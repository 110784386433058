import * as React from "react";
import { connect } from "react-redux";
import { Row, Col, Container } from 'react-bootstrap'
import { IZimiState } from '../../../types/auth'
import { withRouter } from 'react-router'

import { devicesLoad, orgsLoad } from '../../../redux/actions/blueprintActions';
import { IDevice, IDeviceUpdateGateway, IOrganization } from "../../../types/blueprint";
// import { FcmHandler } from "../../../messaging/FcmHandler";
import { RouteComponentProps } from "react-router-dom";

import DeviceBasicView2 from "./DeviceBasicView2";




interface IProps extends IOwnProps, ReduxStateProps, RouteComponentProps<MatchProps> {
  devicesLoadConnect: (orgId?: string) => void;
  // updateDevice: (devUpdate: IDeviceUpdateGateway) => void;
  loadOrgs: (orgId: string) => void;
  history: any;
  // orgId?: string;
}

interface IOwnProps {

}

interface ReduxStateProps {
  devices: IDevice[];
  orgs: IOrganization[];
}

interface MatchProps {
  orgId: string;
}

interface IState {
  initRun: boolean;
  // fcmHandler: FcmHandler;
  activeKey: string;
  // onFocus: () => void;

}

class Devices extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    // this.processMessage = this.processMessage.bind(this);

    this.onFocus = this.onFocus.bind(this)
    this.state = {
      initRun: true,
      // fcmHandler: FcmHandler.getInstance(),
      activeKey: 'basic',
      // onFocus: this.onFocus
    }
  }

  render() {
    const orgId = this.getOrg();
    const orgObj = this.props.orgs && this.props.orgs.find(org => org.id === orgId);
    const heading = orgId ? ' Network : ' + (orgObj?.defaultAlias || orgObj?.name) + ' , Devices' : 'Devices List';

    const singleOrg =
      <Container>
        <h4> {heading}</h4>
        <DeviceBasicView2 orgId={orgId} />
      </Container>

    const allOrgs =
      <Container>
        {this.props.orgs.map(org => {

          const heading = org.id ? ' Network : ' + (org?.defaultAlias || org?.name) + ' , Devices' : 'Devices List';

          return (
            <Container key={'container-network-cps-' + org.id } style={{  margin: '5px' }} >

              <Row style={{ border: '1px  solid blue' }}>
                <Row style={{  margin: '5px' }}>
                  <h4> {heading}</h4>
                </Row>
                <Row style={{  margin: '5px' }}>
                  <DeviceBasicView2 orgId={org.id} />
                </Row>
              </Row>
            </Container>
          )

        })
        }
      </Container>

    const orgSelected = !orgId ? allOrgs : singleOrg;

    return (
      <Container>
        {/* <Row><Col><h2> {heading} </h2></Col></Row> */}

        <Row>
          <Col sm={12} md={12} lg={12} xl={12}>

            {orgSelected}

          </Col>
        </Row>
      </Container>
    );
  };

  UNSAFE_componentWillMount() {
    console.debug('Devices.componentWillMount() called');
    this.props.devicesLoadConnect(this.getOrg());
    if (!this.props.orgs || this.props.orgs.length === 0) {
      this.props.loadOrgs(this.getOrg())
    }
  }

  componentDidMount() {
    window.addEventListener("focus", this.onFocus)
    // window.addEventListener("blur", this.onFocus)

  }

  UNSAFE_componentWillReceiveProps(nextProps: ReduxStateProps) {
    console.debug('Devices.componentWillReceiveProps() called');
    if (nextProps.devices.length > 0 && this.state.initRun) {
      console.debug('Devices.componentWillReceiveProps - FCM setup called');

      this.setState({ initRun: false })
      const zccs = nextProps.devices.filter(dev => dev.deviceType === 'zcc');
      zccs.forEach(zcc => {
        // this.state.fcmHandler.subscribeToGateway(zcc.id);
      })
      // this.state.fcmHandler.onMessageCallback(this.processMessage)
    }
  }

  componentWillUnmount() {
    console.debug('Devices.componentWill-UN-Mount() called');
    const zccs = this.props.devices.filter(dev => dev.deviceType === 'zcc');
    zccs.forEach(zcc => {
      // this.state.fcmHandler.unsubscribeToGateway(zcc.id);
    })
    window.removeEventListener("focus", this.onFocus)
    // window.removeEventListener("blur", this.onFocus)

  }

  onFocus() {
    this.props.devicesLoadConnect(this.getOrg());
    this.props.loadOrgs(this.getOrg())
  }

  getOrg() {
    return this.props.match.params.orgId
  }

  shouldDisplayDevice(device: IDevice) {
    switch (device.deviceType) {
      case 'zcc':
      case 'ugds':
        return false;
      default:
        return true;
    }
  }


}

const mapStateToProps = (state: IZimiState, ownProps: IOwnProps): ReduxStateProps => {
  return { devices: state.blueprintState.deviceState.devices, orgs: state.blueprintState.orgState.orgs }
}

const mapDispatchToProps = {
  devicesLoadConnect: devicesLoad,
  // updateDevice: deviceUpdateWithGatwayMessage,
  loadOrgs: orgsLoad,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(Devices));

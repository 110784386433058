import { IDevice, IOrganization } from "../types/blueprint";
import { RegisterDecoder } from "./RegisterDecoder";



export interface INetworks {
    networks: Map<string, INetworkContents>;
    devices: Map<string, IDeviceContents>

}

export interface INetworkContents {
    name: string;
    id: string;
    roomNames: string[];
    rooms: Map<string, IDevices>;
    usageTotal: number;

}
export interface IDevices {
    deviceIds: string[];
    deviceNames: string[];
    devices: Map<string, IDeviceContents>;
    usageTotal: number;
}

export interface IDeviceContents {
    id: string;
    name: string;
    deviceType: string;
    roomName: string;
    roomId: number;
    organizationId: string;
    usageData: IPowerHistory[];
    usageTotal: number;
}

export interface IPowerHistory {
    timestamp: Date;
    usage: number;
}


export class PowerTree {


    private root: INetworks;
    public constructor() {
        this.root = {
            networks: new Map(),
            devices: new Map()
        };

    }

    public createPowerTree(devices: IDevice[], orgs: IOrganization[]) {
        orgs.forEach(org => {
            const networkContent = this.createNetworkContent(org);
            if (networkContent) {
                this.setNetworkContents(org.id, networkContent)
            }
        })

        devices.forEach(device => {
            const devicesContent: IDeviceContents[] | undefined = this.createDeviceContents(device);
            if (devicesContent) {
                devicesContent.forEach(devContent => {
                    this.setDeviceContent(devContent);
                })
            }

        })

        return this.root;
    }
    public getPowerTree(): INetworks {

        return this.root;
    }

    public setPowerUsage(cpId: string, powerHistory: IPowerHistory[]) {
        const deviceContents = this.root.devices.get(cpId);
        if (deviceContents) {
            deviceContents.usageData = powerHistory;

            let total = 0;
            powerHistory.forEach(entry => {
                total += entry.usage;
            })

            deviceContents.usageTotal += total;

            const orgContent = this.getNetworkContent(deviceContents.organizationId);
            const room = orgContent?.rooms.get(deviceContents.roomName);
            if (room) {
                room.usageTotal += total;
            }
            if (orgContent) {
                orgContent.usageTotal += total;
            }

        }


    }


    private getNetworkContents(): INetworkContents[] | undefined {

        const contents: INetworkContents[] = [];
        this.root.networks.forEach((value, key) => {
            contents.push(value);
        })
        return contents;

    }


    private getNetworkContent(networkId: string): INetworkContents | undefined {
        if (networkId) {
            const network = this.root.networks.get(networkId);
            if (network) {
                return network;
            } else {
                return;
            }
        }
    }

    private setNetworkContents(networkId: string, networkContents: INetworkContents): boolean {
        const contents = this.getNetworkContent(networkId)
        if (contents) {
            return false;
        } else {
            this.root.networks.set(networkId, networkContents);
            return true;
        }
    }

    private setDeviceContent(deviceContents: IDeviceContents) {
        const roomDevices = this.getNetworkContent(deviceContents.organizationId)?.rooms.get(deviceContents.roomName)

        if (roomDevices) {
            roomDevices.deviceIds.push(deviceContents.id);
            roomDevices.deviceNames.push(deviceContents.name);
            roomDevices.devices.set(deviceContents.id, deviceContents);

            this.root.devices.set(deviceContents.id, deviceContents);
        }
    }

    private createNetworkContent(organization: IOrganization): INetworkContents | undefined {

        if (organization.id && organization.roomNames) {

            const roomNames = organization.roomNames?.split(',');

            const newNetworkContent: INetworkContents = {
                id: organization.id,
                name: organization.name || organization.defaultAlias,
                roomNames: roomNames,
                rooms: new Map(),
                usageTotal: 0,
            }


            roomNames.forEach(roomName => {
                newNetworkContent.rooms.set(
                    roomName,
                    {
                        deviceIds: [],
                        deviceNames: [],
                        devices: new Map(),
                        usageTotal: 0,
                    }
                )
            })

            return newNetworkContent;
        }
    }


    private createDeviceContents(device: IDevice): IDeviceContents[] | undefined {

        const orgId = device.organizationId;
        if (orgId) {
            const orgContent = this.getNetworkContent(orgId);
            if (orgContent) {

                const deviceContents: IDeviceContents[] = [];
                const roomNames = orgContent.roomNames;
                const decodedDevices = RegisterDecoder.getInstance().decodeRegistersForControlHistory(device, roomNames);
                decodedDevices?.forEach(decodedDevice => {
                    deviceContents.push({
                        id: decodedDevice.deviceId,
                        name: decodedDevice.deviceName ? decodedDevice.deviceName : '',
                        deviceType: decodedDevice.deviceType,
                        roomId: decodedDevice.roomId,
                        roomName: decodedDevice.roomName ? decodedDevice.roomName : '',
                        organizationId: decodedDevice.organizationId,
                        usageData: [],
                        usageTotal: 0,
                    })
                })
                return deviceContents;
            }
        }
    }



}

import * as requestPromise from 'request-promise'
import { appConfig } from '../firebase/config';
import { FirebaseClient } from '../firebase/FirebaseClient';


const HOST = process.env.HOST || appConfig.zimicoreConfig.baseUrl;
// const PORT = process.env.PORT || 80;
const BASEURL = 'https://' + HOST  //+ (PORT !== '80' ? ':' + PORT : '');
const BLUEPRINT_APIPATH = '/blueprint/api/v1/';
const FCM_SUBS_PATH = '/api/fcm/tokenToTopic';

export const getDevices = (jwt: string, orgId?: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
        requestPromise.get({
            headers: { 'Authorization': 'bearer ' + jwt },
            url: BASEURL + BLUEPRINT_APIPATH + 'devices' + (orgId ? '?organizationId=' + orgId : ''),
            resolveWithFullResponse: true,
        })
            .then((ret: any) => {
                const body = JSON.parse(ret.body);
                resolve(body);
            })
            .catch((err: any) => {
                reject(err);
            });
    });
};

export const getOrganizations = (jwt: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
        requestPromise.get({
            headers: { 'Authorization': 'bearer ' + jwt },
            url: BASEURL + BLUEPRINT_APIPATH + 'organizations',
            resolveWithFullResponse: true,
        })
            .then((ret: any) => {
                const body = JSON.parse(ret.body);
                resolve(body);
            })
            .catch((err: any) => {
                reject(err);
            });
    });
};

export const getOrganization = (jwt: string, orgId: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
        requestPromise.get({
            headers: { 'Authorization': 'bearer ' + jwt },
            url: BASEURL + BLUEPRINT_APIPATH + 'organizations/' + orgId,
            resolveWithFullResponse: true,
        })
            .then((ret: any) => {
                const body = JSON.parse(ret.body);
                resolve(body);
            })
            .catch((err: any) => {
                reject(err);
            });
    });
};


// notification config apis

export interface INotificationConfig {
    organizationId: string;
    configurations:
    {
        id: string;
        controlpointId: string;
        event: string;
        enabled: boolean;
        deviceId: string;
        delay?: number;
    }[]
}

export const getNotificationConfigs = (jwt: string, orgId?: string): Promise<INotificationConfig> => {
    return new Promise<any>((resolve, reject) => {
        requestPromise.get({
            headers: { 'Authorization': 'bearer ' + jwt },
            url: BASEURL + BLUEPRINT_APIPATH + 'notificationconfig' + (orgId ? '?organizationId=' + orgId : ''),
            resolveWithFullResponse: true,
        })
            .then((ret: any) => {
                const body = JSON.parse(ret.body);
                resolve(body.notifications);
            })
            .catch((err: any) => {
                reject(err);
            });
    });
}

export interface INotificationConfigCreate {
    organizationId: string;
    configurations: [
        {
            controlpointId: string;
            event: string;
            enabled: boolean;
            deviceId: string;
            delay?: number;
        }
    ]
}

export const createNotificationConfigs = (jwt: string, config: INotificationConfigCreate) => {
    return new Promise<{ notifications: INotificationConfig, errors: { error: { type: string } }[] }>((resolve, reject) => {
        requestPromise.post({
            headers: { 'Authorization': 'bearer ' + jwt, 'content-type': 'application/json' },
            url: BASEURL + BLUEPRINT_APIPATH + 'notificationconfig',
            resolveWithFullResponse: true,
            body: JSON.stringify({ notifications: config })
        })
            .then((ret: any) => {
                const body = JSON.parse(ret.body);
                resolve(body);
            })
            .catch((err: any) => {
                reject(err);
            });
    });
}

export interface INotificationConfigDelete {
    organizationId: string;
    configurations: [
        {
            id: string
        }
    ]
}

export const deleteNotificationConfig = (jwt: string, config: INotificationConfigDelete) => {

    return new Promise<{ notifications: INotificationConfig, errors: { error: { type: string } }[] }>((resolve, reject) => {
        requestPromise.delete({
            headers: { 'Authorization': 'bearer ' + jwt, 'content-type': 'application/json' },
            url: BASEURL + BLUEPRINT_APIPATH + 'notificationconfig',
            resolveWithFullResponse: true,
            body: JSON.stringify({  notifications: config })
        })
            .then((ret: any) => {
                const body = JSON.parse(ret.body);
                resolve(body);
            })
            .catch((err: any) => {
                reject(err);
            });
    });
}

export const fcmSubscribeToTopic = (jwt: string, token: string, topic: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
        requestPromise.post({
            headers: { 'Authorization': 'bearer ' + jwt, 'content-type': 'application/json' },
            url: BASEURL + FCM_SUBS_PATH,
            resolveWithFullResponse: true,
            body: JSON.stringify({
                registrationToken: token,
                topic
            })
        })
            .then((ret: any) => {
                const body = JSON.parse(ret.body);
                resolve(body);
            })
            .catch((err: any) => {
                resolve({})
                // reject(err);
            });
    });
};

export const fcmUnSubscribeToTopic = (jwt: string, token: string, topic: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
        requestPromise.delete({
            headers: { 'Authorization': 'bearer ' + jwt, 'content-type': 'application/json' },
            url: BASEURL + FCM_SUBS_PATH,
            resolveWithFullResponse: true,
            body: JSON.stringify({
                registrationToken: token,
                topic
            })
        })
            .then((ret: any) => {
                const body = JSON.parse(ret.body);
                resolve(body);
            })
            .catch((err: any) => {
                resolve({})
                // reject(err);
            });
    });
};

export const sendPasswordReset = (email: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
        requestPromise.post({
            headers: { 'content-type': 'application/json' },
            url: BASEURL + '/user/password_reset',
            resolveWithFullResponse: true,
            body: JSON.stringify({
                email
            })
        })
            .then((ret: any) => {
                const body = JSON.parse(ret.body);
                resolve(body);
            })
            .catch((err: any) => {
                reject(err);
            });
    });
};

export const sendPasswordResetSuccess = (email: string): Promise<any> => {
    return new Promise<void>((resolve, reject) => {
        requestPromise.post({
            headers: { 'content-type': 'application/json' },
            url: BASEURL + '/user/password_reset_success',
            resolveWithFullResponse: false,
            body: JSON.stringify({
                email
            })
        })
            .then((ret: any) => {
                // const body = JSON.parse(ret.body);
                resolve();
            })
            .catch((err: any) => {
                reject(err);
            });
    });
};



export const getUserByUid = (jwt: string, uid: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
        requestPromise.get({
            // headers: {   'content-type': 'application/json' },
            url: BASEURL + '/user/byuid/' + uid,
            resolveWithFullResponse: false,
            headers: { 'Authorization': 'bearer ' + jwt },

        })
            .then((ret: any) => {
                const body = JSON.parse(ret);
                resolve(body);
            })
            .catch((err: any) => {
                reject(err);
            });
    });
};


export const getUserByEmail = (jwt: string, email: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
        requestPromise.get({
            // headers: {   'content-type': 'application/json' },
            url: BASEURL + '/user/byemail/' + email,
            resolveWithFullResponse: false,
            headers: { 'Authorization': 'bearer ' + jwt },

        })
            .then((ret: any) => {
                const body = JSON.parse(ret);
                resolve(body);
            })
            .catch((err: any) => {
                reject(err);
            });
    });
};

export const getUsersBatch = (jwt: string, uids: string[]): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
        requestPromise.post({
            headers: { 'content-type': 'application/json', 'Authorization': 'bearer ' + jwt },
            url: BASEURL + BLUEPRINT_APIPATH + 'users_batchget',
            resolveWithFullResponse: false,
            body: JSON.stringify({
                ids: uids
            })
        })
            .then((ret: any) => {
                const body = JSON.parse(ret);
                resolve(body);
            })
            .catch((err: any) => {
                reject(err);
            });
    });
};

export const updateFBAuthUser = (jwt: string, uid: string, emailAddress: string, displayName: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
        requestPromise.post({
            headers: { 'content-type': 'application/json', 'Authorization': 'bearer ' + jwt },
            url: BASEURL + '/user/' + uid,
            resolveWithFullResponse: false,
            body: JSON.stringify({
                email: emailAddress,
                displayName
            })
        })
            .then((ret: any) => {
                const body = JSON.parse(ret);
                resolve(body);
            })
            .catch((err: any) => {
                reject(err);
            });
    });
};

export const updateFSUserDetails = (jwt: string, uid: string, data: object): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
        requestPromise.put({
            headers: { 'content-type': 'application/json', 'Authorization': 'bearer ' + jwt },
            url: BASEURL + BLUEPRINT_APIPATH + 'users/' + uid,
            resolveWithFullResponse: false,
            body: JSON.stringify(data)
        })
            .then((ret: any) => {
                const body = JSON.parse(ret);
                resolve(body);
            })
            .catch((err: any) => {
                reject(err);
            });
    });
};

export const getCurrentUserJwt = () => {
    return new Promise<string>((resolve, reject) => {

        let currentUser = FirebaseClient.getInstance().getFirebaseAuth().currentUser;
        if (currentUser) {
            currentUser.getIdToken()
                .then(value => {
                    resolve(value);
                }).catch(err => {
                    reject(err)
                })
        } else {
            reject()
        }
    })
}

export const sendCommandToDevice = (jwt: string, deviceId: string, payload: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
        requestPromise.post({
            headers: { 'content-type': 'application/json', 'Authorization': 'bearer ' + jwt },
            url: BASEURL + BLUEPRINT_APIPATH + 'devices/' + deviceId + '/sendcommand',
            resolveWithFullResponse: true,
            body: JSON.stringify(payload)
        })
            .then((ret: any) => {
                const body = JSON.parse(ret.body);
                resolve(body);
            })
            .catch((err: any) => {
                reject(err);
            });
    });
};

import React, { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker'

interface IOwnProps {
    startDate: Date | null;
    endDate: Date | null;

    onDateChanged: (startDate: Date | null, endDate: Date | null) => void
    onResolutionChanged: ( resolution: string) => void
}



const DateRangePicker = (props: IOwnProps) => {
    // const [startDate, setStartDate] = useState<any>( props.startDate || new Date("2021/02/01"));
    // const [endDate, setEndDate] = useState<any>(props.endDate || new Date("2021/10/31"));
    const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([props.startDate, props.endDate] || [new Date( Date.now() - 30 * 24*60*60*1000), new Date(Date.now())]);
    const [resolution, setResolution] = useState('day')

    return (
        <>
            <Row>
                <Col md={2}>
                    <div> Date Range </div>
                </Col>
                <Col md={4}>
                    <DatePicker
                        selected={dateRange[0]}
                        onChange={(date) => {
                            //  console.log(JSON.stringify(date)   );
                            if (date && Array.isArray(date)) {
                                setDateRange(date);
                                props.onDateChanged(date[0], date[1])
                            }
                        }
                        }
                        selectsRange
                        startDate={dateRange[0]}
                        endDate={dateRange[1]}
                        monthsShown={2}
                    />
                </Col>
                {/* <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
            /> */}

                {/* <div> Date - {JSON.stringify(dateRange)}</div> */}
            </Row>
            <Row>
                <Col md={2}>
                    Resolution
                </Col>
                <Col md={4}>
                    <Form>
                        <Form.Control as='select' aria-label="Default select example" defaultValue={resolution}
                            onChange={(e) => {
                                // console.log(JSON.stringify(e.target.value))
                                setResolution( e.target.value);
                                props.onResolutionChanged(e.target.value)

                            }}>
                            {/* <option>Select an option</option> */}
                            <option value="minute">Minutely</option>
                            <option value="hour">Hourly</option>
                            <option value="day">Daily</option>
                            <option value="month">Monthly</option>
                            <option value="year">Yearly</option>

                        </Form.Control>
                    </Form>
                </Col>
            </Row>
        </>
    );
};

export default DateRangePicker;
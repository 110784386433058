import * as React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { IDevice } from "../../../types/blueprint";
import { IZimiState } from "../../../types/auth";
import ChangeHighlight from "react-change-highlight";

import './deviceView.scss'
export interface OwnProps {
    device: IDevice;
}

interface ReduxDispatchProps {
    // requestChanges: ( arg: IDeviceUpdateGateway ) => void;
}

interface ReduxStateProps {
    deviceUpdated: IDevice | undefined;
}

interface Props extends ReduxStateProps, ReduxDispatchProps { }

interface State { }

const mapStateToProps = (state: IZimiState, ownProps: OwnProps): ReduxStateProps => {
    const deviceFromState = state.blueprintState.deviceState.devices.find((dev) => ownProps.device && (dev.id === ownProps.device.id))
    return { deviceUpdated: deviceFromState }
}

const mapDispatchToProps: ReduxDispatchProps = {
    // requestChanges: deviceUpdateWithGatwayMessage
}


class DeviceView extends React.Component<Props, State> {

    render() {
        const device = this.props.deviceUpdated;

        if (device) {
            const deviceFields: Array<{ field: string, data: string | undefined }> = [];
            deviceFields.push({ field: 'MeshId', data: device.meshDeviceId })
            deviceFields.push({ field: 'online', data: device.connected ? 'Connected' : ' Disconnected' });
            deviceFields.push({ field: 'lastUpdated', data: device.meta && device.meta.lastUpdateTime ? new Date(device.meta.lastUpdateTime).toISOString() : 'None' })

            const deviceRegisters: Array<{ reg: string, data: string | undefined }> = [];
            for (let i = 0; i < 64; i++) {
                let regId = 'R' + i;
                if (device[regId]) {
                    deviceRegisters.push({ reg: regId, data: device[regId] })
                }
            }

            const cardDevice = device && (
                <Card style={{}}>
                    <Card.Body>
                        <Card.Title>{device.deviceType}  :
                {[device.outputName,
                            device.outputName1,
                            device.outputName2,
                            device.outputName3,
                            device.outputName4].map(name => name ? name + ',' : '')} </Card.Title>
                        <Card.Subtitle className="mb-2 text-muted"> <p>guid : {device.id}</p></Card.Subtitle>
                        <Card.Body>

                        <Row > <Col xs={4}> <b>Field</b> </Col> <Col xs={8}><b>Value</b></Col> </Row>

                            {
                                deviceFields.map(dev =>
                                    (<Row key={dev.field}>
                                        <Col xs={4}>{dev.field} </Col> <Col xs={8}>
                                            <ChangeHighlight highlightClassName='rc-highlight-device'>
                                                <div ref={React.createRef()}>
                                                    {dev.data}
                                                </div>
                                            </ChangeHighlight>
                                        </Col>

                                    </Row>)
                                )
                            }

                            <Row > <Col xs={4}> <b>Register</b> </Col> <Col xs={8}><b>Value</b></Col> </Row>
                            {
                                deviceRegisters.map(dev =>
                                    (<Row key={dev.reg}>
                                        <Col xs={4}>{dev.reg} </Col> <Col xs={8}>
                                            <ChangeHighlight highlightClassName='rc-highlight-device'>
                                                <div ref={React.createRef()}>
                                                    {dev.data}
                                                </div>
                                            </ChangeHighlight>
                                        </Col>

                                    </Row>)
                                )
                            }



                        </Card.Body>
                        {/* <Card.Link href="/organization/orgId/devices" >Devices</Card.Link> */}
                    </Card.Body>
                </Card>
            )

            return cardDevice;
        } else {
            return <></>
        }
    }
}



export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DeviceView);

/* eslint-disable @typescript-eslint/no-redeclare */
export const AUTHENTICATE = "AUTHENTICATE";
export type AUTHENTICATE = typeof AUTHENTICATE;

export const AUTHENTICATE_REQUESTED = "AUTHENTICATE_REQUESTED";
export type AUTHENTICATE_REQUESTED = typeof AUTHENTICATE_REQUESTED;

export const AUTHENTICATE_ERROR = "AUTHENTICATE_ERROR";
export type AUTHENTICATE_ERROR = typeof AUTHENTICATE_ERROR;

export const UNAUTHENTICATE = "UNAUTHENTICATE";
export type UNAUTHENTICATE = typeof UNAUTHENTICATE;

export const ORG_LOAD = "ORGLOAD";
export type ORG_LOAD = typeof ORG_LOAD;

export const DEVICE_LOAD = "DEVICELOAD";
export type DEVICE_LOAD = typeof DEVICE_LOAD;

export const INVITATION_LOAD = "INVITATIONLOAD";
export type INVITATION_LOAD = typeof INVITATION_LOAD;

export const DEVICE_UPDATE_GATEWAY = "DEVICEUPDATEGATEWAY";
export type DEVICE_UPDATE_GATEWAY = typeof DEVICE_UPDATE_GATEWAY;

export const DATA_CLEAR = "DATACLEAR";
export type DATA_CLEAR = typeof DATA_CLEAR;

export const WS_CONNECTED = 'WS_CONNECTED'
export type WS_CONNECTED = typeof WS_CONNECTED;

export const  WS_DISCONNECTED =  'WS_DISCONNECTED';
export type WS_DISCONNECTED = typeof WS_DISCONNECTED;
import * as React from "react";
import { connect } from "react-redux";
import { Alert, Card, Col, Row, Spinner } from 'react-bootstrap'
// import { RouteComponentProps, withRouter } from "react-router-dom";
import { getCurrentUserJwt, getUserByUid, getUsersBatch, updateFBAuthUser, updateFSUserDetails } from "../../../requests/blueprintRequests";
import ViewEditForm, { IFormInput } from "../lib/ViewEditForm";
import { AuthActions, isActionAllowedForRole } from "../../../libs/Authorization";
import { IZimiState } from "../../../types/auth";


enum WorkflowState {
  INIT,
  READY,
  SEARCHING,
  FOUND,
  NOT_FOUND,
  FAILED,
}

interface IReduxProps {
  userAuthRoles?: string[];
}

interface IOwnProps {
  userId: string
}

interface IProps extends IOwnProps, IReduxProps {
}

interface IState {

  workflow: WorkflowState;
  foundUsers: { uid: string, email: string, emailVerified: boolean, phoneNumber: string, lastSignInTime: Date, fsUserDetails: any, [key: string]: any } | undefined;
  fbAuthUserUpdateError: string | undefined;
}

interface IParamMatch {
  // orgId: string;
}

interface PropsFromParent {
  // Your custom props here
  userId: string
}

// type PageProps = IProps 
// RouteComponentProps<IParamMatch>;

class UserView extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      workflow: WorkflowState.INIT,
      foundUsers: undefined,
      fbAuthUserUpdateError: undefined
    }

    this.searchUsers = this.searchUsers.bind(this);
    this.receiveUpdatedFirebaseAuthDetails = this.receiveUpdatedFirebaseAuthDetails.bind(this);
  }

  searchUsers() {
    this.setState({ workflow: WorkflowState.SEARCHING });
    getCurrentUserJwt()
      .then(jwt => {
        getUserByUid(jwt, this.props.userId)
          .then(user => {
            let uids: string[] = []
            let users: any = {}
            let workflowState = WorkflowState.NOT_FOUND
            if (user) {
              uids.push(user.uid)
              workflowState = WorkflowState.FOUND;
              users = (user)
            }
            this.setState({ foundUsers: users, workflow: workflowState })

            getUsersBatch(jwt, uids).then(userDetails => {
              if (userDetails && userDetails.users && userDetails.users.results) {
                const fetchedUser = users;

                let foundUserDetails = userDetails.users.results.find((details: any) => details.id === fetchedUser.uid);
                if (foundUserDetails) {
                  fetchedUser.fsUserDetails = foundUserDetails;
                }


                this.setState({ foundUsers: fetchedUser });
              }

            })

          })
          .catch(err => {
            this.setState({ workflow: WorkflowState.NOT_FOUND })
          })
      })
      .catch(err => {
        this.setState({ workflow: WorkflowState.FAILED })
      })
  }

  receiveUpdatedFirebaseAuthDetails(updates: any) {
    console.log(JSON.stringify(updates))
    this.setState({ fbAuthUserUpdateError: undefined })

    getCurrentUserJwt()
      .then(jwt => {
        updateFBAuthUser(jwt, this.props.userId, updates.email, updates.displayName)
          .then(ret => {
            updateFSUserDetails(jwt, this.props.userId, { emailAddress: updates.email })
              .then(ret2 => {
                this.searchUsers()
              })
              .catch(err => {
                this.setState({ fbAuthUserUpdateError: err.message })
                console.log(err);
              })
          })
          .catch(err => {
            this.setState({ fbAuthUserUpdateError: err.message })
            console.log(err);
          })

      })
      .catch(err => {
        console.log(err);
        this.setState({ fbAuthUserUpdateError: err.message })

      })

  }

  componentDidMount() {
    this.searchUsers()

  }

  componentWillReceiveProps() {
    this.setState({ fbAuthUserUpdateError: undefined })
  }

  render() {

    const user = this.state.foundUsers;
    let foundUsers = <> </>;
    if (this.state.workflow === WorkflowState.SEARCHING) {
      foundUsers = <Spinner
        as="span"
        animation="border"
        size="sm"
        role="status"
        aria-hidden="true"
      />
    }

    if (this.state.workflow === WorkflowState.FOUND && user) {

      const allowEdit = isActionAllowedForRole(this.props.userAuthRoles, AuthActions.SUPER_ADMIN_WRITE)

      const formData: IFormInput[] = [
        { label: '', fieldName: 'section1', fieldValue: 'Firebase Auth Details', fieldType: 'plaintext', col: { md: 8 } },
        { label: 'User Id', fieldName: 'uid', fieldValue: user ? user.uid : '', fieldType: 'plaintext', col: { md: 8 } },
        { label: 'Last Sign In Time', fieldName: 'lastSignInTime', fieldValue: user ? user.lastSignInTime : '', fieldType: 'plaintext', col: { md: 8 } },
        { label: 'Email Address', fieldName: 'email', fieldValue: user ? user.email : '', fieldType: 'text', col: { md: 8 } },
        { label: 'Display Name', fieldName: 'displayName', fieldValue: user ? user.displayName : '', fieldType: 'text', col: { md: 8 } },

      ];

      foundUsers =
        <>

          <Card>
            <Card.Body>
              <ViewEditForm id='uservieweditfbauth' formInput={formData} receiveUpdatedFields={this.receiveUpdatedFirebaseAuthDetails} allowEdit={allowEdit}></ViewEditForm>
            </Card.Body>
            {this.state.fbAuthUserUpdateError && <Alert variant='warning'> {this.state.fbAuthUserUpdateError} </Alert>}
          </Card>
        </>
    }

    let notFoundUsers = <Row></Row>;
    if (this.state.workflow === WorkflowState.NOT_FOUND) {
      notFoundUsers = <Row>
        <Col>
          User Not Found
        </Col>

      </Row>
    }

    return (
      <>
        {foundUsers}
        {notFoundUsers}
      </>
    );
  };
}

const mapStateToProps = ((state: IZimiState, ownProps: PropsFromParent): IReduxProps => {
  return {
    userAuthRoles: state.authState.roles,
  }
});

export default connect(
  mapStateToProps, null,
)((UserView));

import * as React from "react";
import { connect } from "react-redux";
import { Row, Col, Container, Tabs, Tab, TabContent } from 'react-bootstrap'
import { IZimiState } from '../../../types/auth'
import { withRouter } from 'react-router'

import { devicesLoad, deviceUpdateWithGatwayMessage, orgsLoad } from '../../../redux/actions/blueprintActions';
import DeviceView from './deviceView'
import { IDevice, IDeviceUpdateGateway, IOrganization } from "../../../types/blueprint";
// import { FcmHandler } from "../../../messaging/FcmHandler";
import { RouteComponentProps } from "react-router-dom";
import DeviceStateView from "./DeviceStateView";
import DeviceBasicView from "./DeviceBasicView";
import DeviceBasicView2 from "./DeviceBasicView2";

import { WsHandler } from "../../../messaging/WsHandler";



interface IProps extends IOwnProps, ReduxStateProps, RouteComponentProps<MatchProps> {
  devicesLoadConnect: (orgId?: string) => void;
  updateDevice: (devUpdate: IDeviceUpdateGateway) => void;
  loadOrgs: (orgId: string) => void;
  history: any;
  // orgId?: string;
}

interface IOwnProps {

}

interface ReduxStateProps {
  devices: IDevice[];
  orgs: IOrganization[];
  zccConnected: string[];
}

interface MatchProps {
  orgId: string;
}

interface IState {
  initRun: boolean;
  // fcmHandler: FcmHandler;
  wsHandler?: WsHandler;
  activeKey: string;

}

class Devices extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      initRun: true,
      // fcmHandler: FcmHandler.getInstance(),
      activeKey: 'basic',
    }
    // this.processMessage = this.processMessage.bind(this);
    this.onFocus = this.onFocus.bind(this)
  }

  render() {
    const orgId = this.getOrg();
    const orgObj = this.props.orgs && this.props.orgs.find(org => org.id === orgId);
    const heading = orgId ? ' Network : ' + (orgObj?.defaultAlias || orgObj?.name) + ' , Devices' : 'Devices List';

    let devSummaryStateList: JSX.Element[] = [];
    let devBasicStateList: JSX.Element[] = [];
    let devBasicStateListByRoom: JSX.Element[] = [];


    const zccs = this.props.devices.filter(dev => dev.deviceType === 'zcc');
    const zcc: IDevice | undefined = zccs && zccs.length > 0 ? zccs[0] : undefined;

    const roomDevices: Map<string, IDevice[]> = new Map();

    this.props.devices.forEach(device => {
      if (this.shouldDisplayDevice(device)) {


        const roomId = device.R5;
        if (roomId) {

          if (!roomDevices.get(roomId)) {
            roomDevices.set(roomId, [])
          }
          roomDevices.get(roomId)?.push(device);
        }

        let devState = (
          <Col key={device.id} sm={12} md={6} lg={6} xl={4}>
            < DeviceStateView key={device.id + 'vs'} device={device} />
          </Col>
        );

        let devBasic = (
          // <Col key={device.id} xs={6} sm={4} md={3} lg={3} xl={2}>
          < DeviceBasicView key={device.id + 'vb'} device={device} zccDevice={zcc} />
          // </Col>
        );

        devSummaryStateList.push(devState);
        devBasicStateList.push(devBasic);
      }
    })

    roomDevices.forEach((devices, key) => {

      const roomDevices: JSX.Element[] = [];
      devices.forEach(device => {
        roomDevices.push(
          < DeviceBasicView key={device.id + 'vb'} device={device} zccDevice={zcc} />
        )
      });

      devBasicStateListByRoom.push(
        <div>
          <Row>
            <h5> Room: {key}</h5>
          </Row>
          <Row>
            {roomDevices}
          </Row>
        </div>
      )

    })


    return (
      <Container>
        <Row><Col><h2> {heading} </h2></Col></Row>

        <Row>
          <Col sm={12} md={12} lg={12} xl={12}>

            <Tabs defaultActiveKey="basic" id="deviceviewtab" activeKey={this.state.activeKey} onSelect={(key: string | null) => { if (key) { this.setState({ activeKey: key }) } }} unmountOnExit={true} mountOnEnter={true} >
              <Tab eventKey="basic" title="Basic">
                <TabContent>
                  {/* <Row>
                    {
                      devBasicStateList
                    }
                  </Row> */}
                  {/* <Row>
                    <Col sm={12} md={12} lg={12} xl={12} >
                      {devBasicStateListByRoom}
                    </Col>
                  </Row> */}
                  <Row>
                    { (this.getOrg()) && <DeviceBasicView2 orgId={this.getOrg()}/>}
                  </Row>
                </TabContent>
              </Tab>
              <Tab eventKey="state" title="SummaryState">
                <TabContent>
                  <Row>
                    {
                      devSummaryStateList
                    }
                  </Row>
                </TabContent>
              </Tab>
              <Tab eventKey="registers" title="Registers" >
                <Row>
                  {
                    this.props.devices.map(device => {
                      return (
                        <Col key={device.id} sm={12} md={6} lg={6} xl={4}>
                          < DeviceView device={device} />
                        </Col>
                      )
                    })
                  }
                </Row>
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Container>
    );
  };

  UNSAFE_componentWillMount() {
    console.debug('Devices.componentWillMount() called');
    this.props.devicesLoadConnect(this.getOrg());
    if (!this.props.orgs || this.props.orgs.length === 0) {
      this.props.loadOrgs(this.getOrg())
    }
  }

  componentDidMount() {
    window.addEventListener("focus", this.onFocus)
    // window.addEventListener("blur", this.onFocus)

  }

  UNSAFE_componentWillReceiveProps(nextProps: ReduxStateProps) {
    console.debug('Devices.componentWillReceiveProps() called');
    if (nextProps.devices.length > 0  ) {
      // console.debug('Devices.componentWillReceiveProps - FCM setup called');

      this.setState({ initRun: false })
      const zccs = nextProps.devices.filter(dev => dev.deviceType === 'zcc' && dev.organizationId === this.getOrg() );
      zccs.forEach(zcc => {
        // this.state.fcmHandler.subscribeToGateway(zcc.id);
        const foundZCC = nextProps.zccConnected.find( zccConnected => {return zcc.id ===  zccConnected })
        if( !foundZCC)
        {
          this.setState({ wsHandler: new WsHandler(zcc.id) })
        }

      })
      // this.state.fcmHandler.onMessageCallback(this.processMessage)
    }
  }

  componentWillUnmount() {
    console.debug('Devices.componentWill-UN-Mount() called');
    const zccs = this.props.devices.filter(dev => dev.deviceType === 'zcc');
    zccs.forEach(zcc => {
      // this.state.fcmHandler.unsubscribeToGateway(zcc.id);
    })
    this.state.wsHandler?.close();
    window.removeEventListener("focus", this.onFocus)
    // window.removeEventListener("blur", this.onFocus)

  }

  onFocus() {
    this.props.devicesLoadConnect(this.getOrg());
    this.props.loadOrgs(this.getOrg())
  }

  getOrg() {
    return this.props.match.params.orgId
  }

  shouldDisplayDevice(device: IDevice) {
    switch (device.deviceType) {
      case 'zcc':
      case 'ugds':
        return false;
      default:
        return true;
    }
  }


}

const mapStateToProps = (state: IZimiState, ownProps: IOwnProps): ReduxStateProps => {
  return { devices: state.blueprintState.deviceState.devices, orgs: state.blueprintState.orgState.orgs, zccConnected: state.blueprintState.wss.connectedZCC }
}

const mapDispatchToProps = {
  devicesLoadConnect: devicesLoad,
  updateDevice: deviceUpdateWithGatwayMessage,
  loadOrgs: orgsLoad,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(Devices));

import * as React from "react";
import { connect } from "react-redux";
import { Button, Card, Col, Form, Row } from 'react-bootstrap'
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { getCurrentUserJwt, getUserByEmail, getUserByUid } from "../../../requests/blueprintRequests";
import queryString from 'query-string';


enum WorkflowState {
  INIT,
  READY,
  SEARCHING,
  FOUND,
  NOT_FOUND,
  FAILED,
}

interface IProps extends RouteComponentProps {

}

interface IState {
  searchValue: string;
  [key: string]: any;
  workflow: WorkflowState;
  foundUsers: { uid: string, email: string, emailVerified: boolean, phoneNumber: string, lastSignInTime: Date, [key: string]: any }[];

}

interface IParamMatch {
  orgId: string;
}

interface PropsFromParent {
  // Your custom props here
}

type PageProps = PropsFromParent &
  RouteComponentProps<IParamMatch>;

class UserSearch extends React.Component<PageProps, IState> {
  constructor(props: PageProps) {
    super(props);

    const parsedQuery = queryString.parse(props.location.search);
    const queryParam = parsedQuery['searchValue'];
    let searchValue = ''
    if (queryParam && typeof queryParam === 'string') {
      searchValue = queryParam;
    }

    this.state = {
      searchValue,
      workflow: WorkflowState.INIT,
      foundUsers: [],
    }

    this.searchUsers = this.searchUsers.bind(this);
  }

  getOrg() {
    return this.props.match.params.orgId
  }

  searchUsers() {
    if (this.state.searchValue !== '') {
      this.setState({ workflow: WorkflowState.SEARCHING });
      getCurrentUserJwt()
        .then(jwt => {
          const findbyUid = getUserByUid(jwt, this.state.searchValue)
            .catch(err => {

            });
          const findByEmail = getUserByEmail(jwt, this.state.searchValue)
            .catch(err => {

            });

          Promise.all([findByEmail, findbyUid])
            .then(rets => {
              let uids: string[] = []
              const users: any[] = []
              let workflowState = WorkflowState.NOT_FOUND
              rets.forEach(user => {
                if (user) {
                  uids.push(user.uid)
                  workflowState = WorkflowState.FOUND;
                  users.push(user)
                }
              });
              this.setState({ foundUsers: users, workflow: workflowState })

              // getUsersBatch(jwt, uids).then(userDetails => {
              //   if (userDetails && userDetails.users && userDetails.users.results) {
              //     const fetchedUsers = this.state.foundUsers;
              //     fetchedUsers.forEach(fetchedUser => {
              //       let foundUserDetails = userDetails.users.results.find((details: any) => details.id === fetchedUser.uid);
              //       if (foundUserDetails) {
              //         fetchedUser.fsUserDetails = foundUserDetails;
              //       }
              //     })

              //     this.setState({ foundUsers: fetchedUsers });
              //   }

              // })

            })
            .catch(err => {
              this.setState({ workflow: WorkflowState.NOT_FOUND })
            })
        })
        .catch(err => {
          this.setState({ workflow: WorkflowState.FAILED })
        })

    }

  }

  autocontrol = (fieldName: string) => ({
    name: fieldName,
    value: this.state[fieldName] || '',
    onChange: this.handleChange.bind(this, fieldName),
    className: 'form-control',
  });

  handleChange(fieldName: string, event: any) {
    const value = event.target.value;
    const state: any = {};
    state[fieldName] = value;
    if (fieldName === 'searchValue') {
      if (value !== '') {
        state.workflow = WorkflowState.READY
      } else {
        state.workflow = WorkflowState.INIT
      }
    }
    this.setState(state);
  }

  componentDidMount() {
    if (this.state.searchValue !== '') {
      this.searchUsers()
    }
  }

  render() {

    let foundUsers = <Row> </Row>;
    if (this.state.workflow === WorkflowState.FOUND) {
      foundUsers =
        <>
          <Row>
            <Col>
              Found User
          </Col>
          </Row>
          <Row>
            {this.state.foundUsers.map(user => {
              const userUrl = "/admin/users/" + user.uid;

              return (
                <Col md={6}>
                  <Card>

                    <Card.Body>
                      <Card.Title>  {user.email} </Card.Title>
                      <Card.Subtitle className="mb-4 text-muted">{user.uid}</Card.Subtitle>
                      <Card>
                        <span> <b> Firebase Auth Details</b></span>
                        <Row>
                          <Col> {user && Object.keys(user).map(key => {
                            if (key !== 'fsUserDetails') {
                              return (
                                <Row key={'user_field_' + key}>
                                  <Col> {key} </Col>
                                  <Col> {user[key]}</Col>
                                </Row>)
                            }else{
                              return <></>
                            }
                          }
                          )}
                          </Col>
                        </Row>
                      </Card>

                      {/* <Card>
                        <span> <b> Firestore User Details</b></span>
                        <Row>
                          <Col> {user && user.fsUserDetails && Object.keys(user.fsUserDetails).map(key =>
                            <Row key={'fsuser_field_' + key}>
                              <Col> {key} </Col>
                              <Col> {JSON.stringify(user.fsUserDetails[key])}</Col>
                            </Row>
                          )}
                          </Col>
                        </Row>
                      </Card> */}

                      <Card>
                        <Card.Link as={Link} to={userUrl}> <Card.Title> User Details </Card.Title></Card.Link>
                      </Card>
                    </Card.Body>
                  </Card>

                </Col>
              )

            })}

          </Row>
        </>
    }

    let notFoundUsers = <Row></Row>;
    if (this.state.workflow === WorkflowState.NOT_FOUND) {
      notFoundUsers = <Row>
        <Col>
          User Not Found
        </Col>

      </Row>
    }


    return (
      <>
        <Form>
          <Form.Group controlId='userSearchText'>
            <Row>
              <Col xs={12} >
                <Form.Label> Search </Form.Label>
              </Col>
              <Col xs={4}>
                <Form.Control type="text" placeholder='uid, emailAddress'
                  {...this.autocontrol('searchValue')} />
              </Col>
              <Col xs={4}>
                <Button variant='primary' onClick={this.searchUsers} disabled={this.state.workflow === WorkflowState.INIT}> Search </Button>
              </Col>
            </Row>

          </Form.Group>

        </Form>

        {foundUsers}
        {notFoundUsers}

      </>
    );
  };
}

export default connect(
  null, null,
)(withRouter(UserSearch));


enum AuthRoles {
    SUPER_ADMIN,
    ADMIN,
    USER,
}

export enum AuthActions {
    PUBLIC,
    PUBLIC_ADMIN,
    PUBLIC_USER,

    ADMIN_VIEW,
    SUPER_ADMIN_WRITE,
}

const getRoleActionMap = (): Map<AuthActions, Array<AuthRoles>> => {
    let actionRoleMap: Map<AuthActions, Array<AuthRoles>> = new Map();

    actionRoleMap.set(AuthActions.PUBLIC, [AuthRoles.USER, AuthRoles.ADMIN]);
    actionRoleMap.set(AuthActions.ADMIN_VIEW, [AuthRoles.ADMIN, AuthRoles.SUPER_ADMIN]);
    actionRoleMap.set(AuthActions.SUPER_ADMIN_WRITE, [AuthRoles.SUPER_ADMIN]);

    return actionRoleMap;
};

const roleToAuthRole = (roles: string[]): AuthRoles[] => {
    let authRoles: AuthRoles[] = [];

    roles.forEach(role => {

        switch (role.trim()) {
            case 'admin':
                authRoles.push(AuthRoles.ADMIN);
                break;
            case 'superadmin':
                authRoles.push(AuthRoles.SUPER_ADMIN);
                break;
            default:
                authRoles.push(AuthRoles.USER);
        }
    })

    return authRoles;
}

export const isActionAllowedForRole = (roles: string[] | undefined, action: AuthActions) => {
    if (roles && action) {
        const authRoles = roleToAuthRole(roles);

        const userRolesArray = authRoles;
        let rolesAllowedForAction: AuthRoles[] | undefined = getRoleActionMap().get(action);
        let roleAllowed = false;
        userRolesArray.forEach((role) => {
            let allowed = rolesAllowedForAction && rolesAllowedForAction.find((allowedRole) => {
                return allowedRole === role;
            });
            if (allowed === role) {
                roleAllowed = true;
            }
        });
        return roleAllowed;
    } else {
        return false
    }

}
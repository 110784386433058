import * as React from "react";
import { connect } from "react-redux";

import { Link } from "react-router-dom";
import { IZimiState } from "../types/auth";

import { Navbar, Nav, NavItem, Alert } from 'react-bootstrap';
import { AuthActions, isActionAllowedForRole } from "../libs/Authorization";


interface IProps {
  isAuthenticated: boolean | null;
  uuid: string | null;
  userEmail: string | null;
  roles?: string[];
}

const NavMenu = ({ isAuthenticated, uuid, userEmail, roles }: IProps) => {
  const adminActionAllowed = isActionAllowedForRole(roles, AuthActions.ADMIN_VIEW)

  const logInOut = isAuthenticated ? (
    <NavItem>
      <Nav.Link as={Link} to='/log-out'>
        Log out
    </Nav.Link>
    </NavItem>

  ) : (
      <NavItem>

        <Nav.Link as={Link} to="/log-in">
          Log in
      </Nav.Link>
      </NavItem>

    );

  const mainLinks =
    isAuthenticated ? (
      adminActionAllowed ?
        (
          <>
            <Nav.Link as={Link} to="/admin">
              Admin
          </Nav.Link>

            <Nav.Link as={Link} to="/admin/users">
              Users
          </Nav.Link>

            <Nav.Link as={Link} to="/admin/networks">
              Networks
          </Nav.Link>
          </>
        )
        :
        (
          <>
            <Nav.Link as={Link} to="/home">
              Home
            </Nav.Link>

            <Nav.Link as={Link} to="/organizations">
              Networks
            </Nav.Link>


            <Nav.Link as={Link} to="/devices">
              Devices
            </Nav.Link>

            <Nav.Link as={Link} to="/invitations">
              Invitations
            </Nav.Link>
          </>

        )
    )
      :
      (
        <>
          <Nav.Link as={Link} to='/sign-up'>
            Sign Up
        </Nav.Link>

          <Nav.Link as={Link} to='/forgot-password'>
            Forgot Password
        </Nav.Link>

          {/* <Nav.Link as={Link} to='/reset-password'>
          Reset Password
        </Nav.Link> */}

        </>
      );

  return (
    <>
      <Navbar bg="light" expand="lg">
        <Navbar.Brand href="/">My Zimi </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            {mainLinks}
            {logInOut}
          </Nav>
          <Nav>
            <Alert variant='primary'>
              {isAuthenticated ? `Logged in user: ${userEmail}` : "Logged out"}
            </Alert>
          </Nav>
        </Navbar.Collapse>

      </Navbar>

    </>
  );
};

const mapStateToProps = (state: IZimiState) => ({
  uuid: state.authState.uuid,
  userEmail: state.authState.email,
  isAuthenticated: state.authState.isAuthenticated,
  roles: state.authState.roles,
});

export default connect(
  mapStateToProps,
)(NavMenu);

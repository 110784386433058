import { appConfig } from "../firebase/config";
import { IDevice } from "../types/blueprint";

export type ActionName= 'TurnOn' | 'TurnOff'
export interface IDeviceActionParams {
    // deviceId: string,
    device: IDevice;
    outletNumber: number,
    action: {
        type: ActionName
    }
}

export interface IRegisterUpdateZCCMessage {
    reg: number;
    value: string;
    meshDeviceId?: string;
}

export class DeviceActionHandler {



    public static getRegisterUpdateForDeviceAction(deviceActionParams: IDeviceActionParams): IRegisterUpdateZCCMessage | undefined {

        const regUpdate: IRegisterUpdateZCCMessage = {
            reg: 0,
            value: '',
            meshDeviceId: deviceActionParams.device.meshDeviceId,
        }
        switch (deviceActionParams.device.deviceType) {
            case appConfig.deviceTypes.gpo:

                if (deviceActionParams.outletNumber === 1) {
                    if (deviceActionParams.action.type === 'TurnOn') {
                        regUpdate.reg = 8;
                        regUpdate.value = '8100000000000000'
                    }

                    if (deviceActionParams.action.type === 'TurnOff') {
                        regUpdate.reg = 8;
                        regUpdate.value = '8000000000000000'
                    }
                }

                if (deviceActionParams.outletNumber === 2) {
                    if (deviceActionParams.action.type === 'TurnOn') {
                        regUpdate.reg = 8;
                        regUpdate.value = '0081000000000000'
                    }

                    if (deviceActionParams.action.type === 'TurnOff') {
                        regUpdate.reg = 8;
                        regUpdate.value = '0080000000000000'
                    }
                }
                break;

            case appConfig.deviceTypes.clayton:
            case appConfig.deviceTypes.mccoy:
                if (deviceActionParams.outletNumber === 1) {
                    if (deviceActionParams.action.type === 'TurnOn') {
                        regUpdate.reg = 8;
                        regUpdate.value = '8100000000000000'
                    }

                    if (deviceActionParams.action.type === 'TurnOff') {
                        regUpdate.reg = 8;
                        regUpdate.value = '8000000000000000'
                    }
                }

                if (deviceActionParams.outletNumber === 2) {
                    if (deviceActionParams.action.type === 'TurnOn') {
                        regUpdate.reg = 9;
                        regUpdate.value = '8100000000000000'
                    }

                    if (deviceActionParams.action.type === 'TurnOff') {
                        regUpdate.reg = 9;
                        regUpdate.value = '8000000000000000'
                    }
                }
                if (deviceActionParams.outletNumber === 3) {
                    if (deviceActionParams.action.type === 'TurnOn') {
                        regUpdate.reg = 10;
                        regUpdate.value = '8100000000000000'
                    }

                    if (deviceActionParams.action.type === 'TurnOff') {
                        regUpdate.reg = 10;
                        regUpdate.value = '8000000000000000'
                    }
                }

                if (deviceActionParams.outletNumber === 4) {
                    if (deviceActionParams.action.type === 'TurnOn') {
                        regUpdate.reg = 11;
                        regUpdate.value = '8100000000000000'
                    }

                    if (deviceActionParams.action.type === 'TurnOff') {
                        regUpdate.reg = 11;
                        regUpdate.value = '8000000000000000'
                    }
                }

            break;
        }

        return regUpdate;
    }

}
/// <reference lib="dom" />

import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/messaging'

import { appConfig } from './config'

export class FirebaseClient {

    public static getInstance(): FirebaseClient {
        if (!FirebaseClient.instance) {
            FirebaseClient.instance = new FirebaseClient();
        }
        return FirebaseClient.instance;
    }

    private static instance: FirebaseClient;
    private firebaseAuth: firebase.auth.Auth;
    private firebaseFirestore: firebase.firestore.Firestore;
    private firebaseMessaging: firebase.messaging.Messaging | undefined;

    private constructor() {

        const firebaseConfig = appConfig.firebaseConfig;
        firebase.initializeApp(firebaseConfig);

        this.firebaseAuth = firebase.auth();
        this.firebaseFirestore = firebase.firestore();
        try {

            this.firebaseMessaging = firebase.messaging();
            this.firebaseMessaging.usePublicVapidKey(appConfig.fcmConfig.publicVapIdKey)
        } catch (error) {

        }
    }


    public getFirebaseAuth(): firebase.auth.Auth {
        return this.firebaseAuth;
    }

    public getFirebaseFireStore(): firebase.firestore.Firestore {
        return this.firebaseFirestore;
    }

    public getFirebaseMessaging(): firebase.messaging.Messaging | undefined{
        return this.firebaseMessaging;
    }
}
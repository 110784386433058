import * as React from "react";
import { Card, ProgressBar } from "react-bootstrap";
import { connect } from "react-redux";
import { IDevice, IOrganization } from "../../../types/blueprint";
import { IZimiState } from "../../../types/auth";
import { orgsLoad } from '../../../redux/actions/blueprintActions';

import './deviceView.scss'
import { RegisterDecoder } from "../../../libs/RegisterDecoder";
export interface OwnProps {
    device: IDevice;
}

interface ReduxDispatchProps {
    // requestChanges: ( arg: IDeviceUpdateGateway ) => void;
    orgsLoadConnect: (orgId: string) => void;

}
const mapDispatchToProps: ReduxDispatchProps = {
    // requestChanges: deviceUpdateWithGatwayMessage
    orgsLoadConnect: orgsLoad
}

interface ReduxStateProps {
    deviceUpdated: IDevice | undefined;
    orgOfDevice: IOrganization | undefined;
}

const mapStateToProps = (state: IZimiState, ownProps: OwnProps): ReduxStateProps => {
    const deviceFromState = state.blueprintState.deviceState.devices.find((dev) => ownProps.device && (dev.id === ownProps.device.id));
    const orgFromState = (deviceFromState && deviceFromState.organizationId) ? state.blueprintState.orgState.orgs.find((org) => (org.id === deviceFromState.organizationId)) : undefined;
    return { deviceUpdated: deviceFromState, orgOfDevice: orgFromState }
}

interface Props extends ReduxStateProps, ReduxDispatchProps, OwnProps { }

interface State { }



class DeviceStateView extends React.Component<Props, State> {
    // constructor(props: Props) {
    //     super(props);
    // }

    UNSAFE_componentWillMount() {
        if (this.props.device.organizationId && !this.props.orgOfDevice) {
            this.props.orgsLoadConnect(this.props.device.organizationId);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: ReduxStateProps) {
        if (!nextProps.orgOfDevice && nextProps.deviceUpdated?.organizationId && !nextProps.orgOfDevice) {
            this.props.orgsLoadConnect(nextProps.deviceUpdated?.organizationId);
        }
    }

    render() {
        const device = this.props.deviceUpdated;
        if (device && this.props.deviceUpdated) {
            const devParams = RegisterDecoder.getInstance().decodeRegistersForControlHistory(this.props.deviceUpdated,
                this.props.orgOfDevice?.roomNames ? this.props.orgOfDevice?.roomNames.split(',') : [''])

            let cardOutlets: JSX.Element[] = [];
            if (devParams && devParams.length > 0) {
                cardOutlets = devParams?.map(devState => {

                    return <Card key={devState.deviceId} style={{}}>
                        <Card.Body>
                            <Card.Title>
                                {devState.deviceName}
                            </Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">
                                <span> Outlet - {devState.outletType} </span><br />
                                <span>  Room - {devState.roomName}</span>
                                <span>  Room - {devState.roomId}</span>

                            </Card.Subtitle>
                            <Card>
                                {device.connected ?
                                    <ProgressBar variant="success" now={devState.controlLevel} label={`${devState.controlLevel}%`} /> :
                                    <ProgressBar variant="warning" now={100} label='Disconnected' />
                                }
                                <span> <b>Control Level - {devState.controlLevel} </b></span> <br />
                                
                                <span> <b>Control State </b></span>
                                {
                                    devState.controlState && Object.keys(devState.controlState).map(key => {
                                        const controlState: any = devState.controlState;
                                        return (<span key={`${devState.deviceId}-controlState-${key}`}> {key} : {controlState && controlState[key] !== undefined ? JSON.stringify(controlState[key]) : ''}</span>)
                                    })
                                }

                                <br /><span><b>Settings State </b></span>
                                {
                                    devState.settingsState && Object.keys(devState.settingsState).map(key => {
                                        return (<span key={`${devState.deviceId}-setttingsState-${key}`}> {key} : {devState.settingsState && devState.settingsState[key] !== undefined ? JSON.stringify(devState.settingsState[key]) : ''}</span>)
                                    })
                                }

                            </Card>
                        </Card.Body>
                    </Card>
                })
            }

            let cardDevice =
                <Card>
                    <Card.Body>
                        <Card.Title>
                            <span> Serial - {device.serialNumber} </span> <br />
                            <span> Device - {device.deviceType}  </span><br />
                        </Card.Title>
                        <Card.Subtitle className="mb-2 text-muted">
                            <span> id - {device.id} </span><br />
                        </Card.Subtitle>
                    </Card.Body>
                    <Card.Body>
                        {cardOutlets}
                    </Card.Body>
                </Card >


            return <> {cardDevice} </>
        } else {
            return <></>
        }
    }
}



export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DeviceStateView);

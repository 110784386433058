import * as React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";


export interface IFormInput {
    label: string;
    col: { md: number },
    // placeholder: string;
    fieldName: string;
    fieldType: string;
    fieldValue: string;
}
interface IProps extends RouteComponentProps {
    formInput: IFormInput[];
    id: string;
    receiveUpdatedFields: (updated: any) => void;
    allowEdit?: boolean;

}

interface IState {
    isEdit: boolean;
    updatedFields: any // {[key: string]: any};
}

interface IParamMatch {
    userId: string;
}

interface PropsFromParent {
    // Your custom props here
}

type PageProps = PropsFromParent & IProps &
    RouteComponentProps<IParamMatch>;

class ViewEditForm extends React.Component<PageProps, IState> {
    constructor(props: PageProps) {
        super(props);
        this.state = {
            isEdit: false,
            updatedFields: {},
        }
        this.sendUpdatedData = this.sendUpdatedData.bind(this);
    }

    sendUpdatedData() {
        this.setState({ isEdit: false })
        this.props.receiveUpdatedFields(this.state.updatedFields)

    }

    componentDidMount() {
        let updatedFieldsVals: any = {}
        this.props.formInput.forEach(input => {
            if (input.fieldType !== 'plaintext') {
                updatedFieldsVals[input.fieldName] = input.fieldValue;
            }
        })
        this.setState({ updatedFields: updatedFieldsVals });

    }

    componentWillReceiveProps(nextProps: IProps) {
        let updatedFieldsVals: any = {}
        nextProps.formInput.forEach(input => {
            if (input.fieldType !== 'plaintext') {
                updatedFieldsVals[input.fieldName] = input.fieldValue;
            }
        })
        this.setState({ updatedFields: updatedFieldsVals });
    }

    autocontrol = (fieldName: string) => ({
        name: fieldName,
        value: this.state.updatedFields[fieldName] || '',
        onChange: this.handleChange.bind(this, fieldName),
        className: 'form-control',
    });

    handleChange(fieldName: string, event: any) {
        const value = event.target.value;
        const state: any = this.state.updatedFields;
        state[fieldName] = value;
        this.setState({ updatedFields: state });
    }

    render() {

        return (
            <Form>

                <Row>
                    <Col md={12}>
                        {
                            this.props.formInput.map(formField => {
                                let fieldTypeToUse = formField.fieldType
                                if (this.state.isEdit === false) {
                                    fieldTypeToUse = 'plaintext';
                                }
                                return (
                                    <Form.Group controlId={this.props.id + "_" + formField.fieldName} as={Row}  >
                                        <Form.Label column md={4}> {formField.label}</Form.Label>
                                        <Col md={formField.col.md}>
                                            {
                                                (fieldTypeToUse === 'plaintext') ?
                                                    (<Form.Control plaintext readOnly value={formField.fieldValue}></Form.Control>) :
                                                    (<Form.Control type={fieldTypeToUse} {...this.autocontrol(formField.fieldName)} />)
                                            }

                                        </Col>
                                    </Form.Group>
                                )
                            })
                        }

                    </Col>
                </Row>

                { this.props.allowEdit &&
                    (<Row>
                        <Col>
                            {
                                this.state.isEdit === false ?
                                    <Button variant='warning' onClick={() => this.setState({ isEdit: true })}>
                                        Edit
                                 </Button>
                                    :
                                    <>
                                        <Button variant='warning' onClick={() => this.setState({ isEdit: false })}>
                                            Cancel
                                    </Button>

                                        <Button variant='danger' onClick={this.sendUpdatedData} >
                                            Save
                                    </Button>
                                    </>
                            }

                        </Col>
                    </Row>)
                }
            </Form>


        )


    }
}

export default connect(
    null, null,
)(withRouter(ViewEditForm));

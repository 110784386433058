import * as React from "react";
import { connect } from "react-redux";
import { Container } from 'react-bootstrap'
import { RouteComponentProps, withRouter } from "react-router-dom";
import { IZimiState } from '../../../types/auth'
import { invitationsLoad } from "../../../redux/actions/blueprintActions";
import { Invitation } from "../../../types/blueprint";
import InvitationView from "./InvitationView";


interface DispathProps {
  loadInvitations: () => void;
}

interface ReduxStateProps {
  invitations: Invitation[];
  invitationLoaded: boolean;
}

interface IInvitationMatch {
  invitationToken: string;
}

type PageProps = RouteComponentProps<IInvitationMatch>;

interface IProps extends PageProps, ReduxStateProps, DispathProps { }

interface IState {
}


class InvitationComponent extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
    }
  }

  UNSAFE_componentWillMount() {
    this.props.loadInvitations()
  }

  getInvitationToken() {
    return this.props.match.params.invitationToken
  }

  render() {

    let invitation = this.props.invitations.find(inv => inv.invitationToken === this.props.match.params.invitationToken)

    if (!this.props.invitationLoaded) {
      return <Container>
        <div>
          <p><b> Invitation </b> </p>
          <p>Loading..</p>
        </div >
      </Container>
    } else if (invitation) {
      return (
        <Container>
          <p><b> Invitation </b> </p>

          {/* <Organizations orgId={this.getOrg()} /> */}
          {invitation && <InvitationView invitation={invitation} />}
        </Container>
      );
    } else {
      return (
        <Container>
          <div>
            <p><b>Invitation not found</b></p>
            <p> Unfortunately the invitation was not found. </p>
          </div >
        </Container>
      );
    }
  };
}

const mapStateToProps = (state: IZimiState): ReduxStateProps => {
  return { invitations: state.blueprintState.invitationState.invitations, invitationLoaded: state.blueprintState.invitationState.loaded }
}

const mapDispatchToProps: DispathProps = {
  loadInvitations: invitationsLoad
};


export default connect(
  mapStateToProps, mapDispatchToProps,
)(withRouter(InvitationComponent));

import * as React from "react";
import { connect } from "react-redux";
import { Row, Col, Container } from 'react-bootstrap'
import { IZimiState } from '../../../types/auth'
import { Switch, Route, withRouter, RouteComponentProps } from "react-router-dom";

// import Breadcrumbs from "../../Breadcrumbs";
import InvitationView from "./InvitationView";
import { invitationsLoad } from "../../../redux/actions/blueprintActions";
import { Invitation } from "../../../types/blueprint";
import InvitationComponent from "./InvitationComponent";

interface OwnProps {
  // orgId: string;
}

interface DispathProps {
  loadInvitations: () => void;
}

interface ReduxStateProps {
  invitations: Invitation[];
  invitationsLoaded: boolean;
}

interface IProps extends ReduxStateProps, DispathProps, OwnProps, RouteComponentProps {
}

enum WorkflowState {
  INIT,
  LOADING,
  LOADED,
  ERROR,
}
interface IState {
  // invitations?: InvitationsResponse;
  workflowState: WorkflowState;
}

class InvitationsComponent extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      // invitations: undefined,
      workflowState: WorkflowState.INIT,
    }
  }

  render() {

    let invitationsUI = <></>
    switch (this.state.workflowState) {
      case WorkflowState.INIT:
      case WorkflowState.LOADING:
        invitationsUI = <Row><Col> <span> Loading .... </span></Col></Row>;
        break;
      case WorkflowState.LOADED:
        if (this.props.invitations && this.props.invitations && this.props.invitations.length > 0) {
          invitationsUI = <Row> {this.props.invitations.map(invite => {
            return (<Col key={invite.id} sm={12} md={6} lg={6} xl={4}> <InvitationView invitation={invite} ></InvitationView> </Col>)
          })}
          </Row>
        } else {
          invitationsUI = <Row><Col> <span> No Invitations </span></Col></Row>
        }
        break;
      case WorkflowState.ERROR:
        invitationsUI = <Row><Col> <span> Error Loading Invitations </span></Col></Row>
        break;

    }


    return (

      <Container>
        {/* <Breadcrumbs /> */}

        <Switch>

          <Route path='/invitations/:invitationToken'>
            <InvitationComponent />
          </Route>


          <Route exact path='/invitations'>
            <Row> <Col> <h2>My Invitations</h2></Col></Row>
            {invitationsUI}
          </Route>

        </Switch>


        {/* 
        <Row> <Col> <h2>Invited Networks</h2></Col></Row>

        <Row>
          {this.props.orgs.map(org => {
            return (<Col key={org.id} sm={12} md={6} lg={6} xl={4}> {InvitedOrganization  ({ org })} </Col>)
          })}
        </Row> */}

      </Container>
    );
  }

  UNSAFE_componentWillMount() {

    this.props.loadInvitations();
    this.setState({ workflowState: WorkflowState.LOADING })

  }

  UNSAFE_componentWillReceiveProps(nextProps: ReduxStateProps) {
    if (nextProps.invitationsLoaded) {
      this.setState({ workflowState: WorkflowState.LOADED })
    }
  }

}

const mapStateToProps = (state: IZimiState, ownProps: OwnProps): ReduxStateProps => {
  return { invitations: state.blueprintState.invitationState.invitations, invitationsLoaded: state.blueprintState.invitationState.loaded }
}


const mapDispatchToProps: DispathProps = {
  loadInvitations: invitationsLoad
};


export default (connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(InvitationsComponent)));

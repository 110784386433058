import { IOrgLoad, IDeviceLoad, IDataClear, IDeviceChangeFromGateway, IInvitationLoad, IWsConnected, IWsDisconnected } from "../actions/blueprintActions";
import { ORG_LOAD, DEVICE_LOAD, DATA_CLEAR, DEVICE_UPDATE_GATEWAY, INVITATION_LOAD, WS_CONNECTED, WS_DISCONNECTED } from "../../config/constants";
import { IBlueprintState } from '../../types/auth';
import { IDevice, IOrganization } from "../../types/blueprint";

export default function blueprintReducers(
  state: IBlueprintState = {
    orgState: { orgs: [], loaded: false },
    deviceState: { devices: [], loaded: false },
    invitationState: { invitations: [], loaded: false },
    wss: { connectedZCC: [] }
  },
  action: IOrgLoad | IDeviceLoad | IDataClear | IDeviceChangeFromGateway | IInvitationLoad | IWsConnected | IWsDisconnected
): IBlueprintState {
  switch (action.type) {
    case ORG_LOAD:

      const orgMap = new Map<string, IOrganization>();
      state.orgState.orgs.forEach(existingOrgs => {
        orgMap.set(existingOrgs.id, existingOrgs);
      })
      action.data.orgs.forEach(newOrg => {
        orgMap.set(newOrg.id, newOrg)
      })
      const updatedOrgs: IOrganization[] = []
      orgMap.forEach(org => {
        updatedOrgs.push(org);
      })

      return { ...state, orgState: { orgs: updatedOrgs, loaded: true } };
    case DEVICE_LOAD:
      return { ...state, deviceState: { devices: action.data.devs, loaded: true } }
    case INVITATION_LOAD:
      return { ...state, invitationState: { invitations: action.data.invitations, loaded: true } }
    case DATA_CLEAR:
      return { ...state, orgState: { orgs: [], loaded: false }, deviceState: { devices: [], loaded: false }, invitationState: { invitations: [], loaded: false } }
    case DEVICE_UPDATE_GATEWAY:
      let update = action.data.dev;

      let newDevicesList = JSON.parse(JSON.stringify(state.deviceState.devices)) as IDevice[];
      if (newDevicesList) {
        const gatewayDevs = newDevicesList.filter(devGateway => (devGateway.id === update.gatewayId));
        gatewayDevs.forEach(gatewayDev => {
          const matchedDevs = newDevicesList.filter(dev => (dev.organizationId === gatewayDev.organizationId && dev.meshDeviceId && parseInt(dev.meshDeviceId, 10) === update.meshId))
          matchedDevs.forEach(matchedDev => {
            Object.keys(update.data)
              .forEach((key) => {
                matchedDev[key] = update.data[key]
              })
            matchedDev.meta = { lastUpdateTime: new Date(Date.now()) };
          })

          const updatedDevices = state.deviceState.devices.map(dev => {
            matchedDevs.forEach(matchedDev => {
              if (matchedDev.id === dev.id) {
                return matchedDev;
              }
            })
            return dev;
          })
          return { ...state, deviceState: { devices: (updatedDevices), loaded: true } }
        })
      }
      return { ...state, deviceState: { devices: newDevicesList, loaded: true } }

    case WS_CONNECTED:
      const id = action.data.id;
      const newConnectedWS = [...state.wss.connectedZCC];
      newConnectedWS.push(id);
      return { ...state, wss: { connectedZCC: newConnectedWS } }

    case WS_DISCONNECTED:
      const removeId = action.data.id;
      const newConnectedWsForDel = [...state.wss.connectedZCC];

      const index = newConnectedWsForDel.indexOf(removeId);
      newConnectedWsForDel.splice(index, 1);
      return { ...state, wss: { connectedZCC: newConnectedWsForDel } }
    default:
      return state;
  }
  // return state;
}
import * as React from "react";
import { connect } from "react-redux";
import { Row, Col, Container } from 'react-bootstrap'
import { IZimiState } from '../../../types/auth'

import { Switch, Route, withRouter, RouteComponentProps } from "react-router-dom";
import UserSearch from "../users/UserSearch";
import UserIdRoute from "../users/UserIdRoute";


interface OwnProps {
    userId: string;
}

interface DispathProps {
    // orgsLoadConnect: (orgId: string) => void;
}
interface ReduxStateProps {
    // orgs: IOrganization[];
}

interface IProps extends ReduxStateProps, DispathProps, OwnProps, RouteComponentProps {
}

interface IState {
}

class AdminUsers extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            orgs: [],
        }
    }

    render() {

        return (

            <Container>

                <Switch>
                    <Route exact path='/admin'>
                        <Row> <Col> <h2>Admin</h2></Col></Row>

                        <Row>

                        </Row>

                    </Route>
                    <Route exact path='/admin/users'>
                        <Row> <Col> <h2>Users</h2></Col></Row>
                        <UserSearch></UserSearch>

                        <Row>

                        </Row>

                    </Route>

                    <Route path='/admin/users/:userId'>
                        <UserIdRoute></UserIdRoute>
                    </Route>

                    <Route path='/admin/users/:userId/edit'>
                    </Route>
                </Switch>
            </Container>
        );
    };

    UNSAFE_componentWillMount() {
        // if (this.props.orgs && this.props.orgs.length === 0) {
        // this.props.orgsLoadConnect(this.props.orgId);
        // }

    }
}

const mapStateToProps = (state: IZimiState, ownProps: OwnProps): ReduxStateProps => {
    // return { orgs: ownProps.orgId ? state.blueprintState.orgState.orgs.filter(org => org.id === ownProps.orgId) : state.blueprintState.orgState.orgs }
    return {}
}


const mapDispatchToProps: DispathProps = {
    // orgsLoadConnect: orgsLoad
};

export default (connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(AdminUsers)));

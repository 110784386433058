import * as React from "react";
import { connect } from "react-redux";

import { logOut } from '../../redux/actions/loginActions';
import { initiateClearData } from "../../redux/actions/blueprintActions";

interface IProps {
  logOutConnect: () => void;
  clearData: () => void;
}

const LogOut = ({ logOutConnect, clearData }: IProps) => (
  <>
    <p>Logout page</p>
    <button onClick={() => {
      logOutConnect();
      clearData();
    }}>log me out</button>
  </>
);

const mapDispatchToProps = {
  logOutConnect: logOut,
  clearData: initiateClearData
};

export default connect(
  null,
  mapDispatchToProps,
)(LogOut);

import * as React from "react";
import { connect } from "react-redux";

import { Link } from "react-router-dom";
import { IZimiState } from "../types/auth";

import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { IOrganization } from "../types/blueprint";
import { matchPath, withRouter, RouteComponentProps } from 'react-router'


interface IProps extends ReduxStateProps, RouteComponentProps<MatchProps> {
    // location?: any;
    // orgs: IOrganization[];
}

interface MatchProps {
    orgId: string;
}

interface ReduxStateProps {
    orgs: IOrganization[]
}

const mapStateToProps = (state: IZimiState) => (
    { orgs: state.blueprintState.orgState.orgs }
);

class Breadcrumbs extends React.Component<IProps> {

    render() {

        const orgId = this.getOrg();
        const orgPath = this.getOrgPath();
        const orgObj = this.props.orgs && this.props.orgs.find(org => org.id === orgId);

        return (
            <>
                <Breadcrumb key='bc_1'>
                    <Breadcrumb.Item key='bc_1_1'> <Link key='bcl_1_1' to='/'> Home</Link></Breadcrumb.Item>
                    <Breadcrumb.Item key='bc_1_2'> <Link key='bcl_1_2' to='/organizations'> Networks</Link></Breadcrumb.Item>
                    {orgId && <Breadcrumb.Item key='bc_1_3'> <Link key='bcl_1_3' to={'/organizations/' + orgId}> {orgObj?.defaultAlias || orgObj?.name}</Link></Breadcrumb.Item>}

                    {(orgId && orgPath === 'device' ) && <Breadcrumb.Item key='bc_1_4'> <Link key='bcl_1_4' to={'/organizations/' + orgId + '/device'}> Devices</Link></Breadcrumb.Item>}
                    {(orgId && orgPath === 'powerusage' ) && <Breadcrumb.Item key='bc_1_5'> <Link key='bcl_1_5' to={'/organizations/' + orgId + '/powerusage'}> Power Usage</Link></Breadcrumb.Item>}

                </Breadcrumb>

            </>
        );
    }

    getOrg() {
        // return this.props.match.params.orgId;
        if (this.props.location && this.props.location.pathname) {
            const match = matchPath(this.props.location.pathname, {
                path: '/organizations/:orgId',
                exact: false,
                strict: false
            }) as any;
            let orgId = match && match.params['orgId'];
            return orgId;
        } else { return }
    }

    getOrgPath(){
        if( this.props.location && this.props.location.pathname){
            const match = matchPath( this.props.location.pathname, {
                path: '/organizations/:orgId/:path',
                exact: true
            }) as any

            return match?.params?.path
        }
    }
};


export default connect(
    mapStateToProps,
)(withRouter(Breadcrumbs));

import { ThunkDispatch as Dispatch } from "redux-thunk";
import * as constants from '../../config/constants';
import { FirebaseClient } from '../../firebase/FirebaseClient'

export interface IAuthenticate {
  type: constants.AUTHENTICATE;
  params: { uid: string, email: string, roles: string[] };
}

function authenticate(uid: string, email: string, roles: string[]): IAuthenticate {
  return {
    type: constants.AUTHENTICATE,
    params: { uid, email, roles }
  };
}


export interface IAuthenticateRequested {
  type: constants.AUTHENTICATE_REQUESTED;
}

function authenticateRequested(): IAuthenticateRequested {
  return {
    type: constants.AUTHENTICATE_REQUESTED,
  };
}

export interface IAuthenticateError {
  type: constants.AUTHENTICATE_ERROR;
  param: {
    code: string;
    message: string;
  };
}

function authenticateError(code: string, message: string): IAuthenticateError {
  return {
    type: constants.AUTHENTICATE_ERROR,
    param: { code, message }
  };
}

export interface IUnauthenticate {
  type: constants.UNAUTHENTICATE;
}

function unauthenticate(): IUnauthenticate {
  return {
    type: constants.UNAUTHENTICATE,
  };
}

export type AuthenticationAction = IAuthenticate | IUnauthenticate;

export function logIn(username: string, password: string) {
  return async (dispatch: Dispatch<AuthenticationAction, {}, any>) => {
    dispatch(authenticateRequested())
    FirebaseClient.getInstance().getFirebaseAuth().signInWithEmailAndPassword(username, password)
      .then(user => {
        try {
          let userId = user && user.user ? user.user.uid : 'not received';
          let email = user && user.user && user.user.email ? user.user.email : 'not received';

          FirebaseClient.getInstance().getFirebaseAuth().currentUser?.getIdTokenResult()
            .then(idTokenResult => {
              let roles = [];
              if (idTokenResult.claims.roles && idTokenResult.claims.roles.length > 0) {
                roles = idTokenResult.claims.roles
              }
              dispatch(authenticate(userId, email, roles));
            })
            .catch(err => {
              dispatch(authenticateError(err.code, err.message))
            })
          // userId = userId ? userId : '';
        }
        catch (err) 
        {
          console.log(err);
          const errAny: any = err;

          dispatch(authenticateError(errAny.code, errAny.message))
        }
      })
      .catch(err => {
        console.log(err);
        dispatch(authenticateError(err.code, err.message))
      })
  };
}

export function logOut() {
  return async (dispatch: Dispatch<AuthenticationAction, {}, any>) => {
    FirebaseClient.getInstance().getFirebaseAuth().signOut()
      .then(() => {
        try {
          dispatch(unauthenticate());
        } catch (err) {
          console.log(err);
        }
      })
  };
}

export function checkAuthentication() {
  return async (dispatch: Dispatch<AuthenticationAction, {}, any>) => {

    FirebaseClient.getInstance().getFirebaseAuth().onAuthStateChanged((user) => {
      if (user) {
        FirebaseClient.getInstance().getFirebaseAuth().currentUser?.getIdTokenResult()
          .then(idTokenResult => {
            let roles = [];
            if (idTokenResult.claims.roles && idTokenResult.claims.roles.length > 0) {
              roles = idTokenResult.claims.roles
            }
            dispatch(authenticate(user.uid, user.email ? user.email : 'not received', roles));
          })
          .catch(err => {
            dispatch(authenticateError(err.code, err.message))
          })
      } else {
        dispatch(unauthenticate())
      }
    });
  };
}
import * as React from "react";
import { connect } from "react-redux";
import { Form, Row, Col, Button, Alert, Spinner } from 'react-bootstrap'

import { logIn } from '../../../redux/actions/loginActions';
import { IZimiState } from "../../../types/auth";
import './login.scss'
import { sendPasswordReset } from "../../../requests/blueprintRequests";

interface IProps extends ReduxStateProps {
  // resetPasswordConnect: (username: string) => void;
  location: { query: any, pathname: string }
}

interface OwnProps { }

interface IState {
  email: string;
  workflowState: WorkflowState;
  [key: string]: any;
}

interface ReduxStateProps {
  // authSuccess: boolean;
  authError?: {
    code: string;
    message: string;
  },
  loading: boolean;
  // error: string;
}

enum WorkflowState {
  INIT,
  RESET_PASSWORD_STARTED,
  RESET_PASSWORD_SUCCSS,
  RESET_PASSWORD_FAIL,
}


function mapStateToProps(state: IZimiState, ownProps: OwnProps): ReduxStateProps {
  return {
    // authSuccess: state.authState.isAuthenticated,
    authError: state.authState.authError,
    loading: state.authState.loading,
  };
}

const mapDispatchToProps = {
  logInConnect: logIn
};

class ForgotPassword extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      email: '',
      workflowState: WorkflowState.INIT
    };
  }

  render() {
    const autocontrol = (fieldName: string) => ({
      name: fieldName,
      value: this.state[fieldName] || '',
      onChange: this.handleChange.bind(this, fieldName),
      className: 'form-control',
    });


    const resetPasswordUI = (
      <div className='login-component'>
        <Form id='sign-in-form' >
          <br />
          <Row className='form-group'>
            <Col xs={{ span: 10, offset: 1 }} >

              <Form.Group controlId="formBasicEmail">
                <Form.Label>Please enter the e-mail address to reset your password </Form.Label>
                <Form.Control type="email" placeholder="Enter email" required
                  {...autocontrol('email')} />
              </Form.Group>

              {this.props.authError?.message ?
                (
                  <Alert variant='danger'>
                    {this.props.authError?.message}
                  </Alert>
                ) : <></>}

              <Row>
                <Col xs={12}>
                  <Button variant="primary" type="button"
                    onClick={(event: any) => {
                      // this.props.resetPasswordConnect(this.state.email);
                      this.setState({ workflowState: WorkflowState.RESET_PASSWORD_STARTED })
                      sendPasswordReset(this.state.email).then(ret => {
                        this.setState({ workflowState: WorkflowState.RESET_PASSWORD_SUCCSS })
                      }).catch(err => {
                        this.setState({ workflowState: WorkflowState.RESET_PASSWORD_FAIL })
                      })
                    }}
                  > {'    '}
                    {this.state.workflowState === WorkflowState.RESET_PASSWORD_STARTED ?
                      (<Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />) : <></>}
                      Reset Password
                      </Button>
                </Col>
              </Row>

              <Row></Row>
              <Row>
                <Col xs={12}>
                  {this.state.workflowState === WorkflowState.RESET_PASSWORD_FAIL ?
                    <Alert variant='danger'> Sorry something went wrong, please try again. </Alert> :
                    ''}
                </Col>
              </Row>

            </Col>
          </Row>
        </Form>
      </div>
    );

    const resetPasswordEmailSuccessUI = (
      <Alert variant='success' >
        Thank you, we have e-mailed the instructions to reset your password
      </Alert>
    )

    switch (this.state.workflowState) {
      case WorkflowState.INIT:
      case WorkflowState.RESET_PASSWORD_STARTED:
      case WorkflowState.RESET_PASSWORD_FAIL:
        return resetPasswordUI;
      case WorkflowState.RESET_PASSWORD_SUCCSS:
        return resetPasswordEmailSuccessUI;
      default:
        return resetPasswordUI;
    }
  }

  handleChange(fieldName: string, event: any) {
    const value = event.target.value;
    const state: any = {};
    state[fieldName] = value;
    state.workflowState = WorkflowState.INIT;
    this.setState(state);
  }
}



export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ForgotPassword);

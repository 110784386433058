import * as React from "react";
import { Card, Row, Col, Button, Spinner, Alert } from "react-bootstrap";
import { Invitation, InvitationStatus } from "../../../types/blueprint";
import { updateInvitation, UpdateInvitationType } from "../../../requests/zimiserverRequests";


export interface OwnProps {
    invitation: Invitation;
}

interface IProps extends OwnProps { }

interface IState {
    workflowState: Workflow;
    message: any;
}

enum Workflow {
    Init, SubmitRejct, SumbitAccept, SuccessReject, SuccessAccept, ErrorReject, ErrorAccept
}

export class InvitationView extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props)
        this.state = {
            workflowState: Workflow.Init,
            message: ''
        }
        this.acceptInvitation = this.acceptInvitation.bind(this);
        this.rejectInvitation = this.rejectInvitation.bind(this)
    }

    render() {
        let invite = this.props.invitation
        if (invite) {

            let actionButtons =
                <Row>
                    <Col sm={4}>
                        <Button variant="outline-danger"  onClick={(e: any) => this.rejectInvitation(invite.invitationToken)} >
                            {this.state.workflowState === Workflow.SubmitRejct ?
                                (<Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />) : <></>}
                    Decline</Button>
                    </Col>
                    <Col sm={4}>
                        {<Button variant="outline-success"  onClick={(e: any) => this.acceptInvitation(invite.invitationToken)} >
                            {this.state.workflowState === Workflow.SumbitAccept ?
                                (<Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />) : <></>}
                        Join   </Button>
                        }
                    </Col>
                </Row>

            let successMessage = (this.state.workflowState === Workflow.SuccessAccept || this.state.workflowState === Workflow.SuccessReject) && <Alert variant='success'> {this.state.message}</Alert>

            let failureMessage = (this.state.workflowState === Workflow.ErrorReject || this.state.workflowState === Workflow.ErrorAccept) && <Alert variant='warning'> {this.state.message}</Alert>


            let orgCard = (
                <Card style={{}}>
                    <Card.Body>
                        <Card.Title>  {invite.organization?.defaultAlias || "Network not available"} </Card.Title>
                        <Card.Subtitle className="mb-4 text-muted">
                            <span>{invite.invitationStatus === InvitationStatus.CREATED ? 'Awaiting Response' :
                                invite.invitationStatus === InvitationStatus.ACCEPTED ? 'Accepted' :
                                    invite.invitationStatus === InvitationStatus.DELETED ? 'Deleted' : ''
                            }</span> <br></br>
                            <span> User Type : {invite.userType} </span>

                        </Card.Subtitle>
                        <Card.Body>
                            {actionButtons}

                            {failureMessage}
                        </Card.Body>
                    </Card.Body>
                </Card>
            )

            return successMessage || orgCard;
        } else {
            return <></>
        }
    }


    acceptInvitation(token: string) {
        console.log('acceptInvitation - ' + token)
        this.setState({ workflowState: Workflow.SumbitAccept })
        updateInvitation(token, UpdateInvitationType.ACCEPT)
            .then(ret => {
                let successAcceptMessage =
                    <p>
                        <p><strong>Invitation accepted!</strong></p>
                        <p> You have successfully joined the "{this.props.invitation.organization?.defaultAlias.trim()}" network.</p>
                        <p> Please make sure you restart the Zimi app to complete the process.</p>
                        <p> If you have more than one network, select ‘switch’ in the main menu and choose "{this.props.invitation.organization?.defaultAlias.trim()}".</p>
                        <br />
                        <p>If you need help, please visit <a href='www.zimi.life/support/'> www.zimi.life/support/</a></p>
                    </p>
                this.setState({ workflowState: Workflow.SuccessAccept, message: successAcceptMessage })
            })
            .catch(err => {
                let invitationStatus = <></>;
                switch (err.message) {
                    case 'tokenNotFound':

                        invitationStatus = (
                            <div>
                                <p><b>Invitation not found</b></p>
                                <p> Unfortunately the invitation was not found. </p>
                            </div >
                        );
                        break;
                    case 'tokenDeleted':
                        invitationStatus = (
                            <div>
                                <p><b>Invitation has expired</b></p>
                                <p> Unfortunately the invitation no longer exists.</p>
                                <p>Please request the owner to send a new invite.</p>
                                <br />
                                <p>If you need help, please visit <a href='www.zimi.life/support/'> www.zimi.life/support/</a></p>
                            </div >
                        );

                        break;
                    case 'tokenUsed':
                        invitationStatus = (
                            <div>
                                <p><b>Invitation already accepted</b></p>
                                <p> You have already joined the network. Your invitation was already accepted</p>
                                <p>If you’re having problems connecting with Zimi, please restart the app.</p>
                                <br />
                                <p>When using multiple networks with Zimi,</p>

                                <p>Simply ‘switch’ between them in the main menu.</p>
                                <br />
                                <p>If you need help, please visit <a href='www.zimi.life/support/'> www.zimi.life/support/</a></p>
                            </div >
                        );
                        break;

                    case 'userExisting':
                        invitationStatus = (
                            <div>
                                <p><b>Invitation already accepted</b></p>
                                <p> You have already joined the network. Your account is already in the network</p>
                                <p>If you’re having problems connecting with Zimi, please restart the app.</p>
                                <br />
                                <p>When using multiple networks with Zimi,</p>

                                <p>Simply ‘switch’ between them in the main menu.</p>
                                <br />
                                <p>If you need help, please visit <a href='www.zimi.life/support/'> www.zimi.life/support/</a></p>
                            </div >
                        );

                        break;
                    default:
                        invitationStatus = (
                            <div>
                                <p>Something went wrong</p>
                            </div>
                        );
                        break;
                }

                this.setState({ workflowState: Workflow.ErrorAccept, message: invitationStatus })
            })

    }

    rejectInvitation(token: string) {
        console.log('rejectInvitation - ' + token)
        this.setState({ workflowState: Workflow.SubmitRejct })
        updateInvitation(token, UpdateInvitationType.REJECT)
            .then(ret => {
                this.setState({ workflowState: Workflow.SuccessReject, message: 'Invitation Rejected' })
            })
            .catch(err => {
                this.setState({ workflowState: Workflow.ErrorReject, message: err.message })
            })

    }
}

export default InvitationView
import * as React from "react";
import { Card, ProgressBar, Col, Row, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { IDevice, IOrganization } from "../../../types/blueprint";
import { IZimiState } from "../../../types/auth";
import { orgsLoad } from '../../../redux/actions/blueprintActions';

import './deviceView.scss'
import { RegisterDecoder } from "../../../libs/RegisterDecoder";
import { ActionName, DeviceActionHandler, IRegisterUpdateZCCMessage } from "../../../libs/deviceActionHandler";
import { getCurrentUserJwt, sendCommandToDevice } from "../../../requests/blueprintRequests";
export interface OwnProps {
    device: IDevice;
    zccDevice?: IDevice;
}

interface ReduxDispatchProps {
    // requestChanges: ( arg: IDeviceUpdateGateway ) => void;
    orgsLoadConnect: (orgId: string) => void;

}
const mapDispatchToProps: ReduxDispatchProps = {
    // requestChanges: deviceUpdateWithGatwayMessage
    orgsLoadConnect: orgsLoad
}

interface ReduxStateProps {
    deviceUpdated: IDevice | undefined;
    orgOfDevice: IOrganization | undefined;
}

const mapStateToProps = (state: IZimiState, ownProps: OwnProps): ReduxStateProps => {
    const deviceFromState = state.blueprintState.deviceState.devices.find((dev) => ownProps.device && (dev.id === ownProps.device.id));
    const orgFromState = (deviceFromState && deviceFromState.organizationId) ? state.blueprintState.orgState.orgs.find((org) => (org.id === deviceFromState.organizationId)) : undefined;
    return { deviceUpdated: deviceFromState, orgOfDevice: orgFromState }
}

interface Props extends ReduxStateProps, ReduxDispatchProps, OwnProps { }

interface State { }



class DeviceStateView extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.buttonClickHandler.bind(this)
    }

    UNSAFE_componentWillMount() {
        if (this.props.device.organizationId && !this.props.orgOfDevice) {
            this.props.orgsLoadConnect(this.props.device.organizationId);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: ReduxStateProps) {
        if (!nextProps.orgOfDevice && nextProps.deviceUpdated?.organizationId && !nextProps.orgOfDevice) {
            this.props.orgsLoadConnect(nextProps.deviceUpdated?.organizationId);
        }
    }

    render() {
        const device = this.props.deviceUpdated;
        if (device && this.props.deviceUpdated) {
            const devParams = RegisterDecoder.getInstance().decodeRegistersForControlHistory(this.props.deviceUpdated,
                this.props.orgOfDevice?.roomNames ? this.props.orgOfDevice?.roomNames.split(',') : [''])

            let cardOutlets: JSX.Element[] = [];
            if (devParams && devParams.length > 0) {
                cardOutlets = devParams?.map(devState => {
                    const offId = this.getActionRegsID(device, devState.outletNumber, 'TurnOff');
                    const onId = this.getActionRegsID(device, devState.outletNumber, 'TurnOn');

                    return (
                        <Col key={devState.deviceId} xs={6} sm={4} md={3} lg={3} xl={2}>

                            <Card key={devState.deviceId} >
                                <Card.Body >
                                    <Card.Title>
                                        {devState.deviceName}
                                    </Card.Title>
                                    <Card.Subtitle className="mb-2 text-muted" style={{ fontSize: '12px' }}>
                                        <span>  Room - {devState.roomName}</span>
                                    </Card.Subtitle>
                                    <Card>
                                        <Row><Col>
                                            {
                                                device.connected ?
                                                    <ProgressBar variant="success" now={devState.controlLevel} label={`${devState.controlLevel}%`} /> :
                                                    <ProgressBar variant="warning" now={100} label='Disconnected' />
                                            }
                                        </Col></Row>

                                        <Row>
                                            <Col >
                                                {
                                                    offId ?
                                                        <Button
                                                            // id={this.getActionID(devState.deviceId, 'OFF', {})}
                                                            id={this.getActionRegsID(device, devState.outletNumber, 'TurnOff')}
                                                            variant="outline-danger"
                                                            style={{ width: '40px', fontSize: '8px' }}
                                                            onClick={(e: any) => this.buttonClickHandler(e)}
                                                        >
                                                            Off
                                                        </Button>
                                                        : <div></div>
                                                }
                                            </Col>

                                            <Col >
                                                {
                                                    onId ?
                                                        <Button
                                                            // id={this.getActionID(devState.deviceId, 'ON', {})}
                                                            id={this.getActionRegsID(device, devState.outletNumber, 'TurnOn')}
                                                            variant="outline-success"
                                                            style={{ width: '40px', fontSize: '8px' }}
                                                            onClick={(e: any) => this.buttonClickHandler(e)}
                                                        >
                                                            On
                                                    </Button>
                                                        : <div></div>

                                                }

                                            </Col>
                                        </Row>
                                    </Card>
                                </Card.Body>
                            </Card>
                            <Row style={{ height: '10px' }}>
                                <Col></Col>
                            </Row>
                        </Col>)
                })
            }

            // let cardDevice =
            //     <Card>
            //         <Card.Body>
            //             <Card.Title>
            //                 <span> Serial - {device.serialNumber} </span> <br />
            //                 <span> Device - {device.deviceType}  </span><br />
            //             </Card.Title>
            //             <Card.Subtitle className="mb-2 text-muted">
            //                 <span> id - {device.id} </span><br />
            //             </Card.Subtitle>
            //         </Card.Body>
            //         <Card.Body>
            //             {cardOutlets}
            //         </Card.Body>
            //     </Card >


            return <> {cardOutlets} </>
        } else {
            return <></>
        }
    }

    buttonClickHandler(event: any) {
        console.log('button clicked - ' + event.target.id);

        const eventData: IRegisterUpdateZCCMessage = JSON.parse(event.target.id);

        const zccCommandData = {
            numCommands: 1,
            command: [
                {
                    id: eventData.meshDeviceId,
                    data: eventData.value,
                    type: 'reg',
                    reg: eventData.reg
                }
            ]
        }
        getCurrentUserJwt()
            .then(jwt => {
                if (jwt && this.props.zccDevice?.id) {
                    sendCommandToDevice(jwt, this.props.zccDevice.id, zccCommandData)
                }

            })

    }

    getActionID(deviceId: string, action: string, params: any) {

        return JSON.stringify({ deviceId, action, params })
    }

    getActionRegsID(device: IDevice, outletNumber: 1 | 2 | 3 | 4 | 5| 6, action: ActionName) {
        const regObject = DeviceActionHandler.getRegisterUpdateForDeviceAction({
            device, outletNumber, action: { type: action }
        })
        if (regObject?.value) {
            return JSON.stringify(regObject);
        } else {
            return;
        }
    }

}



export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DeviceStateView);

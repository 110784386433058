import * as React from "react";
import { connect } from "react-redux";
import { Alert, Card, Col, Row, Spinner } from 'react-bootstrap'
import { RouteComponentProps, withRouter } from "react-router-dom";
import { getCurrentUserJwt, getUsersBatch, updateFSUserDetails } from "../../../requests/blueprintRequests";
import ViewEditForm, { IFormInput } from "../lib/ViewEditForm";
import { IZimiState } from "../../../types/auth";
import { AuthActions, isActionAllowedForRole } from "../../../libs/Authorization";


enum WorkflowState {
  INIT,
  READY,
  SEARCHING,
  FOUND,
  NOT_FOUND,
  FAILED,
}

interface IProps extends IReduxProps, RouteComponentProps {
  userId: string
}

interface IReduxProps {
  userAuthRoles?: string[];
}

interface IState {

  workflow: WorkflowState;
  foundUser: any | undefined;
  fsUserUpdateError: string | undefined;
}

interface IParamMatch {
  // orgId: string;
}

interface PropsFromParent {
  // Your custom props here
}

type PageProps = PropsFromParent & IProps &
  RouteComponentProps<IParamMatch>;

class UserView extends React.Component<PageProps, IState> {
  constructor(props: PageProps) {
    super(props);
    this.state = {
      workflow: WorkflowState.INIT,
      foundUser: undefined,
      fsUserUpdateError: undefined,
    }

    this.searchUsers = this.searchUsers.bind(this);
    this.receiveUpdatedFirestoreUserDetails = this.receiveUpdatedFirestoreUserDetails.bind(this);
  }

  searchUsers() {
    this.setState({ workflow: WorkflowState.SEARCHING });
    getCurrentUserJwt()
      .then(jwt => {
        getUsersBatch(jwt, [this.props.userId]).then(userDetails => {
          if (userDetails && userDetails.users && userDetails.users.results && userDetails.users.results.length > 0) {
            this.setState({ foundUser: userDetails.users.results[0], workflow: WorkflowState.FOUND });
          } else {
            this.setState({ workflow: WorkflowState.NOT_FOUND })
          }
        })
      })
      .catch(err => {
        this.setState({ workflow: WorkflowState.FAILED })
      })
  }


  receiveUpdatedFirestoreUserDetails(updates: any) {
    console.log(JSON.stringify(updates))
    this.setState({ fsUserUpdateError: undefined })

    getCurrentUserJwt()
      .then(jwt => {
        updateFSUserDetails(jwt, this.props.userId,
          {
            emailAddress: updates.emailAddress,
            firstName: updates.firstName,
            lastName: updates.lastName,
            phoneNumber: updates.phoneNumber,
            address: {
              streetAddress: updates.streetAddress,
              city: updates.city,
              postalCode: updates.postalCode,
              state: updates.state,
              countryCode: updates.countryCode,
            }
          })
          .then(ret2 => {
            this.searchUsers()
          })
          .catch(err => {
            this.setState({ fsUserUpdateError: err.message })
            console.log(err);
          })
      })
      .catch(err => {
        console.log(err);
        this.setState({ fsUserUpdateError: err.message })
      })
  }

  componentDidMount() {
    this.searchUsers()
  }

  componentWillReceiveProps() {
    this.setState({ fsUserUpdateError: undefined })
  }

  render() {

    const user = this.state.foundUser;
    let foundUsers = <> </>;
    if (this.state.workflow === WorkflowState.SEARCHING) {
      foundUsers = <Spinner
        as="span"
        animation="border"
        size="sm"
        role="status"
        aria-hidden="true"
      />
    }
    if (this.state.workflow === WorkflowState.FOUND && user) {

      const allowEdit = isActionAllowedForRole(this.props.userAuthRoles , AuthActions.SUPER_ADMIN_WRITE)


      const formDataFS: IFormInput[] = [

        { label: '', fieldName: 'section2', fieldValue: 'Firestore User Details', fieldType: 'plaintext', col: { md: 8 } },
        { label: 'Email Address', fieldName: 'uid', fieldValue: user && user ? user.emailAddress : '', fieldType: 'plaintext', col: { md: 8 } },
        { label: 'First Name', fieldName: 'firstName', fieldValue: user && user ? user.firstName : '', fieldType: 'text', col: { md: 8 } },
        { label: 'Last Name', fieldName: 'lastName', fieldValue: user && user ? user.lastName : '', fieldType: 'text', col: { md: 8 } },
        { label: 'Phone Number', fieldName: 'phoneNumber', fieldValue: user && user ? user.phoneNumber : '', fieldType: 'text', col: { md: 8 } },

        { label: '', fieldName: 'section3', fieldValue: 'Address', fieldType: 'plaintext', col: { md: 4 } },
        { label: 'Street Address', fieldName: 'streetAddress', fieldValue: user && user ? user.address.streetAddress : '', fieldType: 'text', col: { md: 8 } },
        { label: 'City', fieldName: 'city', fieldValue: user && user ? user.address.city : '', fieldType: 'text', col: { md: 8 } },
        { label: 'Postal Code', fieldName: 'postalCode', fieldValue: user && user ? user.address.postalCode : '', fieldType: 'text', col: { md: 8 } },
        { label: 'State', fieldName: 'state', fieldValue: user && user ? user.address.state : '', fieldType: 'text', col: { md: 8 } },
        { label: 'Country Code', fieldName: 'countryCode', fieldValue: user && user ? user.address.countryCode : '', fieldType: 'text', col: { md: 8 } },

      ];

      foundUsers =
        <>
          <Card>
            <Card.Body>
              <ViewEditForm id='uservieweditfsuser' formInput={formDataFS} receiveUpdatedFields={this.receiveUpdatedFirestoreUserDetails} allowEdit={allowEdit}></ViewEditForm>
            </Card.Body>
            {this.state.fsUserUpdateError && <Alert variant='warning'> {this.state.fsUserUpdateError} </Alert>}

          </Card>
        </>
    }

    let notFoundUsers = <></>;
    if (this.state.workflow === WorkflowState.NOT_FOUND) {
      notFoundUsers = <Row>
        <Col>
          User Not Found
        </Col>

      </Row>
    }

    return (
      <>
        {foundUsers}
        {notFoundUsers}
      </>
    );
  };
}

const mapStateToProps = ((state: IZimiState, ownProps: PropsFromParent): IReduxProps => {
  return {
    userAuthRoles: state.authState.roles,
  }
});

export default connect(
  mapStateToProps, null,
)(withRouter(UserView));

import * as React from "react";
import { connect } from "react-redux";
import { Row, Col, Container } from 'react-bootstrap'
import { IZimiState } from '../../../types/auth'

import { orgsLoad } from '../../../redux/actions/blueprintActions';
import { IOrganization } from "../../../types/blueprint";
import { Switch, Route, withRouter, RouteComponentProps } from "react-router-dom";
import OrganizationView from "./organizationView";
import Organization from './Organization'

import Devices from "../devices/Devices";
import Devices2 from "../devices/Devices2";

import Breadcrumbs from "../../Breadcrumbs";
import DevicesPowerUsage from "../devices/DevicesPowerUsage";
import DevicesNotificationConfig from "../devices/DevicesNotificationConfig";
// import InvitedOrganization from "./InvitedOrganizationView.";

interface OwnProps {
  orgId: string;
}

interface DispathProps {
  orgsLoadConnect: (orgId: string) => void;
}
interface ReduxStateProps {
  orgs: IOrganization[];
}

interface IProps extends ReduxStateProps, DispathProps, OwnProps, RouteComponentProps {
}

interface IState {
}

class Organizations extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      orgs: [],
    }
  }

  render() {

    return (

      <Container>
        <Breadcrumbs />

        <Switch>
          <Route exact path='/organizations'>
            <Row> <Col> <h2>My Networks</h2></Col></Row>

            <Row>
              {this.props.orgs && this.props.orgs.map(org => {
                return (<Col key={org.id} sm={12} md={6} lg={6} xl={4}> {OrganizationView({ org })} </Col>)
              })}
            </Row>

          </Route>

          <Route path='/organizations/:orgId/device'>
            <Devices />
          </Route>

          <Route path='/organizations/:orgId/powerusage'>
            <DevicesPowerUsage />
          </Route>
          <Route path='/organizations/:orgId/notifications' render={ (props) => ( <DevicesNotificationConfig orgId={props.match.params.orgId} />)} >
          </Route>
          <Route path='/organizations/:orgId' render={ (props) => ( <Organization orgId={props.match.params.orgId} />) }  >
            
          </Route>


        </Switch>


        {/* 
        <Row> <Col> <h2>Invited Networks</h2></Col></Row>

        <Row>
          {this.props.orgs.map(org => {
            return (<Col key={org.id} sm={12} md={6} lg={6} xl={4}> {InvitedOrganization  ({ org })} </Col>)
          })}
        </Row> */}

      </Container>


    );
  };

  UNSAFE_componentWillMount() {
    // if (this.props.orgs && this.props.orgs.length === 0) {
    this.props.orgsLoadConnect(this.props.orgId);
    // }

  }
}

const mapStateToProps = (state: IZimiState, ownProps: OwnProps): ReduxStateProps => {
  return { orgs: ownProps.orgId ? state.blueprintState.orgState.orgs.filter(org => org.id === ownProps.orgId) : state.blueprintState.orgState.orgs }
}


const mapDispatchToProps: DispathProps = {
  orgsLoadConnect: orgsLoad
};

export default (connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(Organizations)));

import * as React from "react";
import { connect } from "react-redux";
import { Row, Col, Container, Button } from 'react-bootstrap'
import { IZimiState } from '../../../types/auth'
import { withRouter } from 'react-router'

import { devicesLoad, deviceUpdateWithGatwayMessage, orgsLoad } from '../../../redux/actions/blueprintActions';
import { IDevice, IOrganization } from "../../../types/blueprint";
import { RouteComponentProps } from "react-router-dom";
import { getPowerUsages, IPowerUsageEntry } from "../../../requests/zimiserverRequests";
// import { ResponsiveLine } from '@nivo/line'
// import { ResponsiveBar } from '@nivo/bar'
import { ResponsivePie } from '@nivo/pie'

import DateRangePicker from '../../uicomponents/DateRangePicker'
import moment from "moment";
import * as c3 from 'c3'
import 'c3/c3.css';
import { INetworks, PowerTree } from "../../../libs/PowerTree";



interface IChartData {
    data: {
        id: string,
        cpId: string,
        data: { x: Date, y: number }[]
    }[]
}

interface IChartDataInt {
    data: {
        id: string,
        cpId: string,
        data: { x: Date, y: number }[],
        total: number;
    }[]
}

interface IBarChartData {
    data: {
        timestamp: Date;
        [key: string]: any;
    }[];
}

interface ISelectedNodes {
    nodes: ISelectedNode[];
}
interface ISelectedNode {
    networkId: string;
    roomNames: Set<string>;
    controlPointIds: Set<string>;
}

interface IClickedNode {
    networkId: string;
    roomNames: string;
    controlPointIds?: string;
}

interface IProps extends IOwnProps, ReduxStateProps, RouteComponentProps<MatchProps> {

}

interface IOwnProps {
    // powerData: IPowerUsageEntry[];
    devices: IDevice[];
    org?: IOrganization;
}

interface ReduxStateProps {

}

interface MatchProps {
    orgId: string;
}

interface IState {
    powerData: IPowerUsageEntry[];
    // convertedData: IChartData;
    displayedData: IChartData;
    displayedBarChartData: IBarChartData;
    // hiddenIdSet: Set<string | number>;

    startDate: Date | null;
    endDate: Date | null;
    resolution: string;

    deviceTree?: INetworks;

    selectedNodes: ISelectedNodes;


}

class NetworkPowerUsageChart extends React.Component<IProps, IState> {

    // private fcmHandler: FcmHandler;
    private powerTree: PowerTree;
    constructor(props: IProps) {
        super(props);
        this.state = {
            powerData: [],
            // convertedData: { data: [] },
            displayedData: { data: [] },
            displayedBarChartData: { data: [] },
            // hiddenIdSet: new Set(),
            // colors: undefined,
            startDate: new Date(Date.now() - 31 * 24 * 60 * 60 * 1000),
            endDate: new Date(Date.now()),
            resolution: 'day',
            selectedNodes: { nodes: [] },
        }

        // this.toggleIdVisibility = this.toggleIdVisibility.bind(this);
        // this.updateDataUsingHiddenIds = this.updateDataUsingHiddenIds.bind(this);
        this.loadPowerData = this.loadPowerData.bind(this);
        this.convertData = this.convertData.bind(this);
        this.convertData2 = this.convertData2.bind(this);
        this.convertDataToBarChartFormat = this.convertDataToBarChartFormat.bind(this);
        this.renderChart = this.renderChart.bind(this)

        this.powerTree = new PowerTree();
    }

    renderChart() {


        const barChartKeysMap: Set<string> = new Set();
        this.state.displayedBarChartData.data.forEach(times => {
            Object.keys(times).forEach(id => {
                if (id !== 'timestamp' && id !== 'timestampDate') {
                    barChartKeysMap.add(id)
                }
            })
        })

        const barChartKeys: string[] = []

        barChartKeysMap.forEach(key => {
            barChartKeys.push(key);
        })

        // let data = this.state.powerData.map(entry => {
        //     return {
        //         date: moment(entry.timestamp).format('yyyy-M-DD'),
        //         device: entry.deviceId,
        //         usage:  parseInt(entry.usage+'',10)
        //     }
        // });

        let data = this.state.displayedBarChartData.data;
        c3.generate({
            bindto: "#c3chart",
            data: {
                xFormat: '%Y-%m-%dT%H:%M:%S.%LZ',
                keys: {
                    x: 'timestampDate',
                    value: barChartKeys,
                },
                // columns: [
                //     ['data1', -30, 200, 200, 400, -150, 250],
                //     ['data2', 130, 100, -100, 200, -150, 50],
                //     ['data3', -230, 200, 200, -300, 250, 250]
                // ],
                json: data,
                type: 'bar',
                groups: [barChartKeys]

            },
            axis: {
                x: {
                    type: 'timeseries',
                    tick: {
                        format: '%Y-%m-%d %H:%M'
                    }
                }
            },
            grid: {
                y: {
                    lines: [{ value: 0 }]
                }
            }
        });

        c3.generate({
            bindto: "#c3chartline",
            data: {
                xFormat: '%Y-%m-%dT%H:%M:%S.%LZ',
                keys: {
                    x: 'timestampDate',
                    value: barChartKeys,
                },
                // columns: [
                //     ['data1', -30, 200, 200, 400, -150, 250],
                //     ['data2', 130, 100, -100, 200, -150, 50],
                //     ['data3', -230, 200, 200, -300, 250, 250]
                // ],
                json: data,
                type: 'line',
                groups: [barChartKeys]

            },
            axis: {
                x: {
                    type: 'timeseries',
                    tick: {
                        format: '%Y-%m-%d %H:%M'
                    }
                }
            },
            grid: {
                y: {
                    lines: [{ value: 0 }]
                }
            }
        });
    }

    componentDidMount() {
        this.renderChart();
    }

    componentDidUpdate() {
        this.renderChart();
    }

    render() {

        const barChartKeysMap: Set<string> = new Set();
        this.state.displayedBarChartData.data.forEach(times => {
            Object.keys(times).forEach(id => {
                if (id !== 'timestamp' && id !== 'timestampDate') {
                    barChartKeysMap.add(id)
                }
            })
        })

        const barChartKeys: string[] = []

        barChartKeysMap.forEach(key => {
            barChartKeys.push(key);
        })

        return (
            <Container id='network_powerusage_container' >

                <DateRangePicker
                    startDate={this.state.startDate && this.state.startDate}
                    endDate={this.state.endDate}
                    onDateChanged={(startDate, endDate) => { this.setState({ startDate, endDate }) }}
                    onResolutionChanged={(resolution => this.setState({ resolution: resolution }))}
                />

                <Button id='network_powerusage_load_btn' onClick={this.loadPowerData}>Load Power Data</Button>

                {/* {this.powerTreeDisplay()} */}
                {this.props.org?.id && this.getPieChatForNetwork(this.props.org?.id)}

                {this.state.selectedNodes.nodes.length > 0 && (
                    <div>

                        <span>
                            Filtered Data.
                        </span>
                        <span>

                            {this.state.selectedNodes.nodes.map(node => {
                                return <span> Network: {node.networkId} , Rooms: {Array.from(node.roomNames).map(roomName => roomName)} </span>
                            })}
                        </span>
                    </div>
                )}

                {/* 
                <div id='network_powerusage_chart_area_line' style={{ height: '1000px', width: '1200px' }}>




                    <ResponsiveLine
                        data={this.state.displayedData.data}
                        // data={[]}
                        margin={{ top: 100, right: 400, bottom: 100, left: 100 }}
                        // xScale={{ type: 'linear' }}
                        // yScale={{ type: 'linear', stacked: true, min: 0, max: 2500 }}
                        animate={false}

                        yFormat=" >-.2f"
                        curve="basis"
                        xScale={{
                            type: 'time',
                            format: "%Y-%m-%dT%H:%M",
                            useUTC: false,
                            precision: 'minute',
                        }}
                        xFormat="time:%Y-%m-%d %H:%M"
                        yScale={{
                            type: 'linear',
                            stacked: false,

                            // max: 1000
                        }}

                        // yScale={{
                        //     type: 'log',
                        //     base: 10,
                        //     max: 10000,
                        //     min: 1,
                        // }}


                        axisLeft={{
                            legend: 'power usage (w)',
                            legendOffset: 12,
                        }}
                        axisBottom={{
                            format: '%b %d %H:%M',
                            tickValues: 'every 1 week',
                            legend: 'date',
                            // legendOffset: 40,
                        }}
                        enableGridX={false}
                        colors={{ scheme: 'category10' }}
                        lineWidth={1}
                        pointSize={2}
                        pointColor={{ theme: 'background' }}
                        pointBorderWidth={1}
                        pointBorderColor={{ from: 'serieColor' }}
                        pointLabelYOffset={-12}
                        useMesh={true}
                        // enableSlices={'x'}

                        legends={[
                            {
                                anchor: 'top-right',
                                direction: 'column',
                                justify: false,
                                translateX: 300,
                                translateY: 0,
                                itemsSpacing: 2,
                                itemDirection: 'left-to-right',
                                itemWidth: 200,
                                itemHeight: 12,
                                itemOpacity: 0.75,
                                symbolSize: 12,
                                symbolShape: 'triangle',
                                symbolBorderColor: 'rgba(0, 0, 0, .5)',
                                effects: [
                                    {
                                        on: 'hover',
                                        style: {
                                            itemBackground: 'rgba(0, 0, 0, .03)',
                                            itemOpacity: 1,

                                        }
                                    }
                                ],
                                // onClick: ((e) => {
                                //     const colors = this.state.displayedData.data.map(series => (series.id === e.id ? '#ff3344' : '#DDD'));
                                //     this.setState({
                                //         colors: colors,
                                //     });
                                //     // this.toggleIdVisibility(e.id)
                                // }),

                                toggleSerie: true,

                            },
                        ]}
                    />

                </div>
                <div id='network_powerusage_chart_area_bar' style={{ height: '1000px', width: '1200px' }}>


                    <ResponsiveBar
                        data={this.state.displayedBarChartData.data}
                        keys={barChartKeys}
                        indexBy="timestamp"
                        indexScale={{ type: 'band', round: false }}

                        margin={{ top: 100, right: 400, bottom: 100, left: 100 }}
                        animate={false}

                        axisBottom={{
                            tickSize: 10,
                            tickPadding: 50,
                            tickRotation: 45,
                            legend: 'timestamp',
                            legendPosition: 'middle',
                            legendOffset: 32
                        }}


                        axisLeft={{
                            legend: 'power usage (w)',
                            legendOffset: 12,
                        }}
                        // axisBottom={{
                        //     format: '%b %d',
                        //     tickValues: 'every 1 week',
                        //     legend: 'date',
                        //     // legendOffset: 40,
                        // }}
                        enableGridX={false}
                        colors={{ scheme: 'paired' }}
                        // lineWidth={1}
                        // pointSize={2}
                        // pointColor={{ theme: 'background' }}
                        // pointBorderWidth={1}
                        // pointBorderColor={{ from: 'serieColor' }}
                        // pointLabelYOffset={-12}
                        // useMesh={true}
                        // enableSlices={'x'}
                        enableLabel={true}
                        labelSkipWidth={20}
                        legends={[
                            {
                                dataFrom: 'keys',
                                anchor: 'bottom-right',
                                direction: 'column',
                                justify: false,
                                translateX: 120,
                                translateY: 0,
                                itemsSpacing: 2,
                                itemWidth: 100,
                                itemHeight: 20,
                                itemDirection: 'left-to-right',
                                itemOpacity: 0.85,
                                symbolSize: 20,
                                effects: [
                                    {
                                        on: 'hover',
                                        style: {
                                            itemOpacity: 1
                                        }
                                    }
                                ],
                                toggleSerie: true
                            }
                        ]}
                    />

                </div>
                 */}

                <div id='c3chart' style={{ height: '600px' }} />
                <div id='c3chartline' style={{ height: '600px' }} />


            </Container>

        );
    };

    powerTreeDisplay() {

        const networksDiv: any = [];
        this.state.deviceTree?.networks.forEach(network => {


            const roomDivs: any[] = [];
            network.rooms.forEach((room, key) => {

                const deviceDivs: any[] = [];
                room.devices.forEach(device => {
                    const deviceDiv = <Row>
                        <Col>
                            <div> Device Id: {device.id}</div>
                            <div> Device Name: {device.name}</div>
                            <div> Device Type: {device.deviceType}</div>
                            <div> Device Usage : {device.usageTotal}</div>

                        </Col>
                    </Row>
                    if (device.usageTotal !== 0) {
                        deviceDivs.push(deviceDiv);
                        deviceDivs.push(<Row style={{ height: '20px' }}><Col><span>   </span></Col></Row>)
                    }
                })

                const roomDiv = <Row>
                    <Col md={6}>
                        <Row>
                            <Col>
                                <div> Room Name: {key}</div>
                                <div> Total Usage : {room.usageTotal}</div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                {this.getPieChatForRoom(network.id, key)}
                            </Col>
                        </Row>
                    </Col>
                    <Col md={6}>
                        {deviceDivs}
                    </Col>
                </Row>

                if (room.usageTotal !== 0) {
                    roomDivs.push(roomDiv)
                }
            })


            const networkDiv =
                <Row>
                    <Col md={5}>
                        <Row> Network Id: {network.id} </Row>
                        <Row> Network Name: {network.name} </Row>
                        <Row> Power Usage: {network.usageTotal} </Row>

                        <Row>
                            <Col>
                                {this.getPieChatForNetwork(network.id)}
                            </Col>
                        </Row>

                    </Col>
                    <Col md={7}> {roomDivs}</Col>

                </Row>

            networksDiv.push(networkDiv)

        })
        return <div> {networksDiv}</div>
    }

    getPieChatForNetwork(networkId: string) {
        const data = this.getPieChartDataForNetwork(networkId);
        if (data) {
            return (
                <div style={{ height: '500px' }}>

                    <ResponsivePie
                        data={data}
                        margin={{ top: 40, right: 100, bottom: 80, left: 100 }}
                        innerRadius={0.5}
                        padAngle={0.7}
                        cornerRadius={3}
                        activeOuterRadiusOffset={8}
                        borderWidth={1}
                        borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                        arcLinkLabelsSkipAngle={1}
                        arcLinkLabelsTextColor="#333333"
                        arcLinkLabelsThickness={2}
                        arcLinkLabelsColor={{ from: 'color' }}
                        arcLabelsSkipAngle={10}
                        arcLabelsTextColor={{ from: 'color', modifiers: [['darker', 2]] }}
                        sortByValue={true}

                        legends={[
                            {
                                anchor: 'bottom',
                                direction: 'row',
                                justify: false,
                                translateX: 0,
                                translateY: 56,
                                itemsSpacing: 0,
                                itemWidth: 100,
                                itemHeight: 18,
                                itemTextColor: '#999',
                                itemDirection: 'left-to-right',
                                itemOpacity: 1,
                                symbolSize: 18,
                                symbolShape: 'circle',
                                effects: [
                                    {
                                        on: 'hover',
                                        style: {
                                            itemTextColor: '#000'
                                        }
                                    }
                                ],
                                toggleSerie: true
                            }
                        ]}
                        onClick={(e) => { this.selectDisplayNodes({ networkId, roomNames: e.label.toString() }) }}
                    />
                </div>

            )
        }

    }

    getPieChatForRoom(networkId: string, roomName: string) {
        const data = this.getPieChartDataForNetworkRoom(networkId, roomName);
        if (data) {
            return (
                <div style={{ height: '500px' }}>

                    <ResponsivePie
                        data={data}
                        margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                        innerRadius={0.5}
                        padAngle={0.7}
                        cornerRadius={3}
                        activeOuterRadiusOffset={8}
                        borderWidth={1}
                        borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                        arcLinkLabel={function (e) { return e.label + '' }}
                        // arcLabel={ (e) => e.label+''}

                        arcLinkLabelsSkipAngle={1}
                        arcLinkLabelsTextColor="#333333"
                        arcLinkLabelsThickness={2}
                        arcLinkLabelsColor={{ from: 'color' }}
                        arcLabelsSkipAngle={10}
                        arcLabelsTextColor={{ from: 'color', modifiers: [['darker', 2]] }}
                        sortByValue={true}
                        tooltip={({ datum: { id, label, value, color } }) => (
                            <div
                                style={{
                                    padding: 5,
                                    // color,
                                    background: 'white',
                                    boxShadow: 'inherit',

                                }}
                            >
                                {label}:
                                <strong>
                                    {value}
                                </strong>
                            </div>
                        )}
                        legends={[
                            {
                                anchor: 'bottom',
                                direction: 'row',
                                justify: false,
                                translateX: 0,
                                translateY: 56,
                                itemsSpacing: 0,
                                itemWidth: 100,
                                itemHeight: 18,
                                itemTextColor: '#999',
                                itemDirection: 'left-to-right',
                                itemOpacity: 1,
                                symbolSize: 18,
                                symbolShape: 'circle',
                                effects: [
                                    {
                                        on: 'hover',
                                        style: {
                                            itemTextColor: '#000'
                                        }
                                    }
                                ],
                                toggleSerie: true
                            }
                        ]}
                    />
                </div>
            )
        }

    }

    getPieChartDataForNetwork(networkId: string) {
        const networkUsageDetails = this.powerTree.getPowerTree().networks.get(networkId);
        if (networkUsageDetails) {
            const roomsData: { id: string, label: string, value: number }[] = [];
            networkUsageDetails.rooms.forEach((roomContents, roomName) => {
                if (roomContents.usageTotal > 0) {

                    roomsData.push({
                        id: roomName, label: roomName, value: roomContents.usageTotal
                    })
                }
            })
            return roomsData
        }
    }

    getPieChartDataForNetworkRoom(networkId: string, roomName: string) {
        const networkUsageDetails = this.powerTree.getPowerTree().networks.get(networkId);
        if (networkUsageDetails) {

            const room = networkUsageDetails.rooms.get(roomName);

            const pieChartData: { id: string, label: string, value: number }[] = [];
            room?.devices.forEach((devContents, key) => {
                pieChartData.push({
                    id: key, label: devContents.name, value: devContents.usageTotal
                })
            })
            return pieChartData;
        }
    }

    selectDisplayNodes(clickedNode: IClickedNode) {
        const selectedNodeCheck = this.state.selectedNodes.nodes.find(node => node.networkId === clickedNode.networkId)
        if (!selectedNodeCheck) {
            const roomNameSet = new Set<string>();
            const controlPointIdSet = new Set<string>();
            this.state.selectedNodes.nodes.push(
                {
                    networkId: clickedNode.networkId,
                    roomNames: roomNameSet,
                    controlPointIds: controlPointIdSet
                }
            )
        }
        const selectedNode = this.state.selectedNodes.nodes.find(node => node.networkId === clickedNode.networkId);

        selectedNode?.roomNames.clear();
        const isRoomSelected = selectedNode?.roomNames.has(clickedNode.roomNames);
        if (isRoomSelected) {
            selectedNode?.roomNames.delete(clickedNode.roomNames);
        } else {
            selectedNode?.roomNames.add(clickedNode.roomNames);
        }

        this.filterDisplayData();
    }

    toggleDisplayNodes(clickedNode: IClickedNode) {
        const selectedNodeCheck = this.state.selectedNodes.nodes.find(node => node.networkId === clickedNode.networkId)
        if (!selectedNodeCheck) {
            const roomNameSet = new Set<string>();
            const controlPointIdSet = new Set<string>();
            this.state.selectedNodes.nodes.push(
                {
                    networkId: clickedNode.networkId,
                    roomNames: roomNameSet,
                    controlPointIds: controlPointIdSet
                }
            )
        }
        const selectedNode = this.state.selectedNodes.nodes.find(node => node.networkId === clickedNode.networkId);

        const isRoomSelected = selectedNode?.roomNames.has(clickedNode.roomNames);
        if (isRoomSelected) {
            selectedNode?.roomNames.delete(clickedNode.roomNames);
        } else {
            selectedNode?.roomNames.add(clickedNode.roomNames);
        }

        this.filterDisplayData();
    }

    filterDisplayData() {

        const convertedData = this.convertData2(this.state.powerData, this.props.devices).data;

        this.setState(
            {
                // convertedData: { data: convertedData },
                displayedData: { data: convertedData },
                displayedBarChartData: { data: this.convertDataToBarChartFormat(this.state.powerData, this.props.devices).data }
            }
        )
    }

    isControlPointSelected(controlpointId: string) {

        if( this.powerTree.getPowerTree().devices.size > 0){

            const devTreeCP = this.powerTree.getPowerTree().devices.get(controlpointId);
            const orgIdOfCp = devTreeCP?.organizationId;
            if (orgIdOfCp && devTreeCP) {
                const selectedOrg = this.state.selectedNodes.nodes.find(node => node.networkId === orgIdOfCp);
                if (selectedOrg) {
                    
                    return selectedOrg.roomNames.has(devTreeCP.roomName)
                } else {
                    return true;
                }
            }
            else {
                return false;
            }
        }else{
            return true;
        }
    }

    UNSAFE_componentWillMount() {

    }

    // toggleIdVisibility(id: string | number) {
    //     const hasId = this.state.hiddenIdSet.has(id);
    //     if (hasId) {
    //         this.state.hiddenIdSet.delete(id);
    //     } else {
    //         this.state.hiddenIdSet.add(id)
    //     }

    //     this.updateDataUsingHiddenIds();
    // }

    // updateDataUsingHiddenIds() {
    //     const newDisplayState: IChartData = { data: [] };

    //     this.state.convertedData.data.forEach(dataCollection => {
    //         if (this.state.hiddenIdSet.has(dataCollection.id) === false) {
    //             newDisplayState.data.push(dataCollection)
    //         } else {
    //             newDisplayState.data.push({ id: dataCollection.id, data: [] })
    //         }
    //     })
    //     this.setState({ displayedData: newDisplayState })


    // }


    loadPowerData() {

        let deviceIdsForPower: string[] = [];
        this.props.devices.forEach(dev => {
            this.getLegacyControlpointIdsForDevice(dev)
                .forEach(cpId => {
                    deviceIdsForPower.push(cpId);
                })
        })
        getPowerUsages(deviceIdsForPower, this.state.startDate, this.state.endDate, this.state.resolution)
            .then(ret => {

                const convertedData = this.convertData2(ret, this.props.devices).data;

                if (this.props.org && this.props.devices) {
                    this.powerTree.createPowerTree(this.props.devices, [this.props.org]);
                    convertedData.forEach(entries => {
                        if (entries.data) {

                            this.powerTree.setPowerUsage(
                                entries.cpId,
                                entries.data.map(entry => { return { timestamp: entry.x, usage: entry.y } }))
                        }
                    })

                    const tree = this.powerTree.getPowerTree();

                    this.setState(
                        {
                            deviceTree: tree,
                            powerData: ret,
                            // convertedData: { data: convertedData },
                            displayedData: { data: convertedData },
                            displayedBarChartData: { data: this.convertDataToBarChartFormat(ret, this.props.devices).data }
                        }
                    )
                }


            })

    }

    convertDataToBarChartFormat(from: IPowerUsageEntry[], devices: IDevice[]): IBarChartData {
        const barChartData: IBarChartData = { data: [] }
        const chartDataMap: Map<Date, { id: string, usage: number }[]> = new Map();

        from.forEach(powerEntry => {
            if (!chartDataMap.get(powerEntry.timestamp)) {
                chartDataMap.set(powerEntry.timestamp, [])
            }
            const mapEntry = chartDataMap.get(powerEntry.timestamp)
            if (this.isControlPointSelected(powerEntry.deviceId)) {

                mapEntry?.push({ id: powerEntry.deviceId, usage: parseInt(powerEntry.usage + '', 10) })
            }
        })

        chartDataMap.forEach((value, key) => {

            const barValues: any = { timestamp: moment(key).format('yyyy-MM-DD hh:mm'), timestampDate: key }
            value.forEach(val => {
                const cpName = this.mapLegacyCPIdToDeviceName(val.id, devices) + ' ' + val.id.substring(0, 8)
                barValues[cpName] = val.usage;
            })
            barChartData.data.push(barValues);
        })

        barChartData.data = barChartData.data.sort(
            (a, b) => {
                const bT = new Date(b['timestampDate'])
                const aT = new Date(a['timestampDate'])
                const diff = aT.valueOf() - bT.valueOf()
                return diff
            }
        )

        // barChartData.data = barChartData.data.map(val => {
        //     delete val['timestampDate'];
        //     return val;

        // })
        console.log(JSON.stringify(barChartData))

        return barChartData
    }

    convertData(from: IPowerUsageEntry[], devices: IDevice[]): IChartData {
        const chartData: IChartData = { data: [] }

        const chartDataMap: Map<string, { timestamp: Date, usage: number }[]> = new Map()

        from.forEach(powerEntry => {

            const mapEntry = chartDataMap.get(powerEntry.deviceId);
            if (!mapEntry) {
                chartDataMap.set(powerEntry.deviceId, []);
            }
            const mapEntryGet = chartDataMap.get(powerEntry.deviceId);
            mapEntryGet?.push({ timestamp: powerEntry.timestamp, usage: powerEntry.usage })
        })

        chartDataMap.forEach((value, key) => {
            const dataXY = value.map(val => {
                let usageVal: number = parseInt(val.usage + '', 10)

                // if (usageVal < 1) {
                //     usageVal = 1;
                // }

                return { x: new Date(val.timestamp), y: usageVal, label: 'test ' }

            })
            chartData.data.push({ id: this.mapLegacyCPIdToDeviceName(key, devices), cpId: key, data: dataXY })
        })

        // console.log(JSON.stringify(chartData))
        return chartData;
    }


    convertData2(from: IPowerUsageEntry[], devices: IDevice[]): IChartData {
        const chartData: IChartDataInt = { data: [] }

        const chartDataMap: Map<string, { data: { timestamp: Date, usage: number }[] }> = new Map()

        from.forEach(powerEntry => {

            if (this.isControlPointSelected(powerEntry.deviceId)) {


                const mapEntry = chartDataMap.get(powerEntry.deviceId);
                if (!mapEntry) {
                    chartDataMap.set(powerEntry.deviceId, { data: [] });
                }
                const mapEntryGet = chartDataMap.get(powerEntry.deviceId);
                // if (mapEntryGet) 

                mapEntryGet?.data.push({ timestamp: powerEntry.timestamp, usage: parseInt(powerEntry.usage + '', 10) })
            }

        })

        chartDataMap.forEach((value, key) => {
            const dataXY = value.data.map(val => {
                let usageVal: number = val.usage
                if (usageVal === undefined) {
                    usageVal = 0;
                }
                return { x: new Date(val.timestamp), y: usageVal, label: 'test ' }
            })

            let totalCalc = 0;
            value.data.forEach(entry => {
                totalCalc += entry.usage;
            })

            chartData.data.push({ id: this.mapLegacyCPIdToDeviceName(key, devices) + ' ' + key, cpId: key, data: dataXY, total: totalCalc })
        })

        // const mappedData = chartData.data.map( item => { 
        //     return item;
        // })

        const idTotals: { id: string, total: number }[] = [];
        chartData.data.forEach(item => {
            if (item.id && item.total !== undefined) {
                idTotals.push({ id: item.id, total: item.total })
            }
        })

        const ordered = idTotals.sort((a, b) => a.total - b.total);



        let filteredChartData: IChartData = { data: [] };

        ordered.forEach(val => {

            const item = chartData.data.find(item => item.id === val.id);
            if (item && item.data.length > 0) {
                filteredChartData.data.push(
                    {
                        id: item.id,
                        cpId: item.cpId,
                        data: (
                            item.data.map(
                                itemdataVal => {
                                    return { x: itemdataVal.x, y: itemdataVal.y }
                                })
                        )
                    })
            }

        })
        // chartData.data.forEach(item => {
        //     if (item.id && item.data.length > 0) {

        //         filteredChartData.data.push({ id: item.id, data: item.data })
        //     }
        // })

        // filteredChartData = JSON.parse( JSON.stringify(filteredChartData))

        // console.log(JSON.stringify(filteredChartData))
        return filteredChartData;
    }

    mapLegacyCPIdToDeviceName(cpId: string, devices: IDevice[]): string {

        const deviceId = cpId.substr(0, 36);
        const cpExtension = cpId.substr(36, 1)
        const foundDevice = devices.find(dev => dev.id === deviceId);

        if (foundDevice) {

            switch (foundDevice.deviceType) {
                case '1gdimmer':
                case '1g3w10ax':
                    return foundDevice.outputName ? foundDevice.outputName : 'outputName undefined';
                case '2ggpo':
                    {
                        switch (cpExtension) {
                            case 'R':
                                return foundDevice.outputName1 ? foundDevice.outputName1 : 'outputName 1 undefined';
                            case 'L':
                                return foundDevice.outputName2 ? foundDevice.outputName2 : 'outputname 2 undefined';
                            default:
                                return '2ggpo cp ext not found';
                        }
                    }
                case '1gfan':
                    {
                        switch (cpExtension) {
                            case 'S':
                                return foundDevice.outputName1 ? foundDevice.outputName1 : 'outputName 1 undefined';
                            case 'F':
                                return foundDevice.outputName2 ? foundDevice.outputName2 : 'outputname 2 undefined';
                            default:
                                return '1gfan cp ext not found';
                        }
                    }
                case 'mccoy':
                    {
                        switch (cpExtension) {
                            case 'C':
                                return foundDevice.outputName1 ? foundDevice.outputName1 : 'outputName 1 undefined';
                            case 'D':
                                return foundDevice.outputName2 ? foundDevice.outputName2 : 'outputname 2 undefined';
                            case 'E':
                                return foundDevice.outputName1 ? foundDevice.outputName1 : 'outputName 1 undefined';
                            case 'G':
                                return foundDevice.outputName2 ? foundDevice.outputName2 : 'outputname 2 undefined';
                            default:
                                return 'mccoy cp ext not found';
                        }
                    }
                default:
                    return cpId;
            }


        } else {
            return cpId;
        }

    }

    getLegacyControlpointIdsForDevice(device: IDevice): string[] {

        switch (device.deviceType) {
            case 'zcc':
            case 'ugds':
            case 'ugdc':
                return [];
            case '1gdimmer':
            case '1g3w10ax':
                return [device.id];
            case '2ggpo':
                return [device.id + 'L', device.id + 'R'];
            case 'mccoy':
                return [device.id + 'C', device.id + 'D', device.id + 'E', device.id + 'G'];
            case '1gfan':
                return [device.id + 'S', device.id + 'F'];
            default:
                return []
        }
    }
}

const mapStateToProps = (state: IZimiState, ownProps: IOwnProps): ReduxStateProps => {
    return { devices: state.blueprintState.deviceState.devices, orgs: state.blueprintState.orgState.orgs }
}



const mapDispatchToProps = {
    devicesLoadConnect: devicesLoad,
    updateDevice: deviceUpdateWithGatwayMessage,
    loadOrgs: orgsLoad,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(NetworkPowerUsageChart));

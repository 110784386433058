import * as React from "react";
import { connect } from "react-redux";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";
import { Form, Row, Col, Button, Alert, Spinner } from 'react-bootstrap'

import { logIn } from '../../../redux/actions/loginActions';
import { IZimiState } from "../../../types/auth";
import './login.scss'

interface IRouteMatch {
  // invitationToken: string;
}

type PageProps = RouteComponentProps<IRouteMatch>;

interface IProps extends ReduxStateProps, PageProps {
  logInConnect: (username: string, password: string) => void;
  // location: { query: any, pathname: string }
}

interface OwnProps { }

interface IState {
  email: string;
  password: string;
  [key: string]: any;
  passwordType?: string;
}

interface ReduxStateProps {
  authSuccess: boolean | null;
  authError?: {
    code: string;
    message: string;
  },
  loading: boolean;
  // error: string;
}

function mapStateToProps(state: IZimiState, ownProps: OwnProps): ReduxStateProps {
  return {
    authSuccess: state.authState.isAuthenticated,
    authError: state.authState.authError,
    loading: state.authState.loading,
  };
}

class LogIn extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      email: '',
      password: '',
      validated: false,
      loading: false,
    };
  }

  handleChange(fieldName: string, event: any) {
    const value = event.target.value;
    const state: any = {};
    state[fieldName] = value;
    this.setState(state);
  }

  render() {
    const autocontrol = (fieldName: string) => ({
      name: fieldName,
      value: this.state[fieldName] || '',
      onChange: this.handleChange.bind(this, fieldName),
      className: 'form-control',
    });


    let loginPageMessage;
    if (this.props.location && this.props.location.search && this.props.location.search.includes('redirect=invitations')) {
      loginPageMessage = <h3> Please login to join this network </h3>;
    } else {
      loginPageMessage = <h1> Sign in </h1>;
    }

    return (
      <div className='login-component'>
        <Form id='sign-in-form' >
          {loginPageMessage}
          <br />

          <Row className='form-group'>
            <Col xs={{ span: 10, offset: 1 }} >

              <Form.Group controlId="formBasicEmail">
                {/* <Form.Label>Email address</Form.Label> */}
                <Form.Control type="email" placeholder="Enter email" required
                  {...autocontrol('email')} />
              </Form.Group>

              <Form.Group controlId="formBasicPassword">
                {/* <Form.Label>Password</Form.Label> */}
                <Form.Control type="password" placeholder="Password" required
                  {...autocontrol('password')} />
              </Form.Group>

              {this.props.authError?.message ?
                (
                  <Alert variant='danger'>
                    {this.props.authError?.message}
                  </Alert>
                ) : <></>}

              <Row>
                <Col xs={12}>
                  <Button variant="primary" type="button"
                    onClick={(event: any) => {
                      this.props.logInConnect(this.state.email, this.state.password);
                    }}
                  > {'    '}
                    {this.props.loading ?
                      (<Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />) : <></>}
                      Sign In
                  </Button>
                </Col>
              </Row>

              <div className='forgot-password'>
                <Link to='/forgot-password'>Forgot Password</Link>
              </div>

              <Row>
                <Col xs={12}>
                  <label className='control-label has-error'>
                    {this.state.errors}
                  </label>
                </Col>
              </Row>

              {/* <SpinnerButton
                isLoading={this.state.requestStatus === RequestStatus.REQUEST_SENT}
                label='Sign in'
                componentClass={Validation.components.Button}
                className='btn btn-primary btn-block'
              /> */}
              {/* 
              <Button variant="primary" type="button" onClick={(event: any) => {
                this.props.logInConnect(this.state.email, this.state.password);
              }
              }>
                Submit
           </Button> */}

              <div className='sign-up-link'>
                Don't have an account? <Link to='/sign-up'>Sign up</Link>
              </div>

            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

const mapDispatchToProps = {
  logInConnect: logIn
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(LogIn));

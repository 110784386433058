import * as React from "react";
import { connect } from "react-redux";
import { Row, Col, Container, Tabs, Tab, TabContent, Card, Table, Button } from 'react-bootstrap'
import { IZimiState } from '../../../types/auth'
import { withRouter } from 'react-router'

import { devicesLoad, deviceUpdateWithGatwayMessage, orgsLoad } from '../../../redux/actions/blueprintActions';
import { IDevice, IDeviceUpdateGateway, IOrganization } from "../../../types/blueprint";
// import { FcmHandler } from "../../../messaging/FcmHandler";
import { RouteComponentProps } from "react-router-dom";
import { IDeviceControlUpdate, RegisterDecoder } from "../../../libs/RegisterDecoder";
import { getUserToken } from "../../../requests/user";
import { createNotificationConfigs, deleteNotificationConfig, getNotificationConfigs } from "../../../requests/blueprintRequests";

// import { WsHandler } from "../../../messaging/WsHandler";



interface IProps extends IOwnProps, ReduxStateProps, RouteComponentProps<MatchProps> {
  devicesLoadConnect: (orgId?: string) => void;
  updateDevice: (devUpdate: IDeviceUpdateGateway) => void;
  loadOrgs: (orgId: string) => void;
  history: any;
  // orgId?: string;
}

interface IOwnProps {
  orgId: string;
}

interface ReduxStateProps {
  devices: IDevice[];
  orgs: IOrganization[];
}

interface MatchProps {
  orgId: string;
}

interface IState {
  initRun: boolean;
  cpConfigs: Array<ICpNotificationConfigs>;

}

interface ICpNotificationConfigs {
  id: string;
  cpId: string;
  deviceId: string;
  event: string;
  enabled: boolean;
  created: boolean;
  api: {
    action: 'INIT' | 'NONE' | 'CREATE' | 'DELETE' | 'UPDATE';
  }
}

class DevicesNotificationConfig extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      initRun: true,
      cpConfigs: [],
    }
    this.onFocus = this.onFocus.bind(this)
  }

  render() {
    const orgId = this.getOrg();
    const org = this.props.orgs && this.props.orgs.find(org => org.id === orgId);

    let devBasicStateListByRoom: JSX.Element[] = [];

    const controlPoints: IDeviceControlUpdate[] = [];
    const roomDevices: Map<string, IDeviceControlUpdate[]> = new Map();

    this.props.devices?.forEach(device => {
      const devParams = RegisterDecoder.getInstance().decodeRegistersForControlHistory(device,
        org?.roomNames ? org?.roomNames.split(',') : ['']);

      devParams?.forEach(cp => {
        controlPoints.push(cp);

        const roomId = cp.roomName ? cp.roomName : 'undefined';
        if (roomId) {

          if (!roomDevices.get(roomId)) {
            roomDevices.set(roomId, [])
          }
          roomDevices.get(roomId)?.push(cp);
        }
      })
    })


    roomDevices.forEach((cps, key) => {

      const roomDevices: JSX.Element[] = [];

      cps.forEach(cp => {
        const cpView = this.ControlPointView(cp);

        roomDevices.push(cpView)
      });

      devBasicStateListByRoom.push(
        <Container key={'room-cp-control-' + orgId + '-' + key} style={{ border: '1px  solid cyan', margin: '5px' }}>

          <Row key={'roomcp_' + key}>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} >
              {/* <Col  > */}

              <Row>
                <h5> Room: {key}</h5>
              </Row>
              <Row>
                {roomDevices}
              </Row>
            </Col>
          </Row>
        </Container>
      )

    })


    if (controlPoints.length > 0) {
      let cardOutlets: JSX.Element[] = [];
      controlPoints.forEach(controlpoint => {
        const cpView = this.ControlPointView(controlpoint);
        cardOutlets.push(cpView)
      })

      return <> {devBasicStateListByRoom} </>
    } else {
      return <></>
    }

  };

  private ControlPointView(controlPoint: IDeviceControlUpdate) {
    // const device = controlPoint.device;
    const devState = controlPoint;
    const controlPointId = controlPoint.controlpointId;

    const cpConfigs = this.state.cpConfigs.filter(conf => { return conf.cpId === controlPointId });

    return (
      <Col key={devState.deviceId} xs={12} sm={6} md={6} lg={6} xl={6}  >


        <Card key={devState.deviceId} >
          <Card.Body>
            <Card.Title>
              {devState.deviceName}
            </Card.Title>
            {/* <Card.Subtitle className="mb-2 text-muted" style={{ fontSize: '12px' }}>
                        <span>  Room - {devState.roomName}</span>
                    </Card.Subtitle> */}
            <Card>



              <Row>
                <Col>
                  {/* { JSON.stringify(devState.settingsState)} */}
                  {/* {JSON.stringify(configs)} */}
                  <Table>
                    <thead>
                      <tr key={`cp_table_head_${controlPointId}`}>
                        {/* <th> cpID </th> */}
                        <th> Event</th>
                        <th> Notification </th>
                        <th> Update </th>
                        {/* <th> Enabled</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {cpConfigs.map(conf => {
                        return <tr key={`cp_table_body_${controlPointId}_${conf.id}`} >
                          {/* <th> {conf.cpId}</th> */}
                          <td> {conf.event}</td>
                          <td style={{ color: conf.created ? 'green' : 'black' }}>{conf.created ? 'Enabled' : ' - '}</td>
                          <td> {conf.created ?
                            <Button variant="outline-danger" onClick={() => {
                              this.deleteConfig(conf.id)

                            }} > - </Button> :
                            <Button variant="outline-primary" onClick={() => {
                              this.createConfig(conf.cpId, conf.deviceId, conf.event)
                                .then(() => {

                                })
                            }}> + </Button>}
                          </td>
                          {/* <td> {conf.enabled ? 'Enabled' : ' - '}</td> */}

                        </tr>
                      })}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Card>
          </Card.Body>
        </Card>
        <Row style={{ height: '10px' }}>
          <Col></Col>
        </Row>
      </Col>);
  }

  UNSAFE_componentWillMount() {
    console.debug('DevicesNotifConfigcomponentWillMount() called');
    this.props.devicesLoadConnect(this.getOrg());
    if (!this.props.orgs || this.props.orgs.length === 0) {
      this.props.loadOrgs(this.getOrg())
    }



  }

  componentDidMount() {
    window.addEventListener("focus", this.onFocus)
    // window.addEventListener("blur", this.onFocus)


  }

  async UNSAFE_componentWillReceiveProps(nextProps: ReduxStateProps) {
    console.debug('DevicesNotifConfigcomponentWillReceiveProps() called');
    if (nextProps.devices.length > 0) {
      console.debug('DevicesNotifConfigcomponentWillReceiveProps - FCM setup called', nextProps.devices.length);


      // this.setState({ initRun: false })
      // const zccs = nextProps.devices.filter(dev => dev.deviceType === 'zcc');
      // zccs.forEach(zcc => {
      //   // this.state.fcmHandler.subscribeToGateway(zcc.id);
      //   // this.setState({ wsHandler: new WsHandler(zcc.id) })

      // })
      // this.state.fcmHandler.onMessageCallback(this.processMessage)

      await this.loadAndSetConfigToState(nextProps.devices);


    }
  }

  componentWillUnmount() {
    console.debug('DevicesNotifConfigcomponentWill-UN-Mount() called');
    const zccs = this.props.devices.filter(dev => dev.deviceType === 'zcc');
    zccs.forEach(zcc => {
      // this.state.fcmHandler.unsubscribeToGateway(zcc.id);
    })
    // this.state.wsHandler?.close();
    window.removeEventListener("focus", this.onFocus)
    // window.removeEventListener("blur", this.onFocus)

  }

  onFocus() {
    // this.props.devicesLoadConnect(this.getOrg());
    // this.props.loadOrgs(this.getOrg())
  }



  getOrg() {
    return this.props.match.params.orgId
  }

  shouldDisplayDevice(device: IDevice) {
    switch (device.deviceType) {
      case 'zcc':
      case 'ugds':
        return false;
      default:
        return true;
    }
  }

  loadAndSetConfigToState(devices: IDevice[]) {
    return new Promise<void>((resolve) => {

      const cps: IDeviceControlUpdate[] = [];
      devices.forEach(dev => {
        const devCps = RegisterDecoder.getInstance().decodeRegistersForControlHistory(dev, []);
        devCps?.forEach(devcp => {
          cps.push(devcp)
        })
      })



      this.getConfigs()
        .then(configs => {

          const cpNotificationConfigs: ICpNotificationConfigs[] = []
          cps.forEach(cp => {
            const populatedConfigs = this.populateConfigsForCps(cp, configs);
            populatedConfigs.forEach(ppConfig => {
              cpNotificationConfigs.push(ppConfig);
            })
          })
          this.setState({ cpConfigs: cpNotificationConfigs });
          resolve()
        })
        .catch(err => {
          console.log(err);
          resolve()
        })

    })

  }

  getConfigs() {
    return new Promise<ICpNotificationConfigs[]>((resolve, reject) => {

      getUserToken()
        .then(token => {
          getNotificationConfigs(token, this.getOrg())
            .then(configs => {

              const cpConfigMap = new Array<ICpNotificationConfigs>();
              configs.configurations.forEach(config => {
                cpConfigMap.push({
                  id: config.id,
                  cpId: config.controlpointId,
                  deviceId: config.deviceId,
                  event: config.event,
                  enabled: config.enabled,
                  created: true,
                  api: {
                    action: 'INIT'
                  }
                })
              })

              resolve(cpConfigMap);
              // this.setState({
              //   cpConfigs: cpConfigMap
              // })

            })
            .catch(err => {
              reject(err);
            })
        })
        .catch(err => {
          reject(err)
        })
        .catch(err => {
          reject(err)
          // console.log(err);
        })
    })
  }

  populateConfigsForCps(controlPoint: IDeviceControlUpdate | undefined, loadedConfigs: ICpNotificationConfigs[]) {

    const configs: ICpNotificationConfigs[] = [];

    if (controlPoint) {

      const cpId = controlPoint.controlpointId;
      let possibleConfigs: { cpId: string, event: string }[] = [];
      switch (controlPoint.outletType) {
        case 'garagedoor':
          possibleConfigs =
            [{ cpId, event: 'Opened', },
            { cpId, event: 'Closed', },
            { cpId, event: 'Changed', },
            { cpId, event: 'LeftOpen', }
            ]

          break;
        case 'blind':
          possibleConfigs =
            [{ cpId, event: 'Opened', },
            { cpId, event: 'Closed', },
            // { cpId, event: 'Changed', },
            ]

          break;
        case 'gposwitch0':
        case 'gposwitch1':
        case 'switch':
        case 'switch0':
        case 'switch1':
        case 'switch2':
        case 'switch3':
          possibleConfigs =
            [{ cpId, event: 'TurnedOn', },
            { cpId, event: 'TurnedOff', },
            ]
          break;
        case 'dimmer':
        case 'dimmer0':
        case 'dimmer1':
        case 'dimmer2':
        case 'dimmer3':
        case 'dimmer4':
        case 'fan':
          possibleConfigs =
            [{ cpId, event: 'TurnedOn', },
            { cpId, event: 'TurnedOff', },
            { cpId, event: 'Changed', }
            ]
          break;
        // case 'bc':
        // case 'bc_v2':
        // case 'senbc':
        //   possibleConfigs =
        //     [{ cpId, event: 'Opened', },
        //     { cpId, event: 'Closed', },
        //     { cpId, event: 'Changed', },
        //     ]

        //   break;
      }


      possibleConfigs.forEach(possibleConfig => {
        const foundLoadedConfig = loadedConfigs.find((conf => { return (conf.cpId === possibleConfig.cpId && conf.event === possibleConfig.event) }))
        if (foundLoadedConfig) {
          configs.push(foundLoadedConfig)
        } else {
          configs.push({
            id: 'temp_' + possibleConfig.cpId + '_' + possibleConfig.event,
            cpId: possibleConfig.cpId,
            deviceId: controlPoint.device.id,
            event: possibleConfig.event,
            enabled: false,
            created: false,
            api: {
              action: 'INIT'
            }
          })
        }

      })

    }
    return configs;
  }

  createConfig(cpId: string, deviceId: string, event: string) {
    return new Promise<void>((resolve, reject) => {

      const orgId = this.getOrg();
      getUserToken()
        .then(token => {
          createNotificationConfigs(token, {
            organizationId: orgId,
            configurations: [
              {
                controlpointId: cpId,
                event,
                deviceId,
                enabled: true,
              }
            ]
          })
            .then(ret => {
              if (ret.notifications) {
                const allNewStateCpConfigs: ICpNotificationConfigs[] = [];
                ret.notifications.configurations.forEach(updatedConfig => {

                  const newStateCpConfig: ICpNotificationConfigs[] = this.state.cpConfigs.map((config => {
                    if (config.cpId === updatedConfig.controlpointId && config.event === updatedConfig.event) {
                      return {
                        id: updatedConfig.id,
                        cpId: updatedConfig.controlpointId,
                        deviceId: updatedConfig.deviceId,
                        event: updatedConfig.event,
                        enabled: updatedConfig.enabled,
                        created: true,
                        api: {
                          action: 'INIT'
                        }
                      }
                    } else {
                      return config;
                    }
                  }))
                  allNewStateCpConfigs.push(...newStateCpConfig)
                })
                this.setState({ cpConfigs: allNewStateCpConfigs });
                resolve()
              } else {
                reject('not updated')
              }
            })
        })


    })
  }

  deleteConfig(id: string) {
    return new Promise<void>((resolve, reject) => {

      const orgId = this.getOrg();
      getUserToken()
        .then(token => {
          deleteNotificationConfig(token, {
            organizationId: orgId,
            configurations: [
              { id }
            ]
          })
            .then(ret => {
              if (ret.notifications) {
                const allNewStateCpConfigs: ICpNotificationConfigs[] = [];
                ret.notifications.configurations.forEach(updatedConfig => {

                  const newStateCpConfig: ICpNotificationConfigs[] = this.state.cpConfigs.map((config => {
                    if (config.id === id) {
                      return {
                        id: 'temp' + config.cpId + '_' + config.event,
                        cpId: config.cpId,
                        deviceId: config.deviceId,
                        event: config.event,
                        enabled: false,
                        created: false,
                        api: {
                          action: 'INIT'
                        }
                      }
                    } else {
                      return config;
                    }
                  }))
                  allNewStateCpConfigs.push(...newStateCpConfig)
                })
                this.setState({ cpConfigs: allNewStateCpConfigs });
                resolve()
              } else {
                reject('not updated')
              }
            })
        })


    })
  }

}

const mapStateToProps = (state: IZimiState, ownProps: IOwnProps): ReduxStateProps => {

  return { devices: state.blueprintState.deviceState.devices.filter(d => d.organizationId === ownProps.orgId), orgs: state.blueprintState.orgState.orgs }
}

const mapDispatchToProps = {
  devicesLoadConnect: devicesLoad,
  updateDevice: deviceUpdateWithGatwayMessage,
  loadOrgs: orgsLoad,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(DevicesNotificationConfig));

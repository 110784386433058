import * as React from "react";
import { Card, ProgressBar, Col, Row, Button, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { IDevice, IOrganization } from "../../../types/blueprint";
import { IZimiState } from "../../../types/auth";
import { orgsLoad } from '../../../redux/actions/blueprintActions';

import './deviceView.scss'
import { IDeviceControlUpdate, RegisterDecoder } from "../../../libs/RegisterDecoder";
import { ActionName, DeviceActionHandler, IRegisterUpdateZCCMessage } from "../../../libs/deviceActionHandler";
import { getCurrentUserJwt, sendCommandToDevice } from "../../../requests/blueprintRequests";
import { WsHandler } from "../../../messaging/WsHandler";


export interface OwnProps {
    // device: IDevice;
    // zccDevice?: IDevice;
    orgId: string,
    // onFocus: () => void;

}

interface ReduxDispatchProps {
    // requestChanges: ( arg: IDeviceUpdateGateway ) => void;
    orgsLoadConnect: (orgId: string) => void;

}
const mapDispatchToProps: ReduxDispatchProps = {
    // requestChanges: deviceUpdateWithGatwayMessage
    orgsLoadConnect: orgsLoad
}

interface ReduxStateProps {
    devicesUpdated: IDevice[] | undefined;
    org: IOrganization | undefined;
    zccDevice: IDevice | undefined;
}

const mapStateToProps = (state: IZimiState, ownProps: OwnProps): ReduxStateProps => {
    const devicesFromState = state.blueprintState.deviceState.devices.filter((dev) => ownProps.orgId && (dev.organizationId === ownProps.orgId));
    const orgFromState = state.blueprintState.orgState.orgs.find((org) => (org.id === ownProps.orgId));
    const zccDevice = devicesFromState.find((dev) => (dev.deviceType === 'zcc'));
    return { devicesUpdated: devicesFromState, org: orgFromState, zccDevice: zccDevice }
}

interface Props extends ReduxStateProps, ReduxDispatchProps, OwnProps { }

interface State {
    wsHandler?: WsHandler;

}



class DeviceStateView2 extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.buttonClickHandler.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.state = {
            // wsHandler: undefined
        }

        // this.props.onFocus = this.onFocus();
    }

    onFocus(){
        this.connectToWS();
    }

    UNSAFE_componentWillMount() {
        console.debug('DeviceBasicView2.UNSAFE_componentWillMount() called');

        if (this.props.orgId) {
            this.props.orgsLoadConnect(this.props.orgId);
        }
        this.connectToWS()
    }

    // componentDidMount() {
    //     window.addEventListener("focus", this.onFocus)
    //     // window.addEventListener("blur", this.onFocus)
    
    //   }

    UNSAFE_componentWillReceiveProps(nextProps: ReduxStateProps) {
        // this.props.orgsLoadConnect(this.props.orgId);
        console.debug('DeviceBasicView2.UNSAFE_componentWillReceiveProps() called');
        this.connectToWS();
    }

    componentWillUnmount() {
        console.debug('DeviceBasicView2.componentWillUnmount() called');

        this.state.wsHandler?.close();
        // window.removeEventListener("focus", this.onFocus)

    }

    connectToWS() {
        if (!this.state.wsHandler) {
            if (this.props.zccDevice?.id) {
                this.setState({ wsHandler: new WsHandler(this.props.zccDevice.id) })
            }
        }
    }

    render() {

        let devBasicStateListByRoom: JSX.Element[] = [];

        const controlPoints: IDeviceControlUpdate[] = [];
        const roomDevices: Map<string, IDeviceControlUpdate[]> = new Map();

        this.props.devicesUpdated?.forEach(device => {
            const devParams = RegisterDecoder.getInstance().decodeRegistersForControlHistory(device,
                this.props.org?.roomNames ? this.props.org?.roomNames.split(',') : ['']);

            devParams?.forEach(cp => {
                controlPoints.push(cp);

                const roomId = cp.roomName ? cp.roomName : 'undefined' ;
                if (roomId) {

                    if (!roomDevices.get(roomId)) {
                        roomDevices.set(roomId, [])
                    }
                    roomDevices.get(roomId)?.push(cp);
                }
            })
        })


        roomDevices.forEach((cps, key) => {

            const roomDevices: JSX.Element[] = [];

            cps.forEach(cp => {
                const cpView = this.ControlPointView(cp);

                roomDevices.push(cpView)
            });

            devBasicStateListByRoom.push(
                <Container key={'room-cp-control-' + this.props.orgId + '-' + key} style={{ border: '1px  solid cyan', margin: '5px' }}>

                    <Row key={'roomcp_' + key}>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} >
                            {/* <Col  > */}

                            <Row>
                                <h5> Room: {key}</h5>
                            </Row>
                            <Row>
                                {roomDevices}
                            </Row>
                        </Col>
                    </Row>
                </Container>
            )

        })


        if (controlPoints.length > 0) {
            let cardOutlets: JSX.Element[] = [];
            controlPoints.forEach(controlpoint => {
                const cpView = this.ControlPointView(controlpoint);
                cardOutlets.push(cpView)
            })

            return <> {devBasicStateListByRoom} </>
        } else {
            return <></>
        }
    }

    private ControlPointView(controlPoint: IDeviceControlUpdate) {
        const device = controlPoint.device;
        const devState = controlPoint;
        const offId = this.getActionRegsID(device, devState.outletNumber, 'TurnOff');
        const onId = this.getActionRegsID(device, devState.outletNumber, 'TurnOn');

        return (
            <Col key={devState.deviceId} xs={6} sm={4} md={3} lg={3} xl={2}  >


                <Card key={devState.deviceId} >
                    <Card.Body>
                        <Card.Title>
                            {devState.deviceName}
                        </Card.Title>
                        {/* <Card.Subtitle className="mb-2 text-muted" style={{ fontSize: '12px' }}>
                            <span>  Room - {devState.roomName}</span>
                        </Card.Subtitle> */}
                        <Card>
                            <Row><Col>
                                {device.connected ?
                                    <ProgressBar variant="success" now={devState.controlLevel} label={`${devState.controlLevel}%`} /> :
                                    <ProgressBar variant="warning" now={100} label='Disconnected' />}
                            </Col></Row>

                            <Row>
                                <Col>
                                    {offId ?
                                        <Button
                                            // id={this.getActionID(devState.deviceId, 'OFF', {})}
                                            id={this.getActionRegsID(device, devState.outletNumber, 'TurnOff')}
                                            variant="outline-danger"
                                            style={{ width: '40px', fontSize: '8px' }}
                                            onClick={(e: any) => this.buttonClickHandler(e)}
                                        >
                                            Off
                                        </Button>
                                        : <div></div>}
                                </Col>

                                <Col>
                                    {onId ?
                                        <Button
                                            // id={this.getActionID(devState.deviceId, 'ON', {})}
                                            id={this.getActionRegsID(device, devState.outletNumber, 'TurnOn')}
                                            variant="outline-success"
                                            style={{ width: '40px', fontSize: '8px' }}
                                            onClick={(e: any) => this.buttonClickHandler(e)}
                                        >
                                            On
                                        </Button>
                                        : <div></div>}

                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    {/* { JSON.stringify(devState.settingsState)} */}
                                </Col>
                            </Row>
                        </Card>
                    </Card.Body>
                </Card>
                <Row style={{ height: '10px' }}>
                    <Col></Col>
                </Row>
            </Col>);
    }

    buttonClickHandler(event: any) {
        console.log('button clicked - ' + event.target.id);

        const eventData: IRegisterUpdateZCCMessage = JSON.parse(event.target.id);

        const zccCommandData = {
            numCommands: 1,
            command: [
                {
                    id: eventData.meshDeviceId,
                    data: eventData.value,
                    type: 'reg',
                    reg: eventData.reg
                }
            ]
        }
        getCurrentUserJwt()
            .then(jwt => {
                if (jwt && this.props.zccDevice?.id) {
                    sendCommandToDevice(jwt, this.props.zccDevice.id, zccCommandData)
                }

            })

    }

    getActionID(deviceId: string, action: string, params: any) {

        return JSON.stringify({ deviceId, action, params })
    }

    getActionRegsID(device: IDevice, outletNumber: 1 | 2 | 3 | 4 | 5 | 6, action: ActionName) {
        const regObject = DeviceActionHandler.getRegisterUpdateForDeviceAction({
            device, outletNumber, action: { type: action }
        })
        if (regObject?.value) {
            return JSON.stringify(regObject);
        } else {
            return;
        }
    }

}



export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DeviceStateView2);

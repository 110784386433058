import * as React from "react";
import { connect } from "react-redux";
import { Route } from "react-router-dom";

import history from "../history";
import { IZimiState } from "../types/auth";
import { Row, Container } from "react-bootstrap";

interface IProps {
  exact?: boolean;
  isAuthenticated: boolean | null;
  path: string;
  component: React.ComponentType<any>;
  location?: any;
}

const LoggedInRoute = ({
  component: Component,
  isAuthenticated,
  ...otherProps
}: IProps) => {
  // const props = useParams();

  if (isAuthenticated === false) {
    const redirectPath = otherProps.location.pathname
    if (redirectPath && redirectPath!=='/log-out') {
      history.push("/log-in?redirect=" + redirectPath);
    } else {
      history.push("/log-in" );
    }
    // alert("this is a logged in route, you are logged out, redirected to log in");
  }

  return (
    <Container fluid>
      <Row>
        {/* <header>
        Logged In Header
      </header> */}
        <Route
          render={otherProps => (
            <>
              {/* <Breadcrumb /> */}
              <Component {...otherProps} />
            </>
          )}
        />
        {/* <footer>
        Logged In Footer
      </footer> */}
      </Row>
    </Container>
  );
};

const mapStateToProps = (state: IZimiState) => ({
  isAuthenticated: state.authState.isAuthenticated
});

export default connect(
  mapStateToProps
)(LoggedInRoute);

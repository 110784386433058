import { ThunkDispatch as Dispatch } from "redux-thunk";

import * as constants from '../../config/constants';
import { FirebaseClient } from '../../firebase/FirebaseClient'
import { getDevices, getOrganizations, getOrganization } from '../../requests/blueprintRequests'
import { IDevice, IOrganization, IDeviceUpdateGateway, Invitation } from "../../types/blueprint";
import { getInvitations } from "../../requests/zimiserverRequests";

export interface IOrgLoad {
  type: constants.ORG_LOAD;
  data: { orgs: IOrganization[] }
}

function loadOrgs(orgs: IOrganization[]): IOrgLoad {
  return {
    type: constants.ORG_LOAD,
    data: { orgs },
  };
}

export interface IDeviceLoad {
  type: constants.DEVICE_LOAD;
  data: { devs: IDevice[] }
}

function loadDevices(devs: IDevice[]): IDeviceLoad {
  return {
    type: constants.DEVICE_LOAD,
    data: { devs },
  };
}

export interface IInvitationLoad {
  type: constants.INVITATION_LOAD;
  data: { invitations: Invitation[] }
}

function loadInvitations(invitations: Invitation[]): IInvitationLoad {
  return {
    type: constants.INVITATION_LOAD,
    data: { invitations },
  };
}

export interface IDeviceChangeFromGateway {
  type: constants.DEVICE_UPDATE_GATEWAY,
  data: { dev: IDeviceUpdateGateway }
}

export function updateDevice(device: IDeviceUpdateGateway): IDeviceChangeFromGateway {
  return {
    type: constants.DEVICE_UPDATE_GATEWAY,
    data: { dev: device }
  }
}

export interface IDataClear {
  type: constants.DATA_CLEAR
}

function clearData(): IDataClear {
  return {
    type: constants.DATA_CLEAR
  }
}

export interface IWsConnected {
  type: constants.WS_CONNECTED,
  data: { id: string }
}

export function wsConnected(id: string) {
  return {
    type: constants.WS_CONNECTED,
    data: { id }
  }
}

export interface IWsDisconnected {
  type: constants.WS_DISCONNECTED,
  data: { id: string }
}

export function wsDisconnected(id: string) {
  return {
    type: constants.WS_DISCONNECTED,
    data: { id }
  }
}


export type BlueprintAction = IOrgLoad | IDeviceLoad;

export const orgsLoad = (orgId?: string) => {
  return async (dispatch: Dispatch<BlueprintAction, {}, any>) => {
    let currentUser = FirebaseClient.getInstance().getFirebaseAuth().currentUser;
    if (currentUser) {
      currentUser.getIdToken()
        .then(value => {
          if (orgId) {
            getOrganization(value, orgId)
              .then(org => {
                dispatch(loadOrgs([org.organization]));

              }).catch(err => console.error('getOrganization error ', err))
          } else {
            getOrganizations(value)
              .then(orgs => {
                dispatch(loadOrgs(orgs.organizations.results));
              }).catch(err => console.error('getOrganizations error ', err))
          }
        })
    }

  };
}

export const devicesLoad = (orgId?: string) => {
  return async (dispatch: Dispatch<BlueprintAction, {}, any>) => {
    let currentUser = FirebaseClient.getInstance().getFirebaseAuth().currentUser;
    if (currentUser) {
      currentUser.getIdToken()
        .then(value => {
          getDevices(value, orgId)
            .then(devices => {
              dispatch(loadDevices(devices.devices.results));
            })
        })
    }
  };
}

export const invitationsLoad = () => {
  return async (dispatch: Dispatch<BlueprintAction, {}, any>) => {
    let currentUser = FirebaseClient.getInstance().getFirebaseAuth().currentUser;
    if (currentUser) {
      currentUser.getIdToken()
        .then(value => {
          getInvitations(value)
            .then(invitations => {
              dispatch(loadInvitations(invitations.invitations));
            })
        })
    }
  };
}


export const deviceUpdateWithGatwayMessage = (deviceUpdate: IDeviceUpdateGateway) => {
  return async (dispatch: Dispatch<BlueprintAction, {}, any>) => {
    dispatch(updateDevice(deviceUpdate))
  }
}

export const initiateClearData = () => {
  return async (dispatch: Dispatch<BlueprintAction, {}, any>) => {
    dispatch(clearData())
  }
}

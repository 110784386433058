import * as React from "react";
// import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";

import About from "../components/pages/About";
import Home from "../components/pages/Home";
import Landing from "../components/pages/loggedout/Landing";
import LogIn from "../components/pages/loggedout/LogIn";
import LogOut from "../components/pages/LogOut";
import ForgotPassword from "../components/pages/loggedout/ForgotPassword";
import ResetPassword from "../components/pages/loggedout/ResetPassword";

import SignUp from "../components/pages/loggedout/SignUp";


import NotFound from "../components/pages/NotFound";
import Terms from "../components/pages/Terms";
import Organizations from "../components/pages/organizations/Organizations";
import Devices from "../components/pages/devices/Devices";



import LoggedInRoute from "../routes/LoggedInRoute";
import LoggedOutRoute from "../routes/LoggedOutRoute";
import Invitations from "../components/pages/invitations/Invitations";
import LoggedInAdminRoute from "./LoggedInAdminRoute";
import AdminUsers from "../components/pages/admin/AdminUsers";
import Devices2 from "../components/pages/devices/Devices2";

const Pages = () => {
  return (
    <Switch>
      <LoggedOutRoute path="/" exact={true} component={Landing} />
      <LoggedOutRoute path="/about" exact={true} component={About} />
      <LoggedOutRoute path="/log-in" exact={true} component={LogIn} />
      <LoggedOutRoute path="/forgot-password" exact={true} component={ForgotPassword} />
      <LoggedOutRoute path="/sign-up" exact={true} component={SignUp} />
      <LoggedOutRoute path="/reset-password" exact={true} component={ResetPassword} />

      <LoggedInRoute path="/log-out" exact={true} component={LogOut} />
      <LoggedInRoute path="/home" exact={true} component={Home} />
      <LoggedInRoute path="/devices" exact={true} component={Devices2} />
      {/* <LoggedInRoute path="/organizations/:orgid/device" exact={false} component={Devices} /> */}
      {/* <LoggedInRoute path="/organizations/:orgid" exact={true} component={Organization} /> */}
      <LoggedInRoute path="/invitations" component={Invitations} />
      <LoggedInRoute path="/organizations" component={Organizations} />

      <LoggedInAdminRoute path="/admin" component={AdminUsers} />

      <Route path="/terms" exact={true} component={Terms} />
      <Route component={NotFound} />
    </Switch>
  );
};

export default Pages;

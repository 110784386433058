import * as React from "react";
import { Card } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { IOrganization } from "../../../types/blueprint";
import OrganizationPowerUsage from "./OrganizationPowerUsage";


export interface OwnProps {
    org: IOrganization;
}


const OrganizationView = (props: OwnProps) => {

    const org = props.org;

    if (org) {
        const devsUrl = "/organizations/" + org.id + "/device";
        const orgUrl = "/organizations/" + org.id + "";
        const powerUsageUrl = '/organizations/' + org.id + "/powerusage/";
        const notificationsUrl = '/organizations/' + org.id + '/notifications/';


        let orgCard = (
            <Card style={{}}>
                <Card.Body>
                    <Card.Title>  {org.name} </Card.Title>
                    <Card.Subtitle className="mb-4 text-muted">{org.id}</Card.Subtitle>
                    {/* <Card>
                    <Row>
                        <Col > <span> Address : </span> </Col>
                    </Row>
                    <Row>
                        <Col> {org.address && Object.keys(org.address).map(key =>
                            <Row key={'org_address_' + key}>
                                <Col> {key} </Col>
                                <Col> {org.address[key]}</Col>
                            </Row>
                        )}
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col > <span> Location : </span> </Col><Col><span> {org.latitude}, {org.longitude} </span></Col>
                    </Row>
                    <Row>
                        <Col > <span> Timezone : </span> </Col><Col><span> {org.timezone}</span></Col>
                    </Row>
                </Card> */}
                    {/* <Card>
                    <Card.Title> Users </Card.Title>

                </Card> */}
                    <Card>
                        <Card.Link as={Link} to={orgUrl}> <Card.Title> Network </Card.Title></Card.Link>
                    </Card>

                    <Card>
                        <Card.Link as={Link} to={devsUrl}> <Card.Title> Devices </Card.Title></Card.Link>
                    </Card>

                    <Card>
                        <Card.Link as={Link} to={powerUsageUrl}> <Card.Title> Power Usage </Card.Title></Card.Link>
                    </Card>

                    <Card>
                        <Card.Link as={Link} to={notificationsUrl}> <Card.Title> Notification Setup </Card.Title></Card.Link>
                    </Card>
                </Card.Body>
            </Card>
        )

        return orgCard;
    } else {
        return <></>
    }
}

export default OrganizationView;
import * as React from "react";
import { connect } from "react-redux";
import { Row, Col, Container } from 'react-bootstrap'
import { IZimiState } from '../../../types/auth'
import { withRouter } from 'react-router'

import { devicesLoad, deviceUpdateWithGatwayMessage, orgsLoad } from '../../../redux/actions/blueprintActions';
import { IDevice, IDeviceUpdateGateway, IOrganization } from "../../../types/blueprint";
import { RouteComponentProps } from "react-router-dom";
import NetworkPowerUsageChart from "../powerUsageGraph/NetworkPowerUsageChart";


interface IProps extends IOwnProps, ReduxStateProps, RouteComponentProps<MatchProps> {
  devicesLoadConnect: (orgId?: string) => void;
  updateDevice: (devUpdate: IDeviceUpdateGateway) => void;
  loadOrgs: (orgId: string) => void;
  history: any;
  // orgId?: string;
}

interface IOwnProps {

}

interface ReduxStateProps {
  devices: IDevice[];
  orgs: IOrganization[];
}

interface MatchProps {
  orgId: string;
}

interface IState {
  initRun: boolean;
  // fcmHandler: FcmHandler;
  // powerData: IPowerUsageEntry[];

}

class Devices extends React.Component<IProps, IState> {

  // private fcmHandler: FcmHandler;
  constructor(props: IProps) {
    super(props);
    this.state = {
      initRun: true,
    }
    // this.fcmHandler = FcmHandler.getInstance()

    this.onFocus = this.onFocus.bind(this)
  }

  render() {
    const orgId = this.getOrg();
    const orgObj = this.props.orgs && this.props.orgs.find(org => org.id === orgId);
    const heading = orgId ? ' Network : ' + (orgObj?.defaultAlias || orgObj?.name) + ' , Power usage history' : 'Power usage history';



    return (
      <Container>
        <Row><Col><h2> {heading} </h2></Col></Row>

        <Row>
          <Col sm={12} md={12} lg={12} xl={12}>

            <div>  Power data for Network </div>
            {/* <div> {this.state.powerData && JSON.stringify(this.state.powerData)}</div> */}
            <NetworkPowerUsageChart devices={this.props.devices} org={this.props.orgs.find((org => org.id === this.getOrg()))} ></NetworkPowerUsageChart>
          </Col>
        </Row>
      </Container>
    );
  };

  UNSAFE_componentWillMount() {
    console.debug('Devices.componentWillMount() called');
    this.props.devicesLoadConnect(this.getOrg());
    if (!this.props.orgs || this.props.orgs.length === 0) {
      this.props.loadOrgs(this.getOrg())
    }

  }

  // loadPowerData() {

  //   let deviceIdsForPower: string[] = [];
  //   this.props.devices.forEach(dev => {
  //     this.getLegacyControlpointIdsForDevice(dev)
  //       .forEach(cpId => {
  //         deviceIdsForPower.push(cpId);
  //       })
  //   })
  //   getPowerUsages(deviceIdsForPower, new Date('2021-06-01'), new Date('2021-12-31'), 'day')
  //     .then(ret => {
  //       this.setState({ powerData: ret })
  //     })

  // }

  // componentDidMount() {
  //   window.addEventListener("focus", this.onFocus)
  //   // window.addEventListener("blur", this.onFocus)

  // }

  // UNSAFE_componentWillReceiveProps(nextProps: ReduxStateProps) {
  //   console.debug('Devices.componentWillReceiveProps() called');
  //   if (nextProps.devices.length > 0 && this.state.initRun) {
  //     console.debug('Devices.componentWillReceiveProps - FCM setup called');

  //     this.setState({ initRun: false })
  //     const zccs = nextProps.devices.filter(dev => dev.deviceType === 'zcc');
  //     zccs.forEach(zcc => {
  //       this.fcmHandler.subscribeToGateway(zcc.id)
  //     })
  //     this.fcmHandler.onMessageCallback(this.processMessage)
  //   }
  // }

  // componentWillUnmount() {
  //   console.debug('Devices.componentWill-UN-Mount() called');
  //   const zccs = this.props.devices.filter(dev => dev.deviceType === 'zcc');
  //   zccs.forEach(zcc => {
  //     this.fcmHandler.unsubscribeToGateway(zcc.id);
  //   })
  //   window.removeEventListener("focus", this.onFocus)
  //   // window.removeEventListener("blur", this.onFocus)

  // }

  onFocus() {
    // this.props.devicesLoadConnect(this.getOrg());
    // this.props.loadOrgs(this.getOrg())
  }

  getOrg() {
    return this.props.match.params.orgId
  }

  shouldDisplayDevice(device: IDevice) {
    switch (device.deviceType) {
      case 'zcc':
      case 'ugds':
        return false;
      default:
        return true;
    }
  }




}

const mapStateToProps = (state: IZimiState, ownProps: IOwnProps): ReduxStateProps => {
  return { devices: state.blueprintState.deviceState.devices, orgs: state.blueprintState.orgState.orgs }
}

const mapDispatchToProps = {
  devicesLoadConnect: devicesLoad,
  updateDevice: deviceUpdateWithGatwayMessage,
  loadOrgs: orgsLoad,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(Devices));

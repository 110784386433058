const firebaseConfig = {
    apiKey: "AIzaSyCBw71LCryawko0zyM699QKaitgdjkXtiY",
    authDomain: "zimi-production.firebaseapp.com",
    databaseURL: "https://zimi-production.firebaseio.com",
    projectId: "zimi-production",
    storageBucket: "zimi-production.appspot.com",
    messagingSenderId: "139298138144",
    appId: "1:139298138144:web:7e0b51495a174c3b18b445",
    measurementId: "G-Y30B33ZZE5"
};

const fcmConfig = {
    publicVapIdKey: 'BLESPbyX8UHxn4kfMcyjQEcFV6Hr7byv5DmrDireKrPUZ3s3cG0epXBxHBj_8evJ51eUmE4kZcl8gpqOlNy-Zhw'
}

const zimicoreConfig = {
    baseUrl: 'zimicore.zimi.life'
}
const zimiserviceConfig = {
    baseUrl: 'zimiservice.zimi.life'
}

const deviceTypes = {
    "dimmer": "1gdimmer",
    "gpo": "2ggpo",
    "clayton": "1g3w10ax",
    "fan": "1gfan",
    "gateway": "zcc",
    "mccoy": "mccoy",
    "ugdc": "ugdc",
    "ugds": "ugds"
}

export const appConfig = {
    firebaseConfig,
    fcmConfig,
    zimicoreConfig,
    zimiserviceConfig,
    deviceTypes
}
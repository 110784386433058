import { IAuthenticate, IUnauthenticate, IAuthenticateError, IAuthenticateRequested } from "../actions/loginActions";
import { AUTHENTICATE, UNAUTHENTICATE, AUTHENTICATE_ERROR, AUTHENTICATE_REQUESTED } from "../../config/constants";
import { IAuthState } from '../../types/auth';

export default function authReducer(
  state: IAuthState = {
    uuid: null,
    email: null,
    roles: [],
    isAuthenticated: null,
    loading: false,
  },
  action: IAuthenticate | IUnauthenticate | IAuthenticateError | IAuthenticateRequested,
): IAuthState {
  switch (action.type) {
    case AUTHENTICATE:
      return { ...state, uuid: action.params.uid, email: action.params.email, roles: action.params.roles, isAuthenticated: true };
    case UNAUTHENTICATE:
      return { uuid: null, email: null, roles: [], isAuthenticated: false, loading: false };
    case AUTHENTICATE_ERROR:
      return { uuid: null, email: null, roles: [],  isAuthenticated: false, authError: { code: action.param.code, message: action.param.message }, loading: false }
    case AUTHENTICATE_REQUESTED:
      return { uuid: null, email: null, roles: [], isAuthenticated: false, loading: true }
  }
  return state;
}
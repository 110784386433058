import { AnyAction, createStore, Store, compose, applyMiddleware } from "redux";
import { IZimiState } from "../types/auth";
import { reducer } from '../redux/reducers/index';
import thunkMiddleware from "redux-thunk-recursion-detect";

export class ReduxStore {
    private static instance: ReduxStore;

    private store: Store<any, AnyAction >;
    private constructor() {

        let composeEnhancers;

        if (
            process.env.NODE_ENV !== "production" &&
            (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ) {
            composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
        } else {
            composeEnhancers = compose;
        }

        this.store = createStore<IZimiState, any, any, any>(
            reducer,
            undefined, 
            composeEnhancers(applyMiddleware(thunkMiddleware)) )

    }

    public static getInstance(): ReduxStore {
        if (!ReduxStore.instance) {
            ReduxStore.instance = new ReduxStore();
        }

        return ReduxStore.instance
    }

    public getStore() {
        return this.store;
    }
}
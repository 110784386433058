import { FirebaseClient } from "../firebase/FirebaseClient";

// export const getUser = () => {

// }

export const getUserToken = () => {

    return new Promise<string>((resolve, reject)=>{

        let currentUser = FirebaseClient.getInstance().getFirebaseAuth().currentUser;
        if (currentUser) {
            currentUser.getIdToken()
            .then(value => {
                resolve( value);
            })
            .catch(err=>{
                reject(err)
            })
        }else{
            reject('no current user');
        }
    })
}

import * as React from "react";

const Terms = () => (
  <>
    <p>Terms and conditions etc.</p>
    <p>This page is accessible when logged in or logged out</p>
  </>
);

export default Terms;

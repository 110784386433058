import * as React from "react";
import { connect } from "react-redux";
import { Route, Router } from "react-router-dom";

import history from "./history";
import NavMenu from "./components/Nav";
import Pages from "./routes/Pages";
import { checkAuthentication } from "./redux/actions/loginActions";
import { IZimiState } from "./types/auth";

interface IProps {
  checkAuthenticationConnect: () => void;
  isAuthenticated: boolean | null;
}

const App = ({
  checkAuthenticationConnect,
  isAuthenticated
}: IProps) => {
  React.useEffect(() => {
    checkAuthenticationConnect();
  });

  const app = isAuthenticated !== null ? (
    <Router history={history}>
      <NavMenu />
      <Route component={Pages} />
    </Router>
  ) : null;

  return (
    <div className="App">
      {app}
    </div>
  );
}

const mapStateToProps = (state: IZimiState) => ({
  isAuthenticated: state.authState.isAuthenticated
});

const mapDispatchToProps = {
  checkAuthenticationConnect: checkAuthentication
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(App);
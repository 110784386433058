import * as React from "react";
import { connect } from "react-redux";
import { Route } from "react-router-dom";

import history from "../history";
import { IZimiState } from "../types/auth";
import { Container, Row, Col, Image } from 'react-bootstrap';
import queryString from 'query-string';

import './LoggedOutRoute.scss'
import { AuthActions, isActionAllowedForRole } from "../libs/Authorization";

interface IProps {
  exact?: boolean;
  isAuthenticated: boolean | null;
  authRoles?: string[] ;
  path: string;
  component: React.ComponentType<any>;
  location?: any;
}

const LoggedOutRoute = ({
  component: Component,
  isAuthenticated,
  ...otherProps
}: IProps) => {
  if (isAuthenticated === true) {

    const adminActionAllowed = isActionAllowedForRole(otherProps.authRoles , AuthActions.ADMIN_VIEW)
    const parsedQueryString = queryString.parse(otherProps.location.search)
    const redirectPath = parsedQueryString.redirect;
    if (redirectPath && typeof redirectPath === 'string') {
      history.push(redirectPath);
    } else if (adminActionAllowed) {
      history.push("/admin")
    } else {
      history.push("/organizations");
    }
    // alert("this is a logged out route, you are logged in, redirected to home page");
  }

  // return_old (
  //   <>
  //     <header>
  //       Logged Out Header
  //     </header>
  //     <Route
  //       render={otherProps => (
  //         <>
  //           <Component {...otherProps} />
  //         </>
  //       )}
  //     />
  //     <footer>
  //       Logged Out Footer
  //     </footer>
  //   </>
  // );

  return (
    <>
      <Container fluid id='landing'>
        <Row>
          <Col xs={{ span: 10, order: 1, offset: 1 }} md={{ span: 6, order: 1, offset: 3 }} lg={{ span: 6, order: 1, offset: 3 }} className='text-center'>
            <Row style={{ height: '20px' }}>
              <Col>
                {/* <Image src='https://storage.googleapis.com/zimi-ui-res/images/gsmlogo.png' className='logo' /> */}
              </Col>
            </Row>
            <div className='tab-content main'>
              <Row>
                <Col md={12} >
                  <Image src='https://storage.googleapis.com/zimi-ui-res/images/Zimi_Colored.png' className='logo' />
                </Col>
                {/* <Col md={7} >
                  <Image src='https://storage.googleapis.com/zimi-ui-res/images/Powermesh_Colored.png' className='logo' />
                </Col> */}
              </Row>
              <Route
                render={otherProps => (
                  <>
                    <Component {...otherProps} />
                  </>
                )}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

const mapStateToProps = (state: IZimiState) => ({
  isAuthenticated: state.authState.isAuthenticated,
  authRoles: state.authState.roles
});

export default connect(
  mapStateToProps
)(LoggedOutRoute);

import * as React from "react";
import { Link } from "react-router-dom";
// import { Container, Row, Col, Image } from 'react-bootstrap';

const Landing = (props: any) => (
  <>
  <p>Welcome to My Zimi</p>
  <p> <Link to={'/log-in'}> Please Login</Link> </p>
  </>
   
);

export default Landing;

import * as React from "react";
import { connect } from "react-redux";
import { Card, Col, Container, Nav, NavItem, Row } from 'react-bootstrap'
import { Link, withRouter } from "react-router-dom";
import { IOrganization } from "../../../types/blueprint";
import { orgsLoad } from "../../../redux/actions/blueprintActions";
import { IZimiState } from "../../../types/auth";


interface OwnProps {
  orgId: string

}

interface DispathProps {
  orgsLoadConnect: (orgId: string) => void;
}

interface ReduxStateProps {
  org?: IOrganization;
}

interface IOrganizationReduxProps extends ReduxStateProps, DispathProps { }


interface PageProps extends OwnProps, IOrganizationReduxProps { }


interface IState {
}

class Organization extends React.Component<PageProps, IState> {
  constructor(props: PageProps) {
    super(props);
    this.state = {
    }
  }

  getOrg() {
    return this.props.orgId
  }

  render() {
    const org: IOrganization | undefined = this.props.org;
    return (
      <Container>
        {/* <Organizations orgId={this.getOrg()} /> */}
        {/* {this.getOrg()} */}

        <Card style={{}}>
          <Card.Body>
            <Card.Title> Network:  {org?.name || org?.defaultAlias} </Card.Title>
            <Card.Subtitle className="mb-4 text-muted">{org?.id}</Card.Subtitle>
            <Card>
              <Row>
                <Col > <span> Address : </span> </Col>
              </Row>
              <Row>
                <Col> {org?.address && Object.keys(org.address).map(key => {
                  const addAny: any = org.address;
                  return (<Row key={'org_address_' + key}>
                    <Col> {key} </Col>
                    <Col> {addAny && addAny[key]}</Col>
                  </Row>)
                }
                )}
                </Col>
              </Row>
              <br />
              <Row>
                <Col > <span> Location : </span> </Col><Col><span> {org?.latitude}, {org?.longitude} </span></Col>
              </Row>
              <Row>
                <Col > <span> Timezone : </span> </Col><Col><span> {org?.timezone}</span></Col>
              </Row>
            </Card>
            {/* <Card>
                    <Card.Title> Users </Card.Title>

                </Card>
                    {/* <Card>
                        <Card.Link as={Link} to={orgUrl}> <Card.Title> Network </Card.Title></Card.Link>
                    </Card>

                    <Card>
                        <Card.Link as={Link} to={devsUrl}> <Card.Title> Devices </Card.Title></Card.Link>
                    </Card> */}
          </Card.Body>
        </Card>

        <NavItem>
          <Nav.Link as={Link} to={'/organizations/' + this.getOrg() + '/device'} >
            Devices
          </Nav.Link>

          <Nav.Link as={Link} to={'/organizations/' + this.getOrg() + '/powerusage'} >
            Power Usage History
          </Nav.Link>
        </NavItem>
      </Container>
    );
  };

  UNSAFE_componentWillMount() {
    // if (this.props.orgs && this.props.orgs.length === 0) {
    this.props.orgsLoadConnect(this.getOrg());
    // }

  }
}

const mapDispatchToProps: DispathProps = {
  orgsLoadConnect: orgsLoad
};

const mapStateToProps = (state: IZimiState, ownProps: OwnProps): ReduxStateProps => {
  const orgId = ownProps.orgId;
  if (orgId) {

    return { org: state.blueprintState.orgState.orgs.find(org => org.id === orgId) }
  } else {
    return { org: undefined }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)((Organization));

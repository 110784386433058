import * as React from "react";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
// import UserView from "./UserView";
import UserViewEdit from "./UserFBAuthViewEdit";
import UserFSViewEdit from "./UserFSViewEdit";


interface IProps extends RouteComponentProps {
}

interface IState {

}

interface IParamMatch {
  userId: string;
}

interface PropsFromParent {
  // Your custom props here
}

type PageProps = PropsFromParent & IProps &
  RouteComponentProps<IParamMatch>;

class UserIdRoute extends React.Component<PageProps, IState> {
  constructor(props: PageProps) {
    super(props);
    this.state = {
    }

  }

  componentDidMount() {

  }

  render() {
    return ( <>
        <h3> User Details</h3>
        <Row>
          <Col md={6} >
            <UserViewEdit userId={this.props.match.params.userId} />
          </Col>
          <Col md={6} >
            <UserFSViewEdit userId={this.props.match.params.userId} />
          </Col>
          </Row>
      </>
    )

  }
}

export default connect(
  null, null,
)(withRouter(UserIdRoute));

import { updateDevice, wsConnected, wsDisconnected } from "../redux/actions/blueprintActions";
import { ReduxStore } from "../redux/Store";

const host = 'wss://zimiws.zimitest.page/zcc/';

export class WsHandler {

  private ws: WebSocket;
  private zccId: string;
  constructor(zccId: string) {
    this.zccId = zccId;
    const websocket = new WebSocket(host + zccId);
    this.ws = websocket;

    ReduxStore.getInstance().getStore().dispatch(
      wsConnected(zccId)
    )

    websocket.addEventListener('message', async (ev) => {
      this.handleEvent(ev);
    });

    websocket.addEventListener('error', async (ev) => {
      console.log('websocket error ', ev)
    });

    this.ws.addEventListener('open', async (ev) =>{
      // ReduxStore.getInstance().getStore().dispatch(
      //   wsConnected(zccId)
      // )
    })

    this.ws.addEventListener('close', async (ev) =>{
      ReduxStore.getInstance().getStore().dispatch(
        wsDisconnected(zccId)
      )
    })
  }

  public close() {
    this.ws.close();
  }

  private handleEvent(ev: MessageEvent<any>) {
    this.processMessage(JSON.parse(ev.data));
  }

  private processMessage(payload: any) {
    console.log('received from ws : ' + JSON.stringify(payload));
    if (payload && payload.data && payload.data.message) {
      let message = JSON.parse(payload.data.message);
      let gatewayId = payload.data.gatewayGuid;

      if (message.changes && message.changes.length > 0) {
        let changesDeviceMap: Map<number, any> = new Map()
        message.changes.forEach((change: any) => {

          let deviceFields: any = changesDeviceMap.get(change.id);
          if (!deviceFields) {
            deviceFields = { meshId: change.id };
          }

          if (change.type === 'status') {
            deviceFields['connected'] = change.connected;

          } else if (change.type === 'reg') {
            deviceFields['R' + change.reg] = change.data;
          }
          changesDeviceMap.set(change.id, deviceFields)
        })
        changesDeviceMap.forEach(devChange => {
          ReduxStore.getInstance().getStore().dispatch(
            updateDevice({ meshId: devChange.meshId, gatewayId, data: devChange })
          )
        })
      }
    }
  }

}
import * as React from "react";
import { connect } from "react-redux";
import { Form, Row, Col, Button, Alert, Spinner } from 'react-bootstrap'
import { FirebaseClient } from "../../../firebase/FirebaseClient";
import { logIn } from '../../../redux/actions/loginActions';
import queryString from 'query-string';
// import passwordStrength from 'check-password-strength'


import './login.scss'
import { sendPasswordResetSuccess } from "../../../requests/blueprintRequests";

enum WorkflowState {
  INIT,
  VERIFY_CODE_STARTED,
  VERIFY_CODE_SUCCESS,
  VERIFY_CODE_FAIL,
  RESET_PASSWORD_STARTED,
  RESET_PASSWORD_SUCCSS,
  RESET_PASSWORD_FAIL,
}

interface IProps {
  logInConnect: (username: string, password: string) => void;
  // location: { query: any, pathname: string }
  location: any;
}

interface OwnProps { }

interface IState {
  email: string;
  resetCode: string;
  password: string;
  confirmpassword: string;
  [key: string]: any;
  submitError?: any;
  workflowState: WorkflowState;
}

class ResetPassword extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      email: '',
      password: '',
      confirmpassword: '',
      resetCode: '',
      workflowState: WorkflowState.INIT,
    };
  }

  handleChange(fieldName: string, event: any) {
    const value = event.target.value;
    const state: any = {};
    state[fieldName] = value;
    if (fieldName === 'password' || 'confirmpassword') {
      state.workflowState = WorkflowState.VERIFY_CODE_SUCCESS;
    }
    this.setState(state);
  }

  componentDidMount() {
    const parsedQueryString = queryString.parse(this.props.location.search);
    let email = '';
    let resetCode = ''

    if (typeof parsedQueryString.email === 'string') {
      email = parsedQueryString.email;
    }
    if (typeof parsedQueryString.resetCode === 'string') {
      resetCode = parsedQueryString.resetCode;
    }

    if (resetCode !== '') {
      this.setState({ email, resetCode, workflowState: WorkflowState.VERIFY_CODE_STARTED })
      FirebaseClient.getInstance().getFirebaseAuth().verifyPasswordResetCode(resetCode)
        .then(email => {
          this.setState({ email, workflowState: WorkflowState.VERIFY_CODE_SUCCESS })
        }).catch(error => {
          this.setState({ submitError: error, workflowState: WorkflowState.VERIFY_CODE_FAIL })
        })
    } else {
      // to display page when no link,
      this.setState({ email: '', workflowState: WorkflowState.VERIFY_CODE_SUCCESS })
    }
  }

  autocontrol = (fieldName: string) => ({
    name: fieldName,
    value: this.state[fieldName] || '',
    onChange: this.handleChange.bind(this, fieldName),
    className: 'form-control',
  });

  render() {

    const VerifyCodeFailedUI =
      <div>
        <Row>
          <Col> Reset Password </Col>

          <Col sm={12}>
            <Alert variant='warning'>
              {this.state.submitError?.message}
            </Alert>
          </Col>
        </Row>

      </div>;

    const PasswordChangeSuccessdUI =
      <div>
        <Row>
          <Col> Reset Password </Col>

          <Col sm={12}>
            <Alert variant='success'>
              Your password has been updated, please login to the Zimi App.
            </Alert>
          </Col>
        </Row>

      </div>;

    const ResetPasswordUI = (
      <div className='login-component'>
        <Form id='sign-in-form' >
          <br />

          <Row className='form-group'>
            <Col xs={{ span: 10, offset: 1 }} >

              <p> Reset Password for {this.state.email}</p>
              <p> Please enter your new password. It must be a minimum of 8 characters and contain a capital letter, special character and number</p>

              <Form.Group hidden controlId="formResetEmail">
                {/* <Form.Label>Email address</Form.Label> */}
                <Form.Control type="email" placeholder="Enter email" required
                  {...this.autocontrol('email')} />
              </Form.Group>

              <Form.Group hidden controlId="formBasicResetcode">
                {/* <Form.Label>Email address</Form.Label> */}
                <Form.Control type="resetCode" placeholder="Enter resetCode" required
                  {...this.autocontrol('resetCode')} />
              </Form.Group>

              <Form.Group controlId="formBasicPassword">
                {/* <Form.Label>Password</Form.Label> */}
                <Form.Control type="password" placeholder="Password" required
                  {...this.autocontrol('password')} />
              </Form.Group>

              <Form.Group controlId="formBasicConfirmPassword">
                {/* <Form.Label>Password</Form.Label> */}
                <Form.Control type="password" placeholder="Confirm Password" required
                  {...this.autocontrol('confirmpassword')} />
              </Form.Group>

              {this.state.workflowState === WorkflowState.RESET_PASSWORD_FAIL ?
                (
                  <Alert variant='danger'>
                    {(this.state.submitError.message)}
                  </Alert>
                ) : <></>}

              <Row>
                <Col xs={12}>
                  <Button variant="primary" type="button"
                    onClick={(event: any) => {
                      this.handleResetPasswordSubmit();
                    }}
                  > {'    '}
                    {this.state.workflowState === WorkflowState.RESET_PASSWORD_STARTED ?
                      (<Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />) : <></>}
                      Reset Password
                  </Button>
                </Col>
              </Row>

              <Row>
                <Col sm={12}>
                  {this.state.success ?
                    (
                      <Alert variant='success'>
                        Successfully changed the password.
                      </Alert>
                    ) : <></>}
                </Col>
              </Row>

            </Col>
          </Row>
        </Form>
      </div>
    );

    switch (this.state.workflowState) {
      case WorkflowState.INIT:
      case WorkflowState.VERIFY_CODE_STARTED:
        return <div> Loading... </div>;
      case WorkflowState.VERIFY_CODE_FAIL:
        return VerifyCodeFailedUI;
      case WorkflowState.VERIFY_CODE_SUCCESS:
      case WorkflowState.RESET_PASSWORD_STARTED:
      case WorkflowState.RESET_PASSWORD_FAIL:
        return ResetPasswordUI;
      case WorkflowState.RESET_PASSWORD_SUCCSS:
        return PasswordChangeSuccessdUI;
      default:
        return <></>
    }
  }

  handleResetPasswordSubmit() {

    if (this.state.password === this.state.confirmpassword) {
      this.setState({ workflowState: WorkflowState.RESET_PASSWORD_STARTED })

      FirebaseClient.getInstance().getFirebaseAuth().confirmPasswordReset(
        this.state.resetCode, this.state.password
      )
        .then(resp => {
          this.setState({ success: true, workflowState: WorkflowState.RESET_PASSWORD_SUCCSS })
          // this.props.logInConnect(this.state.email, this.state.password)
          sendPasswordResetSuccess(this.state.email).catch();
        })
        .catch(err => {
          this.setState({ submitError: err, workflowState: WorkflowState.RESET_PASSWORD_FAIL })
        })
    } else {
      this.setState({ submitError: { message: 'Sorry the passwords don’t match, please try again.' }, workflowState: WorkflowState.RESET_PASSWORD_FAIL })
    }
  }
}

const mapDispatchToProps = {
  logInConnect: logIn
};


export default connect(
  null,
  mapDispatchToProps,
)(ResetPassword);

// namespace Blueprint {

export interface RequestEntity {
  id?: string;
  data?: any;
}

export interface RequestEntityQuery {
  query: { field: string; operator: string; value: any }[];
  page?: {
    pageSize: number;
    fromDocument: string;
  };
}

export interface RequestBatchGet {
  ids: string[];
}

export interface ResponseEntity {
  id: string;
}

export interface ResponseEntityQuery {
  results: ResponseEntity[];
}

// export namespace EndUsers {
export interface IEndUser extends ResponseEntity {
  id: string;
  organizationId: string;
  userId: string;
  orgAlias: string;
  accessType: string;
  reportState?: number;
  version?: string;
  created?: Date;
  createdById?: string;
  lastModified?: Date;
  lastModifiedById?: string;
}

export interface ResponseEndUserQuery {
  results: IEndUser[];
}

export interface IEndUserCreate extends RequestEntity {
  id?: string;
  data: {
    organizationId: string;
    userId: string;
    orgAlias: string;
    accessType: string;
    user?: IUser;
  };
}

export interface IEndUserUpdate extends RequestEntity {
  id: string;
  data: {
    orgAlias?: string;
    accessType?: string;
  };
  // refParams: { organizationId:string, userId: string}
}

export interface IEndUserGet extends RequestEntity {
  id: string;
  // refParams: { organizationId:string, userId: string}
}

export interface IEndUserDelete extends RequestEntity {
  id: string;
  // refParams: { organizationId:string, userId: string}
}

export interface IEndUserQuery extends RequestEntityQuery {
  query: { field: string; operator: string; value: any }[];
  // refParams: { organizationId:string, userId: string}
}
// }

// export namespace Organizations {

export interface IOrganization extends ResponseEntity {
  id: string;
  name: string;

  latitude?: number;
  longitude?: number;
  defaultAlias: string;
  electricityPrice?: string;
  roomNames?: string;
  address?: {
    streetAddress: string;
    city: string;
    state: string;
    postalCode: string;
    countryCode: string;
  };
  timezone?: string;

  created?: Date;
  createdById?: string;
  lastModified?: Date;
  lastModifiedById?: string;
  version?: string;
  endUser?: IEndUser;
}

export interface IOrganizationCreate extends RequestEntity {
  data: {
    id: string;
    name: string;
    latitude?: number;
    longitude?: number;
    defaultAlias: string;
    electricityPrice?: string;
    roomNames?: string;
    address?: {
      streetAddress: string;
      city: string;
      state: string;
      postalCode: string;
      countryCode: string;
    };
    timezone?: string;
  };
}

export interface IOrganizationUpdate extends RequestEntity {
  data: {
    id: string;
    name: string;
    latitude?: number;
    longitude?: number;
    defaultAlias: string;
    electricityPrice?: string;
    roomNames?: string;
    address?: {
      streetAddress: string;
      city: string;
      state: string;
      postalCode: string;
      countryCode: string;
    };
    timezone?: string;
  };
}

// }

// export namespace Devices {

export interface IDevice extends ResponseEntity {
  id: string;
  name?: string;
  serialNumber?: string;
  organizationId?: string;
  status: string;
  batchNumber?: string;
  meshDeviceId?: string;
  // latitude?: number;
  // longitude?: number;
  // defaultAlias?: string;

  deviceTemplateId?: string;
  deviceType?: string;
  firmwareVersion?: string;
  hardwareVersion?: string;
  provisioningState?: string;
  password?: string;
  associationCode?: string;

  installerUserId?: string;
  installTime?: Date;

  R8?: string;
  R9?: string;
  R1?: string;
  R2?: string;
  R0?: string;
  R5?: string;

  outputName?: string;
  outputName1?: string;
  outputName2?: string;
  outputName3?: string;
  outputName4?: string;
  GHomePin?: string;
  GHomeAckEnabled?: string;
  GHomeVoiceEnabled?: string;

  otaVersion?: string;
  WIFIRSSI?: string;
  WIFISSID?: string;
  // sensorType?: string; // for sensor
  // bleMacAddress?: string;
  // version: string;
  commissionerUserId?: string;
  commissionTime?: Date;
  // connected?: boolean;
  lastConnected?: Date;
  created?: Date;
  createdById?: string;
  lastModified?: Date;
  lastModifiedById?: string;
  R116?: string;
  [key: string]: any;
  meta?: {
    lastUpdateTime?: Date;
  }
}

export interface IDeviceUpdateGateway {
  gatewayId: string;
  meshId: number;
  data: any;
}

export interface IDeviceUpdate extends RequestEntity {
  data: {
    // id: string;
    // name?: string;
    organizationId?: string;
    batchNumber?: string;
    meshDeviceId?: string;
    // latitude?: number;
    // longitude?: number;
    // defaultAlias?: string;

    firmwareVersion?: string;
    hardwareVersion?: string;
    provisioningState?: string;
    // password?: string;
    // associationCode?: string;

    installerUserId?: string;
    installTime?: Date;

    R8?: string;
    R9?: string;
    R1?: string;
    R2?: string;
    R0?: string;
    R5?: string;

    outputName?: string;
    outputName1?: string;
    outputName2?: string;
    // GHomePin?: string;
    // GHomeAckEnabled?: string;
    // GHomeVoiceEnabled?: string;
    // sensorType?: string; // for sensor
    // bleMacAddress?: string;
    version?: string;
    [key: string]: any;

  };
}

export interface IDeviceCreate extends RequestEntity {
  // id: string;
  data: {
    id?: string;
    // name?: string;
    serialNumber?: string;
    // deviceTemplateId?: string;
    deviceType?: string;
  };
}

export interface IDeviceDelete extends RequestEntity {
  id: string;
  data: {
    serialNumber: string;
    version: string;
  };
}
// }

// export namespace DeviceTypes {
export interface IDeviceType {
  id: string;
  name: string;
  channels: [];
}

export interface IDeviceTypeChannel {
  channelName: string;
  channelPath: string;
}
// }

// export namespace Users {
export interface IUser extends ResponseEntity {
  id: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  address?: {
    streetAddress: string;
    postalCode: string;
    city: string;
    state: string;
    countryCode: string;
  };
  installer?: {
    licenceNo: string;
    licensee: string;
    status: string;
    created: Date;
  };
}

export interface ResponseUserQuery {
  results: IUser[];
}

export interface IUserCreate extends RequestEntity {
  id: string;
  data: {
    id?: string;
    emailAddress: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    address: {
      streetAddress: string;
      city: string;
      state: string;
      postalCode: string;
      countryCode: string;
    };
    installer?: {
      licenceNo: string;
      licensee: string;
      status: string;
      created?: Date;
    };
  };
}

export interface IUserUpdate extends RequestEntity {
  id: string;
  data: {
    emailAddress: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    address: {
      address: string;
      postCode: string;
      suburb: string;
      state: string;
      country: string;
    };
    installer: {
      licenceNo: string;
      licensee: string;
      status: string;
      updated?: Date;
    };
  };
}

export interface IUserGet extends RequestEntity {
  id: string;
}

export interface IUserDelete extends RequestEntity {
  id: string;
}

export interface IUserQuery extends RequestEntityQuery {
  query: { field: string; operator: string; value: any }[];
}
// }

// }

export enum InvitationStatus {
  CREATED = 0,
  ACCEPTED = 1,
  DELETED = -1,
}


export interface Invitation {
  id?: number;
  invitationToken: string;
  email: string;
  organizationId: string;
  invitationStatus: InvitationStatus;
  userType: string;
  organization?: IOrganization;
}

export interface InvitationsResponse {
  invitations: Invitation[];
}